<mat-button-toggle-group
  (change)="tabChanged($event)"
  [value]="indicatorToggle"
  aria-label="Font Style"
>
  <mat-button-toggle
    (change)="onIndicatorToggleChange($event.value)"
    value="summary"
    disabled
  >
    <a
      [routerLink]="
        systemsService.getSystemRoute(
          systemData.attributes.equipmentId,
          systemData.id,
          true
        )
      "
      style="text-decoration: none !important; width: 100%"
    >
      <span class="mat-tab-label">
        <img
          style="margin-right: 8px"
          src="../../../assets/logos/favicon.png"
          alt=""
          width="30px"
        />
        <span>{{ 'SYSTEM.resume' | translate }}</span>
      </span>
    </a>
  </mat-button-toggle>
  <mat-button-toggle
    (change)="onIndicatorToggleChange($event.value)"
    value="availability"
    [ngStyle]="{
      'background-color':
        indicatorToggle == 'availability' ? '#8a86a7' : 'white'
    }"
    [disabled]="indicators[0].off == 1 ? true : false"
  >
    <img src="../../../assets/icon/disponibilidad2.svg" alt="" />
    <p>
      {{ 'HOME.indicators.availability.title' | translate }}
    </p>
  </mat-button-toggle>
  <mat-button-toggle
    (change)="onIndicatorToggleChange($event.value)"
    value="profitability"
    [ngStyle]="{
      'background-color':
        indicatorToggle == 'profitability' ? '#93a4ec' : 'white'
    }"
    [disabled]="indicators[1].off == 1 ? true : false"
  >
    <img src="../../../assets/icon/rentabilidad2.svg" alt="" />
    <p>Rentabilidad</p>
  </mat-button-toggle>
  <mat-button-toggle
    (change)="onIndicatorToggleChange($event.value)"
    value="sustainability"
    [ngStyle]="{
      'background-color':
        indicatorToggle == 'sustainability' ? '#cce3c3' : 'white'
    }"
    [disabled]="indicators[2].off == 1 ? true : false"
  >
    <img src="../../../assets/icon/sustentabilidad2.svg" alt="" />
    <p>Sustentabilidad</p>
  </mat-button-toggle>
</mat-button-toggle-group>

<!-- AVAILABILITY TAB-->
<div
  class="tab-container"
  *ngIf="indicatorToggle == 'availability' && !tabLoading"
>
  <div class="toggle-center">
    <mat-button-toggle-group
      class="sub-toggle"
      [value]="subIndicatorToggle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        style="width: 25%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="deviations"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'availability' &&
            subIndicatorToggle == 'deviations'
              ? '#8a86a7'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.deviations.title' | translate }}
        </span>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 25%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="projections"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'availability' &&
            subIndicatorToggle == 'projections'
              ? '#8a86a7'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.projections' | translate }}
        </span>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 25%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="historical"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'availability' &&
            subIndicatorToggle == 'historical'
              ? '#8a86a7'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.historical' | translate }}
        </span>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 25%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="historicalValues"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'availability' &&
            subIndicatorToggle == 'historicalValues'
              ? '#8a86a7'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.historicalValues' | translate }}
        </span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- DEVIATIONS -->
  <div
    class="subsection-scroll"
    *ngIf="
      subIndicatorToggle == 'deviations' && indicatorToggle == 'availability'
    "
  >
    <div class="hist-table">
      <!-- FIRST ROW -->
      <div class="table-row">
        <h3 class="blueH3">
          {{ 'HOME.indicators.availability.deviations.premises' | translate }}
        </h3>
        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent73"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.idda.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.idda
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.idda.unit }}
              {{ systemData.attributes.structure.indicator.idda.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.iddd.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.iddd
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddd.unit }}
              {{ systemData.attributes.structure.indicator.iddd.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.iddg.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.iddg
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddg.unit }}
              {{ systemData.attributes.structure.indicator.iddg.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.iddj.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.iddj
                )
              }}
              {{ systemData.attributes.structure.indicator.iddj.unit }}
              {{ systemData.attributes.structure.indicator.iddj.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
      <!-- SECOND ROW -->
      <div class="table-row">
        <h3 class="blueH3">Valores</h3>
        <div class="percentage-card gray-card"
          [ngbTooltip]="tipContent77"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                mathTools.abs(
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.indicator.iddb
                  )
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddb.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card gray-card"
          [ngbTooltip]="tipContent78"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.idde
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.idde.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card gray-card"
          [ngbTooltip]="tipContent79"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                mathTools.abs(
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.indicator.iddh
                  )
                ) * 24 | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddh.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card gray-card"
          [ngbTooltip]="tipContent80"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                mathTools.abs(
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.indicator.iddk
                  )
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddk.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
      <!-- THIRD ROW -->
      <div class="table-row">
        <h3 class="blueH3">
          {{ 'HOME.indicators.availability.deviations.deviation' | translate }}
        </h3>
        <div class="percentage-card green-card"
          [ngbTooltip]="tipContent81"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              <!-- "promDayLoad"  -> "plantDetails" -->
              <!-- "weekpromLoad" -> "records" use mathTools.abs()-->
              {{
                -systemData.attributes.plantDetails.system.Premisas[
                  systemData.attributes.structure.indicator.iddc.var[1]
                ] +
                  mathTools.abs(
                    systemData.monthly[
                      systemData.attributes.structure.indicator.iddc.var[0]
                    ]
                  ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddc.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card green-card"
          [ngbTooltip]="tipContent82"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemData.records[0][
                  systemData.attributes.structure.indicator.iddf.var[0]
                ] -
                  systemData.attributes.plantDetails.system.Premisas[
                    systemData.attributes.structure.indicator.iddf.var[1]
                  ] | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddf.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card green-card"
          [ngbTooltip]="tipContent83"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                mathTools.abs(
                  systemData.records[0][
                    systemData.attributes.structure.indicator.iddi.var[0]
                  ]
                ) *
                  24 -
                  systemData.attributes.plantDetails.system.Premisas[
                    systemData.attributes.structure.indicator.iddi.var[1]
                  ] | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddi.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div class="percentage-card green-card"
          [ngbTooltip]="tipContent84"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                mathTools.abs(
                  systemData.records[0][
                    systemData.attributes.structure.indicator.iddl.var[0]
                  ]
                ) -
                  systemData.attributes.plantDetails.system.Premisas[
                    systemData.attributes.structure.indicator.iddl.var[1]
                  ] | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.iddl.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
    </div>
  </div>
  <!-- PROJECTIONS -->
  <div
    class="subsection-scroll flex"
    *ngIf="
      subIndicatorToggle == 'projections' && indicatorToggle == 'availability'
    "
  >
    <div class="twenty-four-percent-tab">
      <div class="percentage-container">
        <!-- 1 -->
        <div class="summary-percentage-card krill-card">
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.idpa.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.idpa
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.idpa.unit }}
              {{ systemData.attributes.structure.indicator.idpa.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <!-- 2 -->
        <div class="summary-percentage-card krill-card">
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.idpb.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.idpb
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.idpb.unit }}
              {{ systemData.attributes.structure.indicator.idpb.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <!-- 3 -->
        <div class="summary-percentage-card krill-card">
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.idpc.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.idpc
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.idpc.unit }}
              {{ systemData.attributes.structure.indicator.idpc.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <!-- 4 -->
        <div class="summary-percentage-card krill-card">
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.idpd.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.idpd
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.idpd.unit }}
              {{ systemData.attributes.structure.indicator.idpd.subUnit }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
    </div>
    <div class="half-tab">
      <div class="summary">
        <h1 class="chart-title">
          {{ systemData.attributes.structure.indicator.idpe.title }}
        </h1>
        <div
          class="tooltip-icon-white chart-tooltip"
          [ngbTooltip]="tipContent71"
          tooltipClass="left half-chart-tooltip"
          placement="left"
        >
          
        </div>
        <canvas
          style="width: 100%; margin: 25px 5px 0px 5px; padding-right: 10px"
          baseChart
          [colors]="lineChartColors1"
          [datasets]="lineChartData1"
          [labels]="lineChartLabels1"
          [legend]="true"
          [chartType]="lineChartType"
          [options]="lineChartOptionsLoad1"
        >
        </canvas>
      </div>
    </div>
    <div class="half-tab">
      <div class="summary">
        <h1 class="chart-title">
          {{ systemData.attributes.structure.indicator.idpf.title }}
        </h1>
        <div
          class="tooltip-icon-white chart-tooltip"
          [ngbTooltip]="tipContent72"
          tooltipClass="left half-chart-tooltip"
          placement="left"
        >
        
        </div>
        <canvas
          style="width: 100%; margin: 25px 5px 0px 5px; padding-right: 10px"
          baseChart
          [colors]="lineChartColors2"
          [datasets]="lineChartData2"
          [labels]="lineChartLabels2"
          [legend]="true"
          chartType="bar"
          [options]="lineChartOptionsLoad2"
        >
        </canvas>
      </div>
    </div>
  </div>
  <!-- HISTORICAL -->
  <div *ngIf="tabLoading2" class="subsection-scroll flex">
    <mat-spinner class="loadSpinner"></mat-spinner>
  </div>

  <div
    class="subsection-scroll flex"
    *ngIf="
      subIndicatorToggle == 'historical' &&
      indicatorToggle == 'availability' &&
      tabLoading2 == false
    "
  >
    <div class="twenty-four-percent-tab">
      <div class="dates-container-charts">
        <ion-badge color="primary">
          <h1>Rango de tiempo:</h1>
        </ion-badge>
        <div class="dates-fields">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'TIME.from' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="from"
              [(ngModel)]="startDate"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from"
            ></mat-datepicker-toggle>
            <mat-datepicker #from disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'TIME.to' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="to"
              [(ngModel)]="endDate"
              disabled
            />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="date-buttons">
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
          <button
            (click)="downloadRecord()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Descargar
          </button>
        </div>
      </div>
    </div>

    <div class="half-tab">
      <div class="summary">
        <div
          class="tooltip-icon-blue chart-tooltip"
          [ngbTooltip]="tipContentLoadBarChart"
          tooltipClass="left chart-tooltip"
          placement="left"
        >
          <!-- <ion-icon name="help-circle"></ion-icon> -->
        </div>
        <canvas
          baseChart
          id="linearChart"
          [datasets]="lineChartDataLeft"
          [labels]="lineChartLabelsLeft"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [options]="lineChartOptionsLeft"
          [colors]="lineChartColorsLeft"
        >
        </canvas>
      </div>
    </div>

    <!-- Second canvas -->
    <div class="half-tab">
      <div class="summary">
        <div
          class="tooltip-icon-blue chart-tooltip"
          [ngbTooltip]="battBarChart"
          tooltipClass="left chart-tooltip"
          placement="left"
        >
          <!-- <ion-icon name="help-circle"></ion-icon> -->
        </div>

        <canvas
          style="width: 100%"
          baseChart
          id="linearChart"
          [datasets]="lineChartDataRight"
          [labels]="lineChartLabelsRight"
          [options]="lineChartOptionsRight"
          [legend]="lineChartLegend"
          [colors]="lineChartColorsRight"
          [chartType]="lineChartType"
        >
        </canvas>
      </div>
    </div>
  </div>
  <!-- HISTORICAL VALUES -->
  <div
    class="subsection-scroll flex"
    *ngIf="
      subIndicatorToggle == 'historicalValues' &&
      indicatorToggle == 'availability'
    "
  >
    <div class="ninety-nine-percent-tab">
      <div class="ninety-nine-percentage-container">
        <div class="hist-table">
          <div class="table-row" style="border-bottom: none !important">
            <ion-icon class="icon-row mb-15" name="newbulb"></ion-icon>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent73"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idva.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idva
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idva.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent74"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idvd.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvd
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idvd.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent75"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idvg.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    mathTools.roundToNumber2(
                      systemsService.getSystemSummaryData(
                        systemData,
                        systemData.attributes.structure.indicator.idvg
                      )
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idvg.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>
            
            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent76"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idvj.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    mathTools.roundToNumber2(
                      systemsService.getSystemSummaryData(
                        systemData,
                        systemData.attributes.structure.indicator.idvj
                      )
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idvj.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>
          </div>
          <mat-divider class="row-divider"></mat-divider>
          <div class="table-row" style="border-bottom: none !important">
            <ion-icon
              class="icon-row mb-15"
              name="electrical-tower-gray"
            ></ion-icon>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent77"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idvb.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvb
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idvb.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent78"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idve.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idve
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idve.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent79"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idvh.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    mathTools.roundToNumber2(
                      systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvh
                      )
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idvh.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent79"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <mat-card-subtitle>
                  {{ systemData.attributes.structure.indicator.idvk.label }}
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    mathTools.roundToNumber2(
                      systemsService.getSystemSummaryData(
                        systemData,
                        systemData.attributes.structure.indicator.idvk
                      )
                    ) | number : '1.0-2'
                  }}
                  {{ systemData.attributes.structure.indicator.idvk.unit }}
                </mat-card-title>
              </mat-card-header>
            </div>
          </div>
          <mat-divider
            class="row-divider"
            style="
              color: white;
              background: white !important;
              border-top-color: white !important;
            "
          ></mat-divider>
          <div class="table-row" style="border-bottom: none !important">
            <span class="img-row mb-15"> kWh </span>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent85"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <ion-icon
                  class="icon-row"
                  mat-card-avatar
                  name="newbulb"
                ></ion-icon>
                <mat-card-subtitle>
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvc
                    ) | number : '1.0-0'
                  }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent86"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <ion-icon
                  class="icon-row"
                  mat-card-avatar
                  name="electrical-tower-gray"
                ></ion-icon>
                <mat-card-subtitle>
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvf
                    ) | number : '1.0-0'
                  }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent87"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <ion-icon
                  class="icon-row"
                  mat-card-avatar
                  name="batt"
                ></ion-icon>
                <mat-card-subtitle>
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvi
                    ) | number : '1.0-0'
                  }}
                </mat-card-title>
              </mat-card-header>
            </div>

            <div class="percentage-card krill-card"
              [ngbTooltip]="tipContent88"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-header>
                <ion-icon class="icon-row"
                  name="solar"
                  mat-card-avatar
                >
                </ion-icon>
                <mat-card-subtitle>
                </mat-card-subtitle>
                <mat-card-title>
                  {{
                    systemsService.getSystemSummaryData(
                      systemData,
                      systemData.attributes.structure.indicator.idvl
                    ) | number : '1.0-0'
                  }}
                </mat-card-title>
              </mat-card-header>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PROFITABILITY TAB-->
<div
  class="tab-container"
  *ngIf="indicatorToggle == 'profitability' && !tabLoading"
>
  <div class="toggle-center">
    <mat-button-toggle-group
      class="sub-toggle"
      [value]="subIndicatorToggle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        style="width: 50%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="deviations"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'profitability' &&
            subIndicatorToggle == 'deviations'
              ? '#93a4ec'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.deviations.title' | translate }}
        </span>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 50%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="projections"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'profitability' &&
            subIndicatorToggle == 'projections'
              ? '#93a4ec'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.projections' | translate }}
        </span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- DEVIATIONS -->
  <div
    class="subsection-scroll"
    *ngIf="
      subIndicatorToggle == 'deviations' && indicatorToggle == 'profitability'
    "
  >
    <div class="hist-table">
      <!-- FIRST ROW -->
      <div class="table-row">
        <h3 class="blueH3">Caso base</h3>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent73"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.irda.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irda
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irda.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.irdd.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdd
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdd.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.irdg.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdg
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdg.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.irdj.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdj
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdj.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

      </div>
      <!-- SECOND ROW -->
      <div class="table-row">
        <h3 class="blueH3">Caso real</h3>
        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent77"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdb
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdb.label }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent78"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irde
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irde.label }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent79"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdh
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdh.label }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent80"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdk
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdk.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
      <!-- THIRD ROW -->
      <div class="table-row">
        <h3 class="blueH3">
          {{ 'HOME.indicators.availability.deviations.deviation' | translate }}
        </h3>
        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent81"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                ((systemData.records[0][
                  systemData.attributes.structure.indicator.irdc.var[0]
                ] -
                  systemData.records[0][
                    systemData.attributes.structure.indicator.irdc.var[1]
                  ]) /
                  systemData.records[0][
                    systemData.attributes.structure.indicator.irdc.var[2]
                  ]) *
                  100 | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdc.label }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent82"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                ((systemData.attributes.plantDetails.system.Premisas[
                  systemData.attributes.structure.indicator.irdf.var[0]
                ] -
                  systemData.records[0][
                    systemData.attributes.structure.indicator.irdf.var[1]
                  ]) /
                  systemData.attributes.plantDetails.system.Premisas[
                    systemData.attributes.structure.indicator.irdf.var[2]
                  ]) *
                  100 | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdf.label }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent83"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemData.records[0][
                  systemData.attributes.structure.indicator.irdi.var[0]
                ] -
                  systemData.records[0][
                    systemData.attributes.structure.indicator.irdi.var[1]
                  ] | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdi.label }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent84"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irdl
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.irdl.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
    </div>
  </div>
  <!-- PROJECTIONS -->
  <div
    class="subsection-scroll flex"
    *ngIf="
      subIndicatorToggle == 'projections' && indicatorToggle == 'profitability'
    "
    style="align-items: center"
  >
    <div class="half-tab-2">
      <div class="summary">
        <h1 class="chart-title">
          {{ systemData.attributes.structure.indicator.irpa.title }}
        </h1>
        <div
          class="tooltip-icon-white chart-tooltip"
          [ngbTooltip]="tipContent72"
          tooltipClass="left half-chart-tooltip"
          placement="left"
        >
          
        </div>
        <canvas
          style="width: 100%"
          id="linearChart"
          baseChart
          [legend]="lineChartLegend"
          chartType="bar"
          [options]="profitabilityProjChartOptions"
          [datasets]="profitabilityProjChartData"
          [labels]="profitabilityProjChartLabels"
          [colors]="profitabilityProjChartColors"
        >
        </canvas>
      </div>
    </div>

    <div class="half-tab-2">
      <div class="percentage-container">
        <!-- 1 -->
        <div class="summary-percentage-card krill-card">
          <mat-card-header>
            <ion-icon class="info-icon" mat-card-avatar name="motor"></ion-icon>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irpb
                ) | number : '1.0-2'
              }}
            </mat-card-title>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.irpb.label }}
            </mat-card-subtitle>
          </mat-card-header>
        </div>
        <!-- 2 -->
        <div class="summary-percentage-card krill-card">
          <mat-card-header>
            <ion-icon class="info-icon" mat-card-avatar name="motor"></ion-icon>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.irpc
                ) | number : '1.0-2'
              }}
            </mat-card-title>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.irpc.label }}
            </mat-card-subtitle>
          </mat-card-header>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SUSTAINABILITY TAB-->
<div
  class="tab-container"
  *ngIf="indicatorToggle == 'sustainability' && !tabLoading"
>
  <div class="toggle-center">
    <mat-button-toggle-group
      class="sub-toggle"
      [value]="subIndicatorToggle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        style="width: 49.99%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="deviations"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'sustainability' &&
            subIndicatorToggle == 'deviations'
              ? '#cce3c3'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.deviations.title' | translate }}
        </span>
      </mat-button-toggle>
      <mat-button-toggle
        style="width: 49.99%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="projections"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'sustainability' &&
            subIndicatorToggle == 'projections'
              ? '#cce3c3'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.projections' | translate }}
        </span>
      </mat-button-toggle>
      <!-- <mat-button-toggle
        style="width: 33.33%"
        (change)="onSubIndicatorToggleChange($event.value)"
        value="historical"
        [ngStyle]="{
          'background-color':
            indicatorToggle == 'sustainability' &&
            subIndicatorToggle == 'historical'
              ? '#cce3c3'
              : 'white'
        }"
      >
        <span>
          {{ 'HOME.indicators.availability.historical' | translate }}
        </span>
      </mat-button-toggle> -->
    </mat-button-toggle-group>
  </div>

  <!-- DEVIATIONS -->
  <div
    class="subsection-scroll"
    *ngIf="
      subIndicatorToggle == 'deviations' && indicatorToggle == 'sustainability'
    "
  >
    <div class="hist-table">
      <!-- FIRST ROW -->
      <div class="table-row">
        <h3 class="blueH3">
          {{ 'HOME.indicators.availability.deviations.premises' | translate }}
        </h3>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent73"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.isda.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isda
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isda.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent74"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.isdd.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdd
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdd.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

        <div class="percentage-card krill-card"
          [ngbTooltip]="tipContent75"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-subtitle>
              {{ systemData.attributes.structure.indicator.isdg.label }}
            </mat-card-subtitle>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdg
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdg.unit }}
            </mat-card-title>
          </mat-card-header>
        </div>

      </div>
      <!-- SECOND ROW -->
      <div class="table-row">
        <h3 class="blueH3">Valores</h3>

        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent77"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdb
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdb.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent78"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isde
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isde.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div
          class="percentage-card gray-card"
          [ngbTooltip]="tipContent79"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdh
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdh.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
      <!-- THIRD ROW -->
      <div class="table-row">
        <h3 class="blueH3">
          {{ 'HOME.indicators.availability.deviations.deviation' | translate }}
        </h3>

        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent81"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdc
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdc.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent82"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdf
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdf.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
        <div
          class="percentage-card green-card"
          [ngbTooltip]="tipContent83"
          tooltipClass="top"
          placement="top"
        >
          <mat-card-header>
            <mat-card-title>
              {{
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.indicator.isdi
                ) | number : '1.0-2'
              }}
              {{ systemData.attributes.structure.indicator.isdi.label }}
            </mat-card-title>
          </mat-card-header>
        </div>
      </div>
    </div>
  </div>
  <!-- PROJECTIONS -->
  <div
    class="subsection-scroll flex"
    *ngIf="
      subIndicatorToggle == 'projections' && indicatorToggle == 'sustainability'
    "
    style="align-items: center"
  >
    <div class="half-tab-2">
      <div class="summary" style="background: transparent">
        <mat-card class="proy-row">
          <span class="table-card-icon" matTooltip="Fecha">
            <ion-icon name="calendar-clear-outline"></ion-icon>
          </span>
          <div
            class="cell"
            *ngFor="let data of indicatorsProjections.data.weatherRequest.daily"
          >
            {{ data.dt * 1000 | date : 'EEE d' }}
            <p class="small">{{ data.dt * 1000 | date : 'MMM' }}</p>
          </div>
        </mat-card>
        <mat-card class="proy-row weather-card">
          <span class="table-card-icon" matTooltip="Clima">
            <ion-icon name="partly-sunny-outline"></ion-icon>
          </span>
          <div
            class="cell"
            *ngFor="let data of indicatorsProjections.data.weatherRequest.daily"
          >
            <img
              [src]="
                '../../../../assets/weather/1x/' + data.weather[0].icon + '.png'
              "
              alt=""
            />
          </div>
        </mat-card>
        <mat-card class="proy-row proy-card">
          <span class="table-card-icon" matTooltip="Temperatura">
            <ion-icon name="thermometer-outline"></ion-icon>
          </span>
          <div
            class="cell"
            *ngFor="let data of indicatorsProjections.data.weatherRequest.daily"
          >
            {{ (data.temp.min + data.temp.max) / 2 | number : '1.0-2' }}
            <p class="small">
              {{ systemData.attributes.structure.indicator.ispa.unit }}
            </p>
          </div>
        </mat-card>
      </div>
    </div>

    <div class="half-tab-2">
      <div class="summary">
        <h1 class="chart-title">
          {{ systemData.attributes.structure.indicator.ispb.title }}
        </h1>
        <div
          class="tooltip-icon-white chart-tooltip"
          [ngbTooltip]="availableMonitChart"
          tooltipClass="left half-chart-tooltip"
          placement="left"
        >
         
        </div>
        <canvas
          style="width: 100%"
          id="linearChart"
          baseChart
          [colors]="batChartColorSust"
          [datasets]="lineChartDataSust"
          [labels]="lineChartLabelsSust"
          [legend]="lineChartLegend"
          [chartType]="barChartType"
          [options]="lineChartOptionsSust"
        >
        </canvas>
      </div>
    </div>
  </div>
  <!-- HISTORICAL -->
  <div
    class="subsection-scroll flex"
    *ngIf="
      subIndicatorToggle == 'historical' && indicatorToggle == 'sustainability'
    "
  >
    <div class="twenty-four-percent-tab">
      <div class="dates-container-charts">
        <ion-badge color="primary">
          <h1>Rango de tiempo:</h1>
        </ion-badge>
        <div class="dates-fields">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'TIME.from' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="from"
              [(ngModel)]="startDate"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="from"
            ></mat-datepicker-toggle>
            <mat-datepicker #from disabled="false"></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'TIME.to' | translate }}</mat-label>
            <input
              matInput
              [matDatepicker]="to"
              [(ngModel)]="endDate"
              disabled
            />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <button
          (click)="changeChartsInterval()"
          class="date-button"
          mat-raised-button
          color="primary"
          style="margin-left: 0px"
        >
          Buscar
        </button>
        <!-- Oculto por ahora
        <button class="date-button" mat-raised-button color="primary">
          Descargar
        </button> -->
      </div>
    </div>

    <div class="fourty-five-percent-tab">
      <div class="summary">
        <h1 class="chart-title">Perfil PV</h1>
        <div
          class="tooltip-icon-white chart-tooltip"
          [ngbTooltip]="solarChart"
          tooltipClass="left half-chart-tooltip"
          placement="left"
        >
          
        </div>
        <canvas
          style="width: 100%"
          baseChart
          [colors]="lineChartColors"
          [datasets]="lineChartDataHist"
          [labels]="lineChartLabelsHist"
          [legend]="lineChartLegend"
          [chartType]="lineChartType"
          [options]="lineChartOptionsSust"
        >
        </canvas>
      </div>
    </div>
  </div>
</div>

<div class="tab-container" *ngIf="tabLoading3">
  <div *ngIf="tabLoading" class="bars-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

<!-- Tooltips -->

<!-- indicators -->
<ng-template #tipContent11>
  {{ 'SYSTEM.tooltips.11' | translate }}
</ng-template>
<ng-template #tipContent12>
  {{ 'SYSTEM.tooltips.12' | translate }}
</ng-template>
<ng-template #tipContent13>
  {{ 'SYSTEM.tooltips.13' | translate }}
</ng-template>
<ng-template #tipContent14>
  {{ 'SYSTEM.tooltips.14' | translate }}
</ng-template>

<ng-template #tipContent53>
  {{ 'SYSTEM.tooltips.53' | translate }}
</ng-template>
<ng-template #tipContent54>
  {{ 'SYSTEM.tooltips.54' | translate }}
</ng-template>
<!-- Availability tips -->
<!-- monitoreo -->
<ng-template #tipContent55>
  {{ 'SYSTEM.tooltips.55' | translate }}
</ng-template>
<ng-template #tipContent56>
  {{ 'SYSTEM.tooltips.56' | translate }}
</ng-template>
<ng-template #tipContent57>
  {{ 'SYSTEM.tooltips.57' | translate }}
</ng-template>
<ng-template #tipContent58>
  {{ 'SYSTEM.tooltips.58' | translate }}
</ng-template>
<ng-template #tipContent59>
  {{ 'SYSTEM.tooltips.59' | translate }}
</ng-template>
<ng-template #availableMonitChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.63' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.pBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.62' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.61' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.60' | translate }}
</ng-template>
<!-- Desviaciones -->
<ng-template #tipContent64>
  {{ 'SYSTEM.tooltips.64' | translate }}
</ng-template>
<ng-template #tipContent65>
  {{ 'SYSTEM.tooltips.65' | translate }}
</ng-template>
<ng-template #tipContent66>
  {{ 'SYSTEM.tooltips.66' | translate }}
</ng-template>
<ng-template #tipContent67>
  {{ 'SYSTEM.tooltips.67' | translate }}
</ng-template>
<ng-template #tipContent68>
  {{ 'SYSTEM.tooltips.68' | translate }}
</ng-template>
<ng-template #tipContent69>
  {{ 'SYSTEM.tooltips.69' | translate }}
</ng-template>
<ng-template #tipContent70>
  {{ 'SYSTEM.tooltips.70' | translate }}
</ng-template>
<!-- Proyecciones -->
<ng-template #tipContent71>
  {{ 'SYSTEM.tooltips.71' | translate }}
</ng-template>
<ng-template #tipContent72>
  {{ 'SYSTEM.tooltips.72' | translate }}
</ng-template>
<ng-template #tipContent104>
  {{ 'SYSTEM.tooltips.104' | translate }}
</ng-template>
<!-- Historicos -->
<ng-template #tipContent73>
  {{ 'SYSTEM.tooltips.73' | translate }}
</ng-template>
<ng-template #tipContent74>
  {{ 'SYSTEM.tooltips.74' | translate }}
</ng-template>
<ng-template #tipContent75>
  {{ 'SYSTEM.tooltips.75' | translate }}
</ng-template>
<ng-template #tipContent76>
  {{ 'SYSTEM.tooltips.76' | translate }}
</ng-template>
<ng-template #tipContent77>
  {{ 'SYSTEM.tooltips.77' | translate }}
</ng-template>
<ng-template #tipContent78>
  {{ 'SYSTEM.tooltips.78' | translate }}
</ng-template>
<ng-template #tipContent79>
  {{ 'SYSTEM.tooltips.79' | translate }}
</ng-template>
<ng-template #tipContent80>
  {{ 'SYSTEM.tooltips.80' | translate }}
</ng-template>
<ng-template #tipContent81>
  {{ 'SYSTEM.tooltips.81' | translate }}
</ng-template>
<ng-template #tipContent82>
  {{ 'SYSTEM.tooltips.82' | translate }}
</ng-template>
<ng-template #tipContent83>
  {{ 'SYSTEM.tooltips.83' | translate }}
</ng-template>
<ng-template #tipContent84>
  {{ 'SYSTEM.tooltips.84' | translate }}
</ng-template>
<ng-template #tipContent85>
  {{ 'SYSTEM.tooltips.85' | translate }}
</ng-template>
<ng-template #tipContent86>
  {{ 'SYSTEM.tooltips.86' | translate }}
</ng-template>
<ng-template #tipContent87>
  {{ 'SYSTEM.tooltips.87' | translate }}
</ng-template>
<ng-template #tipContent88>
  {{ 'SYSTEM.tooltips.88' | translate }}
</ng-template>
<!-- Sust tips -->
<!-- monitoreo -->
<ng-template #tipContent89>
  {{ 'SYSTEM.tooltips.89' | translate }}
</ng-template>
<ng-template #tipContent90>
  {{ 'SYSTEM.tooltips.90' | translate }}
</ng-template>
<ng-template #tipContent91>
  {{ 'SYSTEM.tooltips.91' | translate }}
</ng-template>
<ng-template #tipContent92>
  {{ 'SYSTEM.tooltips.92' | translate }}
</ng-template>
<ng-template #tipContent93>
  {{ 'SYSTEM.tooltips.93' | translate }}
</ng-template>
<ng-template #sustMonitChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.94' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.95' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vpv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.96' | translate }}
</ng-template>
<!-- desviaciones -->
<ng-template #tipContent97>
  {{ 'SYSTEM.tooltips.97' | translate }}
</ng-template>
<ng-template #tipContent98>
  {{ 'SYSTEM.tooltips.98' | translate }}
</ng-template>
<ng-template #tipContent99>
  {{ 'SYSTEM.tooltips.99' | translate }}
</ng-template>
<ng-template #tipContent100>
  {{ 'SYSTEM.tooltips.100' | translate }}
</ng-template>
<ng-template #tipContent101>
  {{ 'SYSTEM.tooltips.101' | translate }}
</ng-template>
<ng-template #tipContent102>
  {{ 'SYSTEM.tooltips.102' | translate }}
</ng-template>
<ng-template #tipContent103>
  {{ 'SYSTEM.tooltips.103' | translate }}
</ng-template>
<ng-template #tipContent105>
  {{ 'SYSTEM.tooltips.105' | translate }}
</ng-template>
<ng-template #tipContent106>
  {{ 'SYSTEM.tooltips.106' | translate }}
</ng-template>
<ng-template #tipContent107>
  {{ 'SYSTEM.tooltips.107' | translate }}
</ng-template>
<ng-template #tipContent108>
  {{ 'SYSTEM.tooltips.108' | translate }}
</ng-template>

<ng-template #tipContentLoadBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.9' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eacChargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.5' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.bars.eBatDischargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.7' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.bars.eBatChargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.8' | translate }}
  <br />
  <span style="color: rgba(153, 0, 76)">
    {{ 'SYSTEM.charts.bars.epvTodayzone' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.6' | translate }}
</ng-template>
<ng-template #battBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.38' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eBatDischargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.37' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.bars.eBatChargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.39' | translate }}
</ng-template>
<ng-template #solarBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.49' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.epvToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.48' | translate }}
</ng-template>
<ng-template #solarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.50' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.51' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vpv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.52' | translate }}
</ng-template>
