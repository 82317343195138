import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  public async error () {
    Swal.fire({
      title: 'Error!',
      text: 'Do you want to continue',
      icon: 'error',
      confirmButtonText: 'Cool'
    })
  }

  public async warning (title, text, icon, confirmText, cancelText) {
    let swal = await Swal.fire({
      title: title,
      text: text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      icon: icon,
      confirmButtonText: confirmText,
      confirmButtonColor: '#001f9d',
      cancelButtonText: cancelText,
      showCancelButton: true,
      backdrop: 'transparent',
      heightAuto: false
    }).then((result) => {
      return result;
    })
    return swal;
  }

  public async message (title, text, icon, confirmText){
    let swal = await Swal.fire({
      title: title,
      text: text,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      icon: icon,
      confirmButtonText: confirmText,
      confirmButtonColor: '#001f9d',
      //backdrop: 'transparent',
      heightAuto: false
    }).then((result) => {
      return result;
    })
    return swal;
  }
}
