import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as moment from 'moment-timezone';
import { SystemsService } from 'src/app/services/systems/systems.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { MathToolsService } from 'src/app/services/mathTools/math-tools.service';
import { Utils } from 'src/app/utils/utils';
import { ReportsService } from 'src/app/services/reports/reports.service';

@Component({
  selector: 'indicators-tabs',
  templateUrl: './indicators-tabs.component.html',
  styleUrls: ['./indicators-tabs.component.scss']
})
export class IndicatorsTabsComponent implements OnInit {
  @Input() systemData: any;
  @Input() systemIndicatorsData: any;
  @Input() indicatorsDeviation: any;
  @Input() indicatorsProjections: any;
  @Input() indicators: any;

  // @Input() indicatorToggle: any;
  // @Input() subIndicatorToggle: any;

  //indicators
  systemId: string;

  sidenavOpened: boolean;
  pageLoading: boolean = true;
  barsLoading: boolean = false;
  tabLoading: boolean = false;
  tabLoading2: boolean = false;
  tabLoading3: boolean = false;
  histLoading: boolean = false;

  actualTab: string = 'availability';
  actualSubTabDeviations: string = 'deviations';
  indicatorToggle: string = 'availability';
  subIndicatorToggle: string = 'deviations';

  toggleTimeValue: string = '';

  availabilityHistoricsTable: any;
  availabilityHistoricalValues: any;

  promEnergiaDiariaCarga = 0;

  sustHistTable: any;

  //dates
  defaultDate: string = '';
  startDate: string = '';
  endDate: string = '';

  lineChartDataHist: ChartDataSets[] = [];
  lineChartLabelsHist: Label[] = [];
  lineChartOptionsLoad: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 7
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 8
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencia (kW)'
          }
        }
      ]
    }
  };

  lineChartDataSust: any;
  lineChartLabelsSust: any;
  lineChartOptionsSust = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 12
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencia (W)'
          }
        }
      ]
    }
  };

  lineChartLegend = true;
  lineChartType = 'line';

  lineChartColors: Color[] = [
    {
      borderColor: 'rgba(0,205,255,0.5)',
      backgroundColor: 'rgba(0,205,255,0.28)',
      pointBackgroundColor: 'rgba(0,205,255,0.5)'
    },
    {
      borderColor: 'rgba(0,89,255,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(0,89,255,0.7)'
    },
    {
      borderColor: 'rgba(26,46,127,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(26,46,127,0.7)'
    },
    {
      borderColor: 'rgba(62,36,130,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(62,36,130,0.7)'
    }
  ];

  batChartColorSust: Color[] = [];

  //========= Autonomia Estocastica ===========
  lineChartData1: ChartDataSets[] = [];

  lineChartLabels1: Label[] = [];

  lineChartColors1: Color[] = [];

  lineChartOptionsLoad1 = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            // userCallback: function (tick) {
            //   return tick.toString() + ' h';
            // },
            maxTicksLimit: 12
          },
          gridLines: {
            drawOnChartArea: false
          },
          scaleLabel: {
            display: true,
            labelString: 'DURACIÓN FALLA (horas)'
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            userCallback: function (tick) {
              return tick.toString() + '%';
            },
            beginAtZero: true,
            maxTicksLimit: 6
          },
          scaleLabel: {
            display: true,
            labelString: 'PROB. SATISFACER DEMANDA'
          }
        }
      ]
    }
  };

  //========= Expectativa de Vida Estocastica ===========
  lineChartData2: ChartDataSets[] = [];

  lineChartLabels2: Label[] = [];

  lineChartColors2: Color[] = [];

  lineChartOptionsLoad2 = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            maxTicksLimit: 12
          },
          gridLines: {
            drawOnChartArea: false
          },
          scaleLabel: {
            display: true,
            labelString: 'Años'
          }
        }
      ],
      yAxes: [
        {
          id: 'linear',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            userCallback: function (tick) {
              return tick.toString() + '%';
            },
            beginAtZero: true,
            maxTicksLimit: 6
          },
          scaleLabel: {
            display: true,
            labelString: 'PROB. Vida Util BESS'
          }
        },
        {
          id: 'bar',
          type: 'linear',
          display: false,
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            display: false,
            autoSkip: true,
            maxTicksLimit: 6,
            min: 0,
            max: 1
          },
          scaleLabel: {
            display: false
          }
        }
      ]
    }
  };

  //========= Disponibilidad - Historical (Left)===========
  lineChartDataLeft: ChartDataSets[] = [];

  lineChartLabelsLeft: Label[] = [];

  lineChartOptionsLeft: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 5,
            beginAtZero: true
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5
          },
          scaleLabel: {
            display: true,
            labelString: 'Potencia (kW)'
          }
        }
      ]
    }
  };

  lineChartColorsLeft: Color[] = [
    {
      //load
      borderColor: '#f4b434 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#f4b434 '
    },
    {
      //battery
      borderColor: ' #fc8c64 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #fc8c64 '
    },
    {
      //solar
      borderColor: '#7caccc ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#7caccc '
    },
    {
      //electrical net
      borderColor: ' #8484a4',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #8484a4'
    },
    {
      //motor
      borderColor: '#b788bf ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#b788bf '
    }
  ];

  //========= Disponibilidad - Historical (Right) ===========
  lineChartDataRight: ChartDataSets[] = [];

  lineChartLabelsRight: Label[] = [];

  lineChartOptionsRight: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 5,
            beginAtZero: true
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'first-y-axis',
          type: 'linear',
          position: 'left',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            beginAtZero: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Voltaje (V)'
          }
        },
        {
          id: 'second-y-axis',
          type: 'linear',
          position: 'right',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 5,
            beginAtZero: true
            // min: 0,
            // max: 1
          },
          scaleLabel: {
            display: true,
            labelString: 'Corriente (A)'
          }
        }
      ]
    }
  };
  // barChartOptions: ChartOptions = {
  //   responsive: true,
  //   legend: {
  //     labels: { usePointStyle: true }
  //   },
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           autoSkip: true,
  //           maxTicksLimit: 6,
  //           beginAtZero: true
  //         },
  //         scaleLabel: {
  //           display: true,
  //           labelString: 'Energía acumulada (kWh)'
  //         }
  //       }
  //     ]
  //   }
  // };
  // barChartDataRight: ChartDataSets[] = [];
  // barChartLabelsRight: any;
  barChartType: ChartType = 'bar';
  // barChartLegend = true;
  lineChartColorsRight: Color[] = [
    {
      //electrical net
      borderColor: ' #8484a4',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #8484a4'
    },
    {
      //motor
      borderColor: '#b788bf ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#b788bf '
    }
  ];

  //======= Profitability - Projections =======
  profitabilityProjChartOptions: ChartOptions = {
    elements: {
      point: {
        radius: 0
      }
    },
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: false
    },
    legend: {
      labels: { usePointStyle: true }
    },
    scales: {
      xAxes: [
        {
          display: true,
          ticks: {
            autoSkip: true,
            minRotation: 0,
            maxRotation: 0,
            maxTicksLimit: 4
          },
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          id: 'linear',
          type: 'linear',
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6
          },
          scaleLabel: {
            display: true,
            labelString: 'USD ($)'
          }
        },
        {
          id: 'bar',
          type: 'linear',
          display: false,
          gridLines: {
            drawOnChartArea: false
          },
          ticks: {
            display: false,
            autoSkip: true,
            maxTicksLimit: 6,
            min: 0,
            max: 1
          },
          scaleLabel: {
            display: false
          }
        }
      ]
    }
  };
  profitabilityProjChartData: ChartDataSets[] = [];
  profitabilityProjChartLabels: Label[] = [];
  profitabilityProjChartColors: Color[] = [
    {
      borderColor: 'rgba(0,205,255,0.5)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(0,205,255,0.5)'
    },
    {
      borderColor: 'rgba(0,89,255,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(0,89,255,0.7)'
    },
    {
      borderColor: 'rgba(255,0,0,0.7)',
      pointBackgroundColor: 'rgba(255,0,0,0.7)',
      backgroundColor: 'rgba(255,0,0,1)'
    }
  ];

  constructor(
    public sidenavService: SidenavService,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    private translate: TranslateConfigService,
    private config: NgbTooltipConfig,
    public sweetAlertService: SweetAlertService,
    public mathTools: MathToolsService,
    private reportsService: ReportsService
  ) {
    config.disableTooltip = true;
    config.container = 'body';
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    // Set spinner Diameter and charts Ticks
    this.maxLimitChartsChange();

    this.systemDataSetup();

    await this.actualIndicator();

    await this.getAllMonitoringChartsData();

    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemData.id
    );
    this.pageLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.maxLimitChartsChange();
  }

  async systemDataSetup() {
    if (this.indicators[0].off == 1) {
      if (this.indicators[1].off == 1) {
        if (this.indicators[2].off == 1) {
          this.actualTab = '';
          this.indicatorToggle = '';
          this.tabLoading3 = true;
        } else {
          this.actualTab = 'sustainability';
          this.indicatorToggle = 'sustainability';
        }
      } else {
        this.actualTab = 'profitability';
        this.indicatorToggle = 'profitability';
      }
    } else {
      this.actualTab = 'availability';
      this.indicatorToggle = 'availability';
    }

    this.defaultDate = moment(new Date())
      .utcOffset(this.systemData.attributes.timeZone)
      .format('yyyy-MM-DD');

    this.systemId = this.systemData.id;

    //==== Chart Availability - projections - Autonomia Estocastica ====
    //data
    this.lineChartData1 =
      this.systemData.attributes.structure.indicator.idpe.chart.data;
    //labels
    this.lineChartLabels1 =
      this.systemData.attributes.structure.indicator.idpe.chart.labels;
    //colors
    this.lineChartColors1 =
      this.systemData.attributes.structure.indicator.idpe.chart.colors;

    //==== Chart Availability - projections - Expectativa de Vida Estocastica ====
    //data
    this.lineChartData2 =
      this.systemData.attributes.structure.indicator.idpf.chart.data;
    //labels
    this.lineChartLabels2 =
      this.systemData.attributes.structure.indicator.idpf.chart.labels;
    //colors
    this.lineChartColors2 =
      this.systemData.attributes.structure.indicator.idpf.chart.colors;
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  async tabChanged(tabChangeEvent: MatButtonToggleChange): Promise<void> {
    this.tabLoading = true;
    this.tabLoading3 = true;

    this.actualTab = tabChangeEvent.value;

    let yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    //subIndicatorToggle will always be in deviations
    this.subIndicatorToggle = 'deviations';

    //console.log("actualTab: ", this.actualTab);

    switch (this.actualTab) {
      case 'availability':
        localStorage.setItem('indicator', this.actualTab);
        // await this.getAvailabilityHistBars('week'); dont work
        await this.getAvailabilityHistoricalValues(true);
        await this.getAvailabilityHistChartData(
          yesterdayDate,
          this.defaultDate
        );
        break;

      case 'profitability':
        localStorage.setItem('indicator', this.actualTab);
        // await this.getProjectionsData();
        break;

      case 'sustainability':
        localStorage.setItem('indicator', this.actualTab);
        await this.getSustData(this.defaultDate, this.defaultDate);
        break;
    }
    this.tabLoading = false;
    this.tabLoading3 = false;
  }

  /**
   * Get all projections data
   */
  async getProjectionsData() {
    //  PROFITABILITY
    let lineChartData = await this.indicatorsService.getProjectionsProfChart();
    console.log('lineChartData: ', lineChartData);

    //min and max
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits.xMinAxevIRPA;
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits.xMaxAxexIRPA;

    this.profitabilityProjChartLabels = lineChartData.lineChartLables.map(
      (date) => {
        return moment(date)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('MMM yyyy');
      }
    );
  }

  async getAvailabilityHistoricalValues(isDefault: boolean = false) {
    if (isDefault) {
      this.availabilityHistoricalValues =
        await this.systemsService.getRecordsHistoryEvents(
          this.systemData.id,
          this.systemData.attributes.equipmentId,
          this.defaultDate,
          this.defaultDate,
          this.systemData.attributes.timeZone,
          true
        );
    } else {
      if (this.startDate == '' && this.endDate != '') {
        //Si la fecha final es mayor que la actual, error
        if (
          moment(this.endDate)
            .utcOffset(this.systemData.attributes.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate
        ) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'La fecha final no puede ser mayor a la fecha actual',
            'warning',
            'OK'
          );
          return;
        }

        this.availabilityHistoricalValues =
          await this.systemsService.getRecordsHistoryEvents(
            this.systemData.id,
            this.systemData.attributes.equipmentId,
            this.endDate,
            this.endDate,
            this.systemData.attributes.timeZone,
            true
          );
      } else if (this.startDate != '' && this.endDate == '') {
        //Si la fecha inicial es mayor que la actual, error
        if (
          moment(this.startDate)
            .utcOffset(this.systemData.attributes.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate
        ) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'La fecha inicial no puede ser mayor a la fecha actual',
            'warning',
            'OK'
          );
          return;
        }

        this.availabilityHistoricalValues =
          await this.systemsService.getRecordsHistoryEvents(
            this.systemData.id,
            this.systemData.attributes.equipmentId,
            this.startDate,
            this.startDate,
            this.systemData.attributes.timeZone,
            true
          );
      } else if (this.startDate != '' && this.endDate != '') {
        //Si la fecha inicial es mayor que la final, error
        if (this.startDate > this.endDate) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'La fecha final tiene que ser mayor a la fecha inicial',
            'warning',
            'OK'
          );
          return;
        }
        //Si ambas fechas son mayores a la fecha actual, error
        if (
          moment(this.startDate)
            .utcOffset(this.systemData.attributes.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate &&
          moment(this.endDate)
            .utcOffset(this.systemData.attributes.timeZone)
            .format('yyyy-MM-DD') > this.defaultDate
        ) {
          this.sweetAlertService.message(
            'Fecha inválida',
            'Ambas fechas no pueden ser mayor a la fecha actual',
            'warning',
            'OK'
          );
          return;
        }

        this.availabilityHistoricalValues =
          await this.systemsService.getRecordsHistoryEvents(
            this.systemData.id,
            this.systemData.attributes.equipmentId,
            this.startDate,
            this.endDate,
            this.systemData.attributes.timeZone,
            false
          );
      }
    }

    //console.log("availabilityHistoricalValues: ", this.availabilityHistoricalValues)
  }

  async getSustData(startDate: string, endDate: string) {
    let solarChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      3,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    this.lineChartDataHist = solarChartsData.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.linear.' + data.label
      );
      return data;
    });

    this.lineChartLabelsHist = solarChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD-MM hh:mm');
    });
  }

  /**
   * Get all configurations for all monitoring charts (First chart of each tab)
   */
  async getAllMonitoringChartsData() {
    //==== AVAILABILITY ====

    //==== AVAILABILITY - PROJECTIONS ====
    let dateToday = moment().toISOString();
    let dateSystem = this.systemData.attributes.createdAt;
    let diffYears = moment(dateToday).diff(moment(dateSystem), 'years');
    this.lineChartData2.push({
      data: [
        {
          x: diffYears.toString(),
          y: 1
        }
      ],
      yAxisID: 'bar',
      label: 'Año actual',
      barThickness: 2,
      borderColor: 'rgba(255,0,0,1)',
      pointBackgroundColor: 'rgba(255,0,0,1)'
    });

    //==== PROFITABILITY ====
    //Min and max limit
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.indicator.irpa.limits.varMin
      ];
    this.profitabilityProjChartOptions.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.indicator.irpa.limits.varMax
      ];

    //==== SUSTAINABILITY ====
    //data
    this.lineChartDataSust = [
      {
        data: [
          5.39, 5.75, 6.18, 5.83, 5.51, 5.48, 5.78, 5.89, 5.8, 5.38, 5.03, 4.97
        ],
        label: 'PV',
        pointBackgroundColor: 'rgba(0,205,255,0.5)',
        yAxisID: 'first-y-axis'
      }
    ];
    //label
    this.lineChartLabelsSust = [
      'ene.',
      'feb.',
      'mar.',
      'abr.',
      'may.',
      'jun.',
      'jul.',
      'ago.',
      'sept.',
      'oct.',
      'nov.',
      'dic.'
    ];
    //colors
    this.batChartColorSust =
      this.systemData.attributes.structure.indicator.ispb.chart.colors;
  }

  changeHistoricsInterval(intervalTime: string) {
    let today = moment(new Date());
    switch (intervalTime) {
      case 'week':
        this.endDate = today.add(1, 'd').format('yyyy-MM-DD');
        this.startDate = today.subtract(7, 'd').format('yyyy-MM-DD');

        break;
      case 'month':
        this.endDate = today.add(1, 'd').format('yyyy-MM-DD');
        this.startDate = today.subtract(1, 'M').format('yyyy-MM-DD');
        break;
      case 'year':
        this.endDate = today.add(1, 'd').format('yyyy-MM-DD');
        this.startDate = today.subtract(1, 'y').format('yyyy-MM-DD');
        break;

      default:
        break;
    }
  }

  /**
   * Change the indicator tab from toggle value
   * @param indicator
   */
  onIndicatorToggleChange(indicator) {
    this.indicatorToggle = indicator;
  }

  /**
   * Change the indicator tab from sub toggle value
   * @param indicator
   */
  onSubIndicatorToggleChange(indicator) {
    //console.log("indicatorToggle and subIndicatorToggle: ", this.indicatorToggle, indicator)
    this.subIndicatorToggle = indicator;
  }

  public resetToggleTime() {
    this.toggleTimeValue = null;
  }

  validateDates(startDate: any, endDate: any) {
    let notPass = false;
    this.defaultDate = moment(new Date())
      .utcOffset(this.systemData.attributes.timeZone)
      .format('yyyy-MM-DD');

    if (startDate == '' && endDate == '') {
      this.sweetAlertService.message(
        'Fecha inválida',
        'Ingrese una fecha inicial y final',
        'warning',
        'OK'
      );
      return notPass;
    } else if (startDate == '' && endDate != '') {
      if (
        moment(endDate)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha final no puede ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
      return true;
    } else if (startDate != '' && endDate == '') {
      if (
        moment(startDate)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha inicial no puede ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
      return true;
    } else if (startDate != '' && endDate != '') {
      if (
        moment(endDate)
          .utcOffset(this.systemData.attributes.timeZone)
          .diff(
            moment(startDate).utcOffset(this.systemData.attributes.timeZone),
            'days'
          ) > 32
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La diferencia de fechas no puede ser mayor a 32 días',
          'warning',
          'OK'
        );
        return notPass;
      }
      if (startDate > endDate) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha final tiene que ser mayor a la fecha inicial',
          'warning',
          'OK'
        );
        return notPass;
      }
      if (
        moment(startDate)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate &&
        moment(endDate)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'Ambas fechas no pueden ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
      if (
        moment(endDate)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('yyyy-MM-DD') > this.defaultDate
      ) {
        this.sweetAlertService.message(
          'Fecha inválida',
          'La fecha final no puede ser mayor a la fecha actual',
          'warning',
          'OK'
        );
        return notPass;
      }
    }
  }

  async changeChartsInterval() {
    if (this.validateDates(this.startDate, this.endDate) != false) {
      let startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
      let endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

      if (localStorage.getItem('indicator') == 'availability') {
        this.tabLoading2 = true;
        await this.getAvailabilityHistChartData(startDate, endDate);
        this.tabLoading2 = false;
      }
    }
  }

  async getAvailabilityHistChartData(startDate: string, endDate: string) {
    //RecordsChart Left
    let loadChartsData = await this.systemsService.getRecordsChartIndicator(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    let leftChartData = [];
    let rightChartData = [];

    loadChartsData.lineChartData.forEach((data) => {
      if (data.label == 'iBat') {
        rightChartData.push(data);
      }
      if (data.label == 'vBat') {
        rightChartData.push(data);
      }
      if (data.label != 'iBat' && data.label != 'vBat') {
        leftChartData.push(data);
      }
    });

    this.lineChartDataLeft = leftChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.linear.' + data.label
      );
      return data;
    });

    //max and min left chart
    // this.lineChartOptionsLeft.scales.yAxes[0].ticks.min =
    //   this.systemData.attributes.plantDetails.system.FrontLimits[
    //     this.systemData.attributes.structure.indicator.idga.limits.varMin
    //   ];
    // this.lineChartOptionsLeft.scales.yAxes[0].ticks.max =
    //   this.systemData.attributes.plantDetails.system.FrontLimits[
    //     this.systemData.attributes.structure.indicator.idga.limits.varMax
    //   ];

    this.lineChartLabelsLeft = loadChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });

    //RecordsChart Right
    this.lineChartDataRight = [
      {
        data: rightChartData[0].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' + rightChartData[0].label
        ),
        pointBackgroundColor: 'rgba(0,205,255,0.5)',
        yAxisID: 'first-y-axis'
      },
      {
        data: rightChartData[1].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' + rightChartData[1].label
        ),
        pointBackgroundColor: 'rgba(0,89,255,0.7)',
        yAxisID: 'second-y-axis'
      }
    ];

    //max and min right chart
    // this.lineChartOptionsRight.scales.yAxes[0].ticks.min =
    //   this.systemData.attributes.plantDetails.system.FrontLimits[
    //     this.systemData.attributes.structure.indicator.idgb.limits.varMin
    //   ];
    // this.lineChartOptionsRight.scales.yAxes[0].ticks.max =
    //   this.systemData.attributes.plantDetails.system.FrontLimits[
    //     this.systemData.attributes.structure.indicator.idgb.limits.varMax
    //   ];

    this.lineChartLabelsRight = loadChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });
  }

  public maxLimitChartsChange() {
    if (window.innerWidth > 800) {
      this.lineChartOptionsLeft.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsLeft.scales.yAxes[0].ticks.maxTicksLimit = 5;
    } else if (window.innerWidth <= 800) {
      this.lineChartOptionsLeft.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsLeft.scales.yAxes[0].ticks.maxTicksLimit = 5;
    }
  }

  async actualIndicator() {
    let yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    if (localStorage.getItem('indicator') != null) {
      if (
        localStorage.getItem('indicator') == 'availability' &&
        this.indicators[0].off == 0
      ) {
        this.indicatorToggle = localStorage.getItem('indicator');
        // await this.getAvailabilityHistBars('week');
        await this.getAvailabilityHistoricalValues(true);
        await this.getAvailabilityHistChartData(
          yesterdayDate,
          this.defaultDate
        );
      } else if (
        localStorage.getItem('indicator') == 'profitability' &&
        this.indicators[1].off == 0
      ) {
        this.indicatorToggle = localStorage.getItem('indicator');
        await this.getProjectionsData();
      } else if (
        localStorage.getItem('indicator') == 'sustainability' &&
        this.indicators[2].off == 0
      ) {
        this.indicatorToggle = localStorage.getItem('indicator');
        await this.getSustData(this.defaultDate, this.defaultDate);
      }
    } else {
      this.indicatorToggle = 'availability';
    }
  }

  setTogglesValues(indicator) {
    this.indicatorToggle = indicator;
    this.subIndicatorToggle = 'deviations';
    localStorage.setItem('indicator', indicator);
  }

  async downloadRecord() {
    if (this.validateDates(this.startDate, this.endDate) != false) {
      let startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
      let endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

      if (localStorage.getItem('indicator') == 'availability') {
        this.tabLoading2 = true;
        
        const csv = await this.reportsService.getCSV(
          this.systemData.id,
          startDate,
          endDate
        );

        Utils.downloadFile(csv, 'text/csv', 'csv', `${this.systemData.attributes.name} - Datos Mensuales `, `(${startDate}-${endDate})`);
        this.tabLoading2 = false;
        this.startDate = '';
        this.endDate = '';
      }
    }
  }

}
