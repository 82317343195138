<div class="page-content" *ngIf="!loading; else matSpinner">
  <!-- map section -->
  <mat-card class="map-card">
    <div class="map">
      <agm-map [latitude]="mapLat" [longitude]="mapLng">
        <div *ngFor="let system of notificationList10">
          <agm-marker
            [latitude]="system.sys.latitude"
            [longitude]="system.sys.longitude"
            [iconUrl]="
              './assets/marks/mark-' +
              systemsService.getSystemStatusColor(
                system.flagType == 1
                  ? '11000'
                  : system.flagType == 2
                  ? '10100'
                  : system.flagType == 3
                  ? '10001'
                  : '00000'
              ) +
              '.png'
            "
          >
            <agm-info-window>
              <button
                [routerLink]="
                  systemsService.getSystemRoute(
                    system.sys.equipmentId,
                    system.sys.id,
                    summaryPage
                  )
                "
              >
                <ion-img
                  *ngIf="system.sys.imgs != null; else krillThumbnail"
                  [src]="systemsService.getSystemFirstImg(system.sys)"
                  class="thumbnail"
                ></ion-img>
                <ng-template #krillThumbnail>
                  <ion-img
                    src="../../../../assets/logos/favicon.png"
                    class="thumbnail krill-thumbnail"
                  >
                  </ion-img>
                </ng-template>
                <strong style="color: black">{{ system.sys.name }}</strong>
              </button>
            </agm-info-window>
          </agm-marker>
        </div>
      </agm-map>
    </div>
  </mat-card>
  <div class="filters-and-buttons">
    <mat-card class="filter-card">
      <mat-card-title>{{ 'HOME.filters.title' | translate }}</mat-card-title>
      <mat-accordion multi>
        <!-- By Criticality -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'HOME.filters.criticality' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list
            #type
            [(ngModel)]="selectedTypes"
            (ngModelChange)="onNgModelChange($event, 'type')"
          >
            <mat-list-option
              *ngFor="let type of filterTypes"
              color="primary"
              [value]="type.id"
            >
              {{ 'HOME.filters.' + type.name | translate }}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
        <!-- By System -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'HOME.filters.system.title' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-selection-list
            #system
            [(ngModel)]="selectedSystems"
            (ngModelChange)="onNgModelChange($event, 'system')"
          >
            <mat-list-option
              *ngFor="let system of systems"
              color="primary"
              [value]="system.id"
            >
              {{ system.attributes.name }}
            </mat-list-option>
          </mat-selection-list>
        </mat-expansion-panel>
        <!-- By Date -->
        <!-- <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ 'HOME.filters.date.title' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="notification-system-date">
            <mat-form-field style="padding-bottom: 5px" appearance="fill">
              <mat-label>{{
                'HOME.filters.date.startDate' | translate
              }}</mat-label>
              <input
                matInput
                [matDatepicker]="dp2"
                [(ngModel)]="startDate"
                (dateInput)="addEvent('start', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp2"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp2 disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="padding-bottom: 5px" appearance="fill">
              <mat-label>{{
                'HOME.filters.date.endDate' | translate
              }}</mat-label>
              <input
                matInput
                [matDatepicker]="dp3"
                [(ngModel)]="endDate"
                (dateInput)="addEvent('end', $event)"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dp3"
              ></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-error
              *ngIf="errorDate"
              style="font-size: 12px; margin-top: -13px; margin-bottom: 10px"
              >{{ error }}</mat-error
            >
          </div>
        </mat-expansion-panel> -->
      </mat-accordion>
      <br />
      <div style="text-align: center">
        <ion-button (click)="filter()">
          {{ 'NOTIFICATION.buttons.filter' | translate }}
        </ion-button>
        <ion-button (click)="clean()">
          {{ 'NOTIFICATION.buttons.clean' | translate }}
        </ion-button>
      </div>
    </mat-card>
  </div>
  <div class="main-container">
    <div class="totals-container">
      <mat-card class="totals-card total-card-1">
        <div class="total-info">
          <h3>{{ systemNotifications }}</h3>
          <p>{{ 'HOME.map.notifications' | translate }}</p>
        </div>
        <ion-icon class="blue-icon" name="notifications"></ion-icon>
      </mat-card>
      <mat-card class="totals-card total-card-2">
        <div class="total-info">
          <h3>{{ systemAlerts }}</h3>
          <p>{{ 'HOME.map.alerts' | translate }}</p>
        </div>
        <ion-icon class="yellow-icon" name="notifications"></ion-icon>
      </mat-card>
      <mat-card class="totals-card total-card-3">
        <div class="total-info">
          <h3>{{ systemAlarms }}</h3>
          <p>{{ 'HOME.map.alarms' | translate }}</p>
        </div>
        <ion-icon class="red-icon" name="notifications"></ion-icon>
      </mat-card>
    </div>

    <!-- notifications list -->
    <mat-card class="list-card">
      <mat-accordion>
        <mat-expansion-panel
          [disabled]="clickPanel"
          (click)="onClickPanel(notification)"
          class="matExpansionPanelMobile"
          *ngFor="let notification of notificationList10"
          hideToggle
        >
          <mat-expansion-panel-header
            [ngClass]="
              String(notification.name.es) > 50
                ? 'mat-expansion-panel-header height50'
                : String(notification.name.es) > 25 &&
                  String(notification.name.es) < 50
                ? 'mat-expansion-panel-header height25'
                : 'mat-expansion-panel-header height15'
            "
          >
            <mat-panel-title class="notification-title">
              <div style="display: flex">
                <ion-icon
                  class="zoom-icon"
                  name="ellipse"
                  [ngClass]="
                    notification.flagType == 1
                      ? 'status-icon blue-icon'
                      : notification.flagType == 2
                      ? 'status-icon yellow-icon'
                      : notification.flagType == 3
                      ? 'status-icon red-icon'
                      : 'status-icon black-icon'
                  "
                >
                </ion-icon>
              </div>
              <div class="notification notification-name mobile">
                <!-- notification name mobile-->
                <div class="notification-name">
                  <span
                    >{{ 'NOTIFICATION.name.name' | translate }}:
                    {{ notification.title }}
                  </span>
                </div>
              </div>
              <div class="notification notification-name web">
                <!-- notification name web-->
                <div class="notification-name">
                  <span
                    >{{ 'NOTIFICATION.name.name' | translate }}:
                    {{ notification.title }}
                  </span>
                </div>
                <!-- notification type -->
                <div class="notification notification-type">
                  <span *ngIf="notification.flagType == 1">
                    {{ 'NOTIFICATION.type.name' | translate }}:
                    {{ 'NOTIFICATION.type.notification' | translate }}
                  </span>
                  <span *ngIf="notification.flagType == 2">
                    {{ 'NOTIFICATION.type.name' | translate }}:
                    {{ 'NOTIFICATION.type.alert' | translate }}
                  </span>
                  <span *ngIf="notification.flagType == 3">
                    {{ 'NOTIFICATION.type.name' | translate }}:
                    {{ 'NOTIFICATION.type.alarm' | translate }}
                  </span>
                </div>
              </div>
            </mat-panel-title>

            <mat-panel-description class="notification web">
              <div class="notification-system">
                {{ 'NOTIFICATION.system.name' | translate }}:
                {{ notification.sys.name }}
              </div>
              <div class="notification-date">
                {{ 'NOTIFICATION.date' | translate }}:
                {{
                  notification.time
                    | date : 'yyyy-MM-dd, h:mm a' : notification.sys.timeZone
                }}
              </div>
            </mat-panel-description>

            <mat-panel-description class="mat-panel-desc">
              <button mat-button (click)="GoSummaryPage(notification)">
                <ion-icon class="zoom-icon" name="desktop"></ion-icon>
              </button>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div
            class="notification notification-type notification-description mobile"
          >
            <span> {{ 'NOTIFICATION.type.name' | translate }}: &nbsp; </span>
            <span *ngIf="notification.flagType == 1">
              {{ 'NOTIFICATION.type.notification' | translate }}
            </span>
            <span *ngIf="notification.flagType == 2">
              {{ 'NOTIFICATION.type.alert' | translate }}
            </span>
            <span *ngIf="notification.flagType == 3">
              {{ 'NOTIFICATION.type.alarm' | translate }}
            </span>
          </div>
          <div class="notification-system notification-description mobile">
            <span
              >{{ 'NOTIFICATION.system.name' | translate }}:
              {{ notification.systemsId }}</span
            >
          </div>
          <div class="notification-date notification-description mobile">
            <span
              >{{ 'NOTIFICATION.date' | translate }}:
              {{ notification.updatedAt | date : 'yyyy-MM-dd' }}</span
            >
          </div>

          <div class="notification notification-description">
            <span>
              {{ 'NOTIFICATION.description.name' | translate }}:
              <span *ngIf="languague == 'ES'"
                >{{ notification.description }}
              </span>
              <span *ngIf="languague == 'ENG'"
                >{{ notification.description }}
              </span>
            </span>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-paginator
        #paginator
        [length]="notificationListLength"
        [pageSize]="pageSize"
        (page)="pageEvent = getNotifications($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </mat-card>
  </div>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>
