import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateConfigService } from './services/translate-config/translate-config.service';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MatBottomSheetModule,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AgmCoreModule } from '@agm/core';
import { AvatarModule } from 'ngx-avatar';

import { LoginComponent } from './pages/login/login.component';

import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
import { KrillSpinnerComponent } from './components/krill-spinner/krill-spinner.component';
import { KrillSpinnerRemasterizedComponent } from './components/krill-spinner-remasterized/krill-spinner-remasterized.component';
import { KrillSpinnerV2Component } from './components/krill-spinner-v2/krill-spinner-v2.component';

import { ChartsModule } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LoadingComponent } from './pages/loading/loading.component';

import {
  SystemImagesComponent,
  SystemImagesDialogComponent
} from './components/system-images/system-images.component';
import { AddEditDialogComponent } from './components/add-edit-dialog/add-edit-dialog.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { ImageGridComponent } from './components/image-grid/image-grid.component';
import { MapModalComponent } from './components/map-modal/map-modal.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

//============ C O M P O N E N T S ============
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/dashboard/home/home.component';
import { NotificationsComponent } from './pages/dashboard/notifications/notifications.component';
import { ReportsComponent } from './pages/dashboard/reports/reports.component';
import { ContactComponent } from './pages/dashboard/contact/contact.component';
import { AdmPanelComponent } from './pages/dashboard/adm-panel/adm-panel.component';
import { ConfigsComponent } from './pages/dashboard/configs/configs.component';
import { SystemTabsComponent } from './components/system-tabs/system-tabs.component';
import { IndicatorsTabsComponent } from './components/indicators-tabs/indicators-tabs.component';
import { ListModalComponent } from './components/list-modal/list-modal.component';

//============ A D M I N  P A N E L  C O M P O N E N T S ============
import { CompaniesComponent } from './pages/dashboard/companies/companies.component';
import { UsersComponent } from './pages/dashboard/users/users.component';
import { ProjectsComponent } from './pages/dashboard/projects/projects.component';
import { ModalProjectsComponent } from './pages/dashboard/projects/modal-projects/modal-projects.component';

//============ S U M M A R Y ============
import { SummaryComponent } from './pages/dashboard/summaryPage/t1/summary.component';

//============ S Y S T E M ============
import { SystemComponent } from './pages/dashboard/systemPage/t1/system.component';

//============ I N D I C A T O R ============
import { IndicatorComponent } from './pages/dashboard/indicatorsPage/t1/indicator.component';

export function LanguageLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ConfigsComponent,
        SummaryComponent,
        SystemComponent,
        IndicatorComponent,
        NotificationsComponent,
        LoginComponent,
        UsersComponent,
        ProjectsComponent,
        AdmPanelComponent,
        CompaniesComponent,
        AddEditDialogComponent,
        ModalProjectsComponent,
        DeleteDialogComponent,
        LoadingComponent,
        DashboardComponent,
        FilterModalComponent,
        KrillSpinnerComponent,
        KrillSpinnerRemasterizedComponent,
        KrillSpinnerV2Component,
        SystemTabsComponent,
        IndicatorsTabsComponent,
        MapModalComponent,
        ReportsComponent,
        ContactComponent,
        SystemImagesComponent,
        SystemImagesDialogComponent,
        ImageGridComponent,
        ListModalComponent
    ],
    imports: [
        BrowserModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA4eU42fSSkLqc1VSqgRsMs_3mSGztFGlY'
        }),
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: LanguageLoader,
                deps: [HttpClient]
            }
        }),
        AvatarModule,
        MatSidenavModule,
        MatTableModule,
        MatButtonModule,
        MatDividerModule,
        MatListModule,
        MatMenuModule,
        MatCardModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatGridListModule,
        MatIconModule,
        MatExpansionModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatTabsModule,
        MatDialogModule,
        ChartsModule,
        MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        NgbModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        MatDatepickerModule,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
        { provide: MatBottomSheetRef, useValue: {} },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
