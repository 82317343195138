import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor() {}

  /**
   *
   * @param companyId
   */
  public async getReportPDF(systemId: string, date: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'records/' + systemId + '/report/' + date,
        {
          headers: {}
        }
      );

      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getConsolidatedReportCheck(projectId: string, date: string) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'records/project/' +
          projectId +
          '/report/' +
          date +
          '/check',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getIndividualReportCheck(systemId: string, date: string) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'records/' +
          systemId +
          '/report/' +
          date +
          '/check',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  public async getCSV(systemsId: string, startDate: string, endDate: string): Promise<string> {
    try {
      let response = await axios.post(
        environment.apiUrl +
          'records/' +
          systemsId +
          '/csv/download',
        {
          startDate: startDate,
          endDate: endDate
        },
        {
          headers: {}
        }
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
}
