import {
  Component,
  HostListener,
  OnInit,
} from '@angular/core';

import jwt_decode from 'jwt-decode';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from '../../services/translate-config/translate-config.service';
import {
  ActivatedRoute,
  Router,
  Event,
  NavigationEnd,
} from '@angular/router';
import { SystemsService } from '../../services/systems/systems.service';
import { SidenavService } from '../../services/sidenav/sidenav.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  sidenavMode: string = 'side';
  sidenavOpened: boolean = true;
  selectedLanguage: string;
  systemName: string;
  systemId: string;
  systemType: string;
  equipmentId: string;
  loadingRoutes: boolean = true;
  userProfile: any;
  summaryPage: boolean = true;
  totalAlertsAlarms: number = 0;

  systemList: any[] = [];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private translate: TranslateConfigService,
    private activatedRoute: ActivatedRoute,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    public sidenavService: SidenavService,
    public notificationsService: NotificationsService
  ) {
    this.initializeApp();
    this.selectedLanguage = this.translate.getDefaultLanguage();
  }

  async ngOnInit() {
    try {
      this.userProfile = jwt_decode(localStorage.getItem('TOKEN'));
    } catch (error) {
      localStorage.clear();
      window.location.href = environment.loginUrl;
    }

    //Get systems by user
    let companyId = localStorage.getItem('COMPANY_ID');
    let data = await this.systemsService.getSystemList(companyId);
    this.systemList = data.systems;

    if (window.innerWidth < 1000) {
      this.sidenavMode = 'over';
      this.sidenavOpened = false;
    } else {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.getSystemName();
      }
    });

    this.sidenavService.changeSidenav(this.sidenavOpened);
    this.getTotalAlarmsAlerts();
  }

  /**
   * Gets system name depending on the page
   *
   */
  async getSystemName() {
    if (
      this.router.url.substring(0, 19) == '/dashboard/summary/' ||
      this.router.url.substring(0, 18) == '/dashboard/system/' ||
      this.router.url.substring(0, 22) == '/dashboard/indicators/'
    ) {
      let routerItems = this.router.url.split('/');
      this.systemId = routerItems.pop();
      this.systemType = routerItems.pop();
      this.systemName = await this.systemsService.getSystemNameById(
        this.systemId // This is the system id
      );

      this.equipmentId = localStorage.getItem('eq');
      this.loadingRoutes = false;
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  languageChanged(selectedLanguage: string) {
    this.translate.setLanguage(selectedLanguage);
  }

  async logOut() {
    localStorage.clear();
    window.location.href = environment.loginUrl;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    if (window.innerWidth < 1000) {
      this.sidenavMode = 'over';
      this.sidenavOpened = false;
    } else {
      this.sidenavMode = 'side';
      this.sidenavOpened = true;
    }
  }

  public sidenavChanged(opened) {
    this.sidenavService.changeSidenav(opened);
  }

  public async getTotalAlarmsAlerts() {
    //Define an array
    let systemListByUser = [];
    //get all systems id by user
    this.systemList.forEach((element) => {
      systemListByUser.push(element.id);
    });
    let type: any = [1, 2, 3];
    // let res = await this.notificationsService.getNotifications(
    //   1,
    //   this.userProfile.userId,
    //   type,
    //   systemListByUser,
    //   'true',
    //   undefined,
    //   undefined,
    //   'false'
    // );
    
    this.totalAlertsAlarms =0;
  }
}
