<mat-card class="container">
  <mat-card class="card"
    routerLink="/dashboard/companies"
  >
    <ion-icon name="business"></ion-icon>
    <mat-card-content>
      <mat-card-title>Compañias</mat-card-title>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>

  <mat-card class="card"
    routerLink="/dashboard/projects"
  >
    <ion-icon name="briefcase-outline"></ion-icon>
    <mat-card-content>
      <mat-card-title>Proyecto</mat-card-title>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>

  <mat-card class="card">
    <ion-icon name="folder-open-outline"></ion-icon>
    <mat-card-content>
      <mat-card-title>Sistema</mat-card-title>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>

  <mat-card class="card"
    routerLink="/dashboard/users"
  >
    <ion-icon name="people-outline"></ion-icon>
    <mat-card-content>
      <mat-card-title>Usuario</mat-card-title>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>

</mat-card>
