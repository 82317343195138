<mat-card>
  <div class="title-container">
    <h2>Reporte de operación</h2>
    <p>
      Para realizar la descarga de un reporte, se debe seleccionar el sistema y
      mes del reporte requerido. La información reflejada en el documento
      representa los valores acumulados o promedios del mes solicitado y los
      valores acumulados o promedio desde el momento de la puesta en servicio de
      la solución.
    </p>
  </div>
  <mat-tab-group mat-align-tabs="center" selectedIndex="0" #tabgroup>
    <!-- consolidated report -->
    <mat-tab label="Consolidado" selected>
      <div class="picker-container">
        <div class="percent30">
          <ion-badge color="primary" style="width: 100%">
            <h1>{{ 'SYSTEM.project' | translate }}:</h1>
          </ion-badge>
        </div>
        <div class="percent69_two">
          <mat-form-field appearance="none" class="percent69_two-form">
            <mat-label>{{ 'DIALOG.select' | translate }}</mat-label>
            <mat-select
              [(value)]="projectSelected"
              (click)="openSystemList('cReport')"
              *ngIf="!projectSelected"
            >
            </mat-select>

            <input
              matInput
              type="text"
              [value]="projectSelected?.name"
              disabled
            />
            <button
              *ngIf="projectSelected"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="projectSelected = undefined"
            >
              <ion-icon name="close-outline"></ion-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <div class="dates-container-charts">
        <div class="percent30">
          <ion-badge color="primary" style="width: 100%">
            <h1>Periodo:</h1>
          </ion-badge>
        </div>

        <div class="percent69">
          <mat-form-field appearance="none" class="percent69_two-form">
            <mat-label>{{ 'TIME.month' | translate }}</mat-label>
            <mat-select [(value)]="monthSelected">
              <mat-option *ngFor="let month of monthsValid" [value]="month.id">
                {{ month.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button
            (click)="openConsolidatedReport()"
            class="date-button"
            mat-raised-button
            color="primary"
            [disabled]="monthSelected && projectSelected ? false : true"
          >
            Buscar
          </button>
        </div>
      </div>
    </mat-tab>
    <!-- individual report -->
    <mat-tab label="Individual">
      <div class="picker-container">
        <div class="percent30">
          <ion-badge color="primary" style="width: 100%">
            <h1>{{ 'SYSTEM.system' | translate }}:</h1>
          </ion-badge>
        </div>
        <div class="percent69_two">
          <mat-form-field appearance="none" class="percent69_two-form">
            <mat-label>{{ 'DIALOG.select' | translate }}</mat-label>
            <mat-select
              [(value)]="systemSelected"
              (click)="openSystemList('iReport')"
              *ngIf="!systemSelected"
            >
            </mat-select>

            <input
              matInput
              type="text"
              [value]="systemSelected?.name"
              disabled
            />
            <button
              *ngIf="systemSelected"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="systemSelected = undefined"
            >
              <ion-icon name="close-outline"></ion-icon>
            </button>
          </mat-form-field>
        </div>
      </div>

      <div class="dates-container-charts">
        <div class="percent30">
          <ion-badge color="primary" style="width: 100%">
            <h1>Periodo:</h1>
          </ion-badge>
        </div>

        <div class="percent69">
          <mat-form-field appearance="none" class="percent69_two-form">
            <mat-label>{{ 'TIME.month' | translate }}</mat-label>
            <mat-select [(value)]="monthSelected">
              <mat-option *ngFor="let month of monthsValid" [value]="month.id">
                {{ month.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button
            (click)="openIndividualReport()"
            class="date-button"
            mat-raised-button
            color="primary"
            [disabled]="monthSelected && systemSelected ? false : true"
          >
            Buscar
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>

<mat-card style="margin-top: 40px">
  <!-- MONTHLY REPORT -->
  <div class="title-container">
    <h2>Datos descargables</h2>
    <p>
      Puede descarcar la data de un sistema para una fecha especifica. Para
      ello, seleccione el sistema y el rango de fechas que desea descargar. El
      formato del archivo es ".csv".
    </p>
  </div>

  <div class="picker-container">
    <div class="percent30">
      <ion-badge color="primary" style="width: 100%">
        <h1>{{ 'SYSTEM.system' | translate }}:</h1>
      </ion-badge>
    </div>
    <div class="percent69_two">
      <mat-form-field appearance="none" class="percent69_two-form">
        <mat-label>{{ 'DIALOG.select' | translate }}</mat-label>
        <mat-select
          [(value)]="systemSelectedMonthData"
          (click)="openSystemList('mData')"
          *ngIf="!systemSelectedMonthData"
        >
        </mat-select>

        <input
          matInput
          type="text"
          [value]="systemSelectedMonthData?.name"
          disabled
        />
        <button
          *ngIf="systemSelectedMonthData"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="systemSelectedMonthData = undefined"
        >
          <ion-icon name="close-outline"></ion-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <div class="dates-container-charts">
    <div class="percent100">
      <div id="monthlyDataForm" [formGroup]="monthlyDataForm">
        <mat-form-field id="dataStartDate" appearance="fill" style="width: 50%">
          <mat-label>{{ 'TIME.from' | translate }}</mat-label>

          <input
            matInput
            [matDatepicker]="from"
            formControlName="dataStartDate"
            name="dataStartDate"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
          <mat-datepicker #from disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field id="dataEndDate" appearance="fill" style="width: 50%">
          <mat-label>{{ 'TIME.to' | translate }}</mat-label>
          <input
            matInput
            [matDatepicker]="to"
            formControlName="dataEndDate"
            name="dataEndDate"
            [min]="minDate"
            [max]="maxDate"
          />
          <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
          <mat-datepicker #to disabled="false"></mat-datepicker>
        </mat-form-field>

        <button
          class="date-button"
          (click)="downloadMonthlyData()"
          type="submit"
          mat-raised-button
          [disabled]="!formActive && !systemSelectedMonthData"
          color="primary"
        >
          Descargar
        </button>
      </div>
    </div>
  </div>
</mat-card>
