import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateConfigService } from '../../../services/translate-config/translate-config.service';
import {
  MatBottomSheet,
  MatBottomSheetConfig
} from '@angular/material/bottom-sheet';
import { FilterModalComponent } from '../../../components/filter-modal/filter-modal.component';
import { SystemsService } from '../../../services/systems/systems.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { Router } from '@angular/router';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Utils } from 'src/app/utils/utils';
import { interval } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  // localStorage
  companyId: string;
  projectId: string;
  type: string;
  regionId: string;
  status = '';

  loading: boolean = true;
  loadingData = true;
  summaryPage: boolean = true;

  // map
  mapLat: number;
  mapLng: number;
  mapZoom: number;
  mapSystems: [] = [];

  systemList: any[] = [];
  sitesValues: any;
  systemListBackup: any[] = [];

  itemSubsection: string = 'subsection-no-filter';
  itemFilters: string = 'item-filter-hidden';

  // Paginator
  systemListPaginatorBackup: any[] = [];
  systemListLength: number;
  pageSize = 50;
  pageIndex = 1;

  // Watch systems: list | mosaic
  watch: string = 'mosaic';

  interval: any;
  isRefresh: string;

  displayedColumns: string[] = [
    'id',
    'name',
    'location',
    'system',
    'connected',
    'lastConnection',
    'notifications',
    'load',
    'krill'
  ];

  @ViewChild('paginator') paginator: MatPaginator;

  // filter
  filterData: any[] = [];

  // search term
  searchTerm: string = '';

  constructor(
    private translate: TranslateConfigService,
    private _filterSheet: MatBottomSheet,
    private router: Router,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    public configsService: ConfigsService
  ) {}

  async ngOnInit() {
    this.companyId = localStorage.getItem('COMPANY_ID');
    this.projectId = localStorage.getItem('PROJECT_ID');
    this.type = localStorage.getItem('TYPE');
    this.regionId = localStorage.getItem('REGION_ID');

    // get map & sites data
    await this.getMapSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.status
    );

    // get systems data
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status
    );

    // filter data
    this.filterData = await this.systemsService.getFilterData(
      this.companyId,
      this.projectId
    );

    // Agregando el tipo de sistema que sera
    this.systemListBackup.forEach((system) => (system.type = 'eq12kb'));

    if (this.systemList[0]) {
      this.mapLat = this.systemList[0].latitude
        ? this.systemList[0].latitude
        : 0;
      this.mapLng = this.systemList[0].longitude
        ? this.systemList[0].longitude
        : 0;
      this.mapZoom = 5;
    }
    this.loading = false;

     // Inicia la actualización cada 10 minutos (600,000 milisegundos)
     this.isRefresh = localStorage.getItem('refresh');
     if(this.isRefresh == "yes"){
       this.interval = setInterval(() => {
         this.refresh();
         console.log('refresh');
       },240000);
     }
   }
  
   // Detiene la actualización al salir del componente
   ngOnDestroy() {
     if(this.isRefresh == "yes"){
       clearInterval(this.interval);
     }
   }

  openFilterSheet(): void {
    // return;

    const config = new MatBottomSheetConfig();
    config.data = {
      filterList: this.filterData
    };

    const bottomSheet = this._filterSheet.open(FilterModalComponent, config);

    bottomSheet.afterDismissed().subscribe(async (data) => {
      if (data == undefined) return;

      // map sites
      this.mapSystems = data.mapSites.systems;
      this.sitesValues = data.mapSites.sites;

      // system list
      this.systemList = data.systems.data;
      console.log(this.systemList);

      // total paginator items
      this.systemListLength = this.sitesValues.total;
      this.pageIndex = data.currentPage;

      // company
      this.companyId = data.company;
      // project
      this.projectId = data.projectId;
      // type
      this.type = data.type;
      // region
      this.regionId = data.regionId;
    });
  }

  async searchSystemSites() {
    await this.getMapSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.status,
      this.searchTerm
    );
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status,
      this.searchTerm
    );
  }

  receiveSystemList($event) {
    this.systemList = $event;
  }

  formatDate(date, timeZone) {
    return moment(date).utcOffset(timeZone);
  }

  async filterSites(status: string = '') {
    this.status = status;
    if (status == '') {
      // set map markers
      await this.getMapSites(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.status,
        this.searchTerm
      );
      await this.getSystems(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.pageIndex.toString(),
        this.status,
        this.searchTerm
      );
    } else {
      /* 
        11000 notification 6
        10100 alert 30
        10010 alarm 18
        00000 disconnect 182
        10000 connect 469
      */
      await this.getMapSites(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.status,
        this.searchTerm
      );
      await this.getSystems(
        this.companyId,
        this.projectId,
        this.type,
        this.regionId,
        this.pageIndex.toString(),
        this.status,
        this.searchTerm
      );
    }
  }

  async getMapSites(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    status = '',
    name = ''
  ) {
    const dataMapSites = await this.systemsService.getMapSites(
      companyId,
      projectId,
      type,
      regionId,
      status,
      name
    );
    this.mapSystems = dataMapSites.systems;
    this.sitesValues = dataMapSites.sites;
  }

  async getSystems(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    page: string,
    status = '',
    name = ''
  ) {
    const dataSystem = await this.systemsService.getSystemListPaginated(
      companyId,
      projectId,
      type,
      regionId,
      page,
      status,
      name
    );

    // system list
    this.systemList = dataSystem.data;

    // total paginator items
    this.systemListLength =
      status === ''
        ? this.sitesValues.total
        : status === 'connected'
        ? this.sitesValues.connected
        : status === 'disconnected'
        ? this.sitesValues.disconnected
        : status === 'notification'
        ? this.sitesValues.notification
        : 0;
    this.loadingData = false;
  }

  async getSystemsByPaginator(event: PageEvent) {
    this.pageIndex = event.pageIndex + 1;
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status
    );
  }

  async watchList() {
    if (this.watch === 'list') {
      this.watch = 'mosaic';
    } else if (this.watch === 'mosaic') {
      this.watch = 'list';
    }
  }

  async downloadCSVSites() {
    const csv = await this.systemsService.getCSVSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId
    );

    Utils.downloadFile(csv, 'text/csv', 'csv', `All sites - Harvey Energy`);
  }

  async refresh(){
    
    // get map & sites data
    await this.getMapSites(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.status
    );

    // get systems data
    await this.getSystems(
      this.companyId,
      this.projectId,
      this.type,
      this.regionId,
      this.pageIndex.toString(),
      this.status
    );
    
    // filter data
    this.filterData = await this.systemsService.getFilterData(
      this.companyId,
      this.projectId
    );

    if (this.systemList[0]) {
      this.mapLat = this.systemList[0].latitude
        ? this.systemList[0].latitude
        : 0;
      this.mapLng = this.systemList[0].longitude
        ? this.systemList[0].longitude
        : 0;
      this.mapZoom = 5;
    }
    this.loading = false;

  }
}
