<div class="page">
  <mat-card>
    <mat-card-title>Formulario de contacto</mat-card-title>
    <mat-card-content>
      <ion-input
        label="Outline input"
        label-placement="floating"
        fill="outline"
        placeholder="Nombre"
        [(ngModel)]="name"
      ></ion-input>
      <ion-input
        label="Outline input"
        label-placement="floating"
        fill="outline"
        placeholder="Correo"
        [(ngModel)]="email"
      ></ion-input>
      <ion-input
        label="Outline input"
        label-placement="floating"
        fill="outline"
        placeholder="Localidad"
        [(ngModel)]="localidad"
      ></ion-input>
      <ion-input
        label="Outline input"
        label-placement="floating"
        fill="outline"
        placeholder="Asunto"
        [(ngModel)]="subject"
      ></ion-input>
      <ion-textarea
        label="Outline input"
        label-placement="floating"
        fill="outline"
        placeholder="Descripción"
        [(ngModel)]="description"
      ></ion-textarea>

      <button
        type="submit"
        mat-raised-button
        color="primary"
        (click)="onSubmit()"
      >
        Enviar
      </button>
    </mat-card-content>
  </mat-card>
</div>
