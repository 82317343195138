<mat-toolbar style="background-color: transparent">
  <span> Configuraciones</span>
</mat-toolbar>
<mat-list>
  <mat-list-item>
    <mat-slide-toggle
      (change)="changeSummaryPage()"
      [checked]="summaryPage"
      color="accent"
    >
      Versión Lite</mat-slide-toggle
    >
  </mat-list-item>
</mat-list>
