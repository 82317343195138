import { Component, Inject, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent implements OnInit {

  //map
  zoom: number = 8;
  mapLat;
  mapLng;

  //marker map
  markerLat;
  markerLng;
  markerDrag = true;
  markerLabel = "Posición Seleccionada";
  constructor(
    private dialogRef: MatDialogRef<MapModalComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.defineMap(data);
  }

  ngOnInit() {
    // this.mapLat = 8.34938;
    // this.mapLng = -80.59;
    // console.log(this.markerLat, this.markerLng)
  }

  defineMap(data:any){
    if(data.title === 'user' && data.action === 'add'){
      //get latitude and longitude from user login
      this.mapLat = parseFloat(localStorage.getItem('LATITUDE'));
      this.mapLng = parseFloat(localStorage.getItem('LONGITUDE'));
    }else if(data.title === 'user' && data.action === 'edit'){
      if(data.longitude && data.latitude){
        this.mapLat = data.latitude;
        this.mapLng = data.longitude;
        this.markerLat = data.latitude;
        this.markerLng = data.longitude;
      }else{
        this.mapLat = parseFloat(localStorage.getItem('LATITUDE'));
        this.mapLng = parseFloat(localStorage.getItem('LONGITUDE'));
      }
    }
  }

  addMarker($event: MouseEvent) {
    // console.log("event: ", $event);
    this.markerLat = $event.coords.lat;
    this.markerLng = $event.coords.lng;
  }

  markerDragEnd($event: MouseEvent) {
    // console.log('dragEnd', $event);
    this.markerLat = $event.coords.lat;
    this.markerLng = $event.coords.lng;
  }

  close() {
    this.dialogRef.close();
  }

  send() {
    this.dialogRef.close({
      markerLat: this.markerLat, 
      markerLng: this.markerLng
    });
  }

}
