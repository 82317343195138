import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { UsersService } from 'src/app/services/users/users.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { MapModalComponent } from '../map-modal/map-modal.component';


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-add-edit-dialog',
  templateUrl: './add-edit-dialog.component.html',
  styleUrls: ['./add-edit-dialog.component.scss'],
})
export class AddEditDialogComponent implements OnInit {
  matcher = new MyErrorStateMatcher();

  //define form to use: user, company.
  useForm: string;

  //users
  form: FormGroup;
  username: string;
  password: string;
  companyId: number;
  projectId: number = 1;
  email: string;
  role: string;
  longitude;
  latitude;
  id: number;

  //company
  companyForm: FormGroup;
  name:string;

  //project
  projectForm: FormGroup;

  okName: string;
  action: string;
  title: string;

  companiesList: any;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateConfigService,
    private dialogRef: MatDialogRef<AddEditDialogComponent>,
    private usersService: UsersService,
    private companiesService: CompaniesService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    //console.log("data: ", data);
    this.defineData(data);
  }

  async ngOnInit() {
    this.setForm();
    this.companiesList = await this.companiesService.getCompaniesList();
  }

  defineData(data:any){
    // console.log(data)
    if(data.title === 'user'){
      this.username = data.username;
      this.companyId = data.companyId;
      this.email = data.email;
      this.role = data.role;
    }else if(data.title === 'company'){
      this.name = data.name;
    }else if(data.title === 'project'){
      this.companyId = data.companyId;
    }
    this.id = data.id;
    this.title = data.title;
    this.action = data.action;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
  }

  setForm() {
    this.okName = this.translate.getTranslation('USER.user');

    if(this.title === 'user'){
      switch (this.action) {
        case 'add':
          this.form = this.fb.group(
            {
              username: [this.username, [Validators.required]],
              email: [this.email, [Validators.required]],
              companyId: [this.companyId, [Validators.required]],
              projectId: [this.projectId, [Validators.required]],
              confirmEmail: [, [Validators.required]],
              password: [
                this.password,
                [Validators.required, Validators.minLength(8)],
              ],
              confirmPassword: [, [Validators.required, Validators.minLength(8)]],
              role: [this.role, [Validators.required]],
              longitude: [this.longitude, [Validators.required]],
              latitude: [this.latitude, [Validators.required]]
            },
            {
              validators: [this.passwordConfirmation, this.emailConfirmation],
            }
          );
          return;

        case 'edit':
          this.form = this.fb.group(
            {
              username: [this.username, [Validators.required]],
              email: [this.email, [Validators.required]],
              companyId: [this.companyId, [Validators.required]],
              projectId: [this.projectId, [Validators.required]],
              confirmEmail: [, [Validators.required]],
              password: [
                this.password,
                [Validators.required, Validators.minLength(8)],
              ],
              confirmPassword: [, [Validators.required, Validators.minLength(8)]],
              role: [this.role, [Validators.required]],
              longitude: [this.longitude, [Validators.required]],
              latitude: [this.latitude, [Validators.required]]
            },
            {
              validators: [this.passwordConfirmation, this.emailConfirmation],
            }
          );
          return;
      }
    }else if(this.title === 'company'){
      switch (this.action) {
        case 'add':
          this.companyForm = this.fb.group(
            {
              name: [this.username, [Validators.required]],
            }
          );
          return;

        case 'edit':
          this.companyForm = this.fb.group(
            {
              name: [this.username, [Validators.required]],
            },
          );
          return;
      }
    }else if(this.title === 'project'){
      switch (this.action) {
        case 'add':
          this.projectForm = this.fb.group(
            {
              name: [this.username, [Validators.required]],
            }
          );
          return;

        case 'edit':
          this.projectForm = this.fb.group(
            {
              name: [this.username, [Validators.required]],
            },
          );
          return;
      }
    }
  }

  async save(action: string) {
    if (this.action === 'add') {
      if(this.title === 'user') this.dialogRef.close({ action, ...this.form.value });
      if(this.title === 'company') this.dialogRef.close({ action, ...this.companyForm.value});
      if(this.title === 'project') this.dialogRef.close({ action, ...this.projectForm.value});
    }
    if (this.action === 'edit') {
      if(this.title === 'user') this.dialogRef.close({ id: this.id, action, ...this.form.value });
      if(this.title === 'company') this.dialogRef.close({ id: this.id, action, ...this.companyForm.value });
      if(this.title === 'project') this.dialogRef.close({ id: this.id, action, ...this.projectForm.value });
    }
  }

  close() {
    this.dialogRef.close();
  }

  passwordConfirmation(group: FormGroup): { [s: string]: boolean } {
    if (group) {
      if (
        group.controls.password.value == group.controls.confirmPassword.value
      ) {
        return null;
      }
    }
    return { errorPasswords: true };
  }

  emailConfirmation(group: FormGroup): { [s: string]: boolean } {
    if (group) {
      if (group.controls.email.value == group.controls.confirmEmail.value) {
        return null;
      }
    }
    return { errorEmails: true };
  }

  openMap() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      latitude: this.latitude,
      longitude: this.longitude,
      action: this.action,
      title: this.title
    };

    const dialogRef = this.dialog.open(MapModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      // console.log(data);
      if (data) {
        this.form.controls['latitude'].setValue(data.markerLat);
        this.form.controls['longitude'].setValue(data.markerLng);
        this.latitude = data.markerLat;
        this.longitude = data.markerLng;
      }
    });
  }
}
