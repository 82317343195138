import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adm-panel',
  templateUrl: './adm-panel.component.html',
  styleUrls: ['./adm-panel.component.scss'],
})
export class AdmPanelComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
