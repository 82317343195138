<div class="page-content" *ngIf="!loading; else matSpinner">
  <mat-card [ngClass]="sidenavOpened ? 'fixed' : 'fixed-full-width'">
    <!-- indicator section -->
    <mat-card class="indicators-section">
      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('availability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[0].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/disponibilidad2.svg"
          ></ion-icon>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.common.ha1.label }}
          </mat-card-subtitle>
          <mat-card-title class="connected-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha1
              ) | number : '1.0-2'
            }}

            {{ systemData.attributes.structure.common.ha1.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('profitability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[1].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/rentabilidad2.svg"
          ></ion-icon>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.common.ha2.label }}
          </mat-card-subtitle>
          <mat-card-title class="alarm-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha2
              ) | number : '1.0-2'
            }}

            {{ systemData.attributes.structure.common.ha2.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('sustainability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[2].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/sustentabilidad2.svg"
          ></ion-icon>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.common.ha3.label }}
          </mat-card-subtitle>
          <mat-card-title class="alert-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha3
              ) /1000 | number : '1.0-2'
            }}

            {{ systemData.attributes.structure.common.ha3.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </mat-card>
    <!-- data section -->
    <mat-card class="data-section">
      <mat-card-header class="bg-gradient-blue">
        <mat-card-subtitle class="system-name">
          {{ systemData.attributes.name }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="system-description-container">
        <div class="system-description-weather">
          <p>
            {{ utils.getWeather(systemData.records[0].icon) }}
          </p>
          <p>
            {{
              systemData.records[0][
                systemData.attributes.structure.common.hb2.var
              ] | number
            }}
            {{ systemData.attributes.structure.common.hb2.unit }}
          </p>
        </div>
        <div class="system-description-dates">
          <p>{{ systemCurrentDate }}</p>
          <p>{{ systemCurrentYear }}</p>
          <p>{{ systemCurrentHour }}</p>
        </div>
        <div class="system-description-logo">
          <!-- <ion-icon
            class="weather-icon"
            [src]="imageIndicator"
          ></ion-icon> -->
          <img class="weather-icon" [src]="imageIndicator" alt="" />
        </div>
        <div class="system-description-icon">
          <p style="font-size:smaller;">
            <ion-icon
              class="icon"
              name="notifications"
              [ngClass]="
                systemData.records[0].status == null
                  ? 'icon green-icon'
                  : systemData.records[0].status.sNotifications == 'alarm'
                  ? 'icon red-icon'
                  : systemData.records[0].status.sNotifications == 'warning'
                  ? 'icon yellow-icon'
                  : systemData.records[0].status.sNotifications == 'info'
                  ? 'icon blue-icon'
                  : 'icon green-icon'
              "
            >
            </ion-icon>
            {{
              systemsService.getSystemNotification(
                systemData.records,
                lastNotification.notification.title
              )
            }}
          </p>
        </div>
        <div
          [ngClass]="
            systemData.attributes.status != 00000
              ? 'system-description-status bg-green'
              : 'system-description-status bg-black'
          "
        >
          <p>
            {{
              systemData.attributes.status != 00000
                ? 'Conectado'
                : 'Desconectado'
            }}
          </p>
          <p>{{ lastConnection }}</p>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- spinner section -->
    <mat-card class="spinner-section">
      <mat-card-header>
        <mat-card-subtitle> Autonomía </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="spinner-v2">
        <krill-spinner-v2
          format="vertical"
          [magnitude]="systemData.attributes.structure.common.hc1.unit"
          [maxSpinnerValue]="
            systemData.attributes.plantDetails.system.FrontLimits[
              systemData.attributes.structure.common.hc1.limits.varMax
            ]
          "
          [textValue]="
            systemData.records[0][
              systemData.attributes.structure.common.hc1.var
            ] | number : '1.0-2'
          "
          [imgValue]=""
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle=""
          tooltipClass="top"
          placement="top"
          [spinnerFull]="true"
        >
        </krill-spinner-v2>
      </mat-card-content>
    </mat-card>
    <!-- system indicator section -->
    <mat-card class="system-indicator-section">
      <mat-card-header>
        <mat-card-subtitle> Consumo | Suministro </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- BATTERY -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent13"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg1.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] < 0 &&
              systemData.attributes.structure.common.systemTabs.batteries
            "
          ></ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) | number : '1.0-2'
            }}
            {{ systemData.attributes.structure.common.lg1.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="batt"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) | number : '1.0-2'
            }}
            {{ systemData.attributes.structure.common.lg1.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg1.limits.varMax
              ]
            "
            *ngIf="systemData.records[0].pBat >= 0"
          ></ion-progress-bar>
        </div>
        <!-- LOAD -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent14"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg2.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] >= 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] >= 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg2.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] < 0
            "
          ></span>
          <ion-icon class="icon-load" name="newbulb"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] >= 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] < 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg2.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg2.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] < 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
        </div>
        <!--ELECTRICAL NET-->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent11"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg3.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] < 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg3.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="electrical-tower-gray"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg3.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg3.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] >= 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
        </div>
        <!-- SOLAR -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent12"
          tooltipClass="top"
          placement="top"
          animation="true"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg4.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] < 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg4.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="solar"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg4.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg4.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
        </div>
        <!-- MOTOR -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent12"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg5.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] < 0 &&
              systemData.attributes.structure.common.systemTabs.generatorSetTab
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg5.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="motor"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              ) | number : '1.0-2'
            }}
            {{ systemData.attributes.structure.common.lg5.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right blue-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg5.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] >= 0 &&
              systemData.attributes.structure.common.systemTabs.generatorSet
            "
          ></ion-progress-bar>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- images section -->
    <mat-card class="images-section">
      <mat-card-header>
       
      </mat-card-header>
      <mat-card-content>
        <system-images [systemImgs]="systemImgs"></system-images>
      </mat-card-content>
    </mat-card>
  </mat-card>

  <section class="second-section">
    <!-- premises -->
    <mat-card class="premises-section">
      <mat-card class="premises">
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/summary/speedometer.svg"
            style="margin-right: 10px"
          ></ion-icon>
          <mat-card-title>
            {{ systemData.attributes.structure.summary.promises.t1.label }}
            <br />
            {{ systemData.attributes.structure.summary.promises.t2.label }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-blue'
            : 'premises bg-gradient-blue p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pa.label }}
          </mat-card-subtitle>
          <mat-card-title
            *ngIf="
              systemData.attributes.structure.summary.promises.pa.unit != 'icon'
            "
          >
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pa
              )
            }}
            {{ systemData.attributes.structure.summary.promises.pa.unit }}
          </mat-card-title>
          <mat-card-title
            *ngIf="
              systemData.attributes.structure.summary.promises.pa.unit == 'icon'
            "
          >
            {{
              systemData.records[0][
                systemData.attributes.structure.summary.cards.fr5.var
              ] | date : 'dd-LL hh:mm a' : systemData.attributes.timeZone
            }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-blue'
            : 'premises bg-gradient-blue p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pb.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pb
              ) | number : '1.0-0'
            }}
            
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-blue'
            : 'premises bg-gradient-blue p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pc.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pc
              )
            }}
            {{ systemData.attributes.structure.summary.promises.pc.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-green'
            : 'premises bg-gradient-green p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pd.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pd
              )
            }}
            {{ systemData.attributes.structure.summary.promises.pd.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'premises bg-gradient-green'
            : 'premises bg-gradient-green p-events'
        "
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
      >
        <mat-card-header>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.summary.promises.pe.label }}
          </mat-card-subtitle>
          <mat-card-title>
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.summary.promises.pe
              )
            }}
            {{ systemData.attributes.structure.summary.promises.pe.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </mat-card>

    <!-- load card -->
    <mat-card
      [ngClass]="loadTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        loadTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : systemsService.getSystemRoute(0, systemData.id)
      "
      (click)="saveTab('load')"
    >
      <!-- icon card -->
      <div class="icon-card-container">
        <img *ngIf="loadTab" src="./assets/icon/newbulb.svg" alt="" />
      </div>

      <div *ngIf="!loadTab" class="icon-card-off-container">
        <img src="./assets/icon/newbulb.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fl1.label }}
          Carga
        </mat-card-title>
      </div>

      <!-- title card -->
      <mat-card-title *ngIf="loadTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fl1.label }}
        Carga
      </mat-card-title>

      <!-- box 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="loadTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fl4.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fl4.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fl4.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- box 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="loadTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fl5.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemsService.getSystemSummaryData(
              systemData,
              systemData.attributes.structure.summary.cards.fl5
            ) | number : '1.0-2'
          }}
          <!-- {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fl5.var
            ] | number : '1.0-2'
          }} -->
          {{ systemData.attributes.structure.summary.cards.fl5.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- electrical card -->
    <mat-card
      [ngClass]="electricalNetTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        electricalNetTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : systemsService.getSystemRoute(0, systemData.id)
      "
      (click)="saveTab('electricNet')"
    >
      <!-- icon card -->
      <div class="icon-card-container">
        <img
          *ngIf="electricalNetTab"
          src="./assets/icon/electrical-tower-gray.svg"
          alt=""
        />
      </div>
      <div *ngIf="!electricalNetTab" class="icon-card-off-container">
        <img src="./assets/icon/electrical-tower-gray.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fr1.label }}
          Red
        </mat-card-title>
      </div>

      <!-- title card -->
      <mat-card-title *ngIf="electricalNetTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fr1.label }}
        Red Eléctrica
      </mat-card-title>

      <!-- box 1 -->
      <mat-card-header
        tooltipClass="top"
        placement="top"
        *ngIf="electricalNetTab"
      >
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fr4.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fr4.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fr4.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- box 2 -->
      <mat-card-header
        tooltipClass="top"
        placement="top"
        *ngIf="electricalNetTab"
      >
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fr5.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fr5.var
            ] | date : 'dd-LL hh:mm a' : '-0800'
          }}
          {{ systemData.attributes.structure.summary.cards.fr5.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- battery card -->
    <mat-card
      [ngClass]="batteriesTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        batteriesTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : systemsService.getSystemRoute(0, systemData.id)
      "
      (click)="saveTab('batteries')"
    >
      <!-- icon card -->
      <div class="icon-card-container">
        <img *ngIf="batteriesTab" src="./assets/icon/batt.svg" alt="" />
      </div>

      <div *ngIf="!batteriesTab" class="icon-card-off-container">
        <img src="./assets/icon/batt.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fb1.label }}
          Batería
        </mat-card-title>
      </div>

      <!-- title card -->
      <mat-card-title *ngIf="batteriesTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fb1.label }}
        Batería
      </mat-card-title>

      <!-- box 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="batteriesTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fb4.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fb4.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fb4.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- box 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="batteriesTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fb5.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fb5.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fb5.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- solar card -->
    <mat-card
      [ngClass]="solarTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        solarTab
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : systemsService.getSystemRoute(0, systemData.id)
      "
      (click)="saveTab('solar')"
    >
      <!-- icon card -->
      <div class="icon-card-container">
        <img *ngIf="solarTab" src="./assets/icon/solar.svg" alt="" />
      </div>

      <div *ngIf="!solarTab" class="icon-card-off-container">
        <img src="./assets/icon/solar.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fs1.label }}
          Solar
        </mat-card-title>
      </div>

      <!-- title card -->
      <mat-card-title *ngIf="solarTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fs1.label }}
        Solar
      </mat-card-title>

      <!-- box 1 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="solarTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fs4.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fs4.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fs4.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- box 2 -->
      <mat-card-header tooltipClass="top" placement="top" *ngIf="solarTab">
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fs5.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fs5.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fs5.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>

    <!-- motor card -->
    <mat-card
      [ngClass]="generatorSetTab ? 'column-card' : 'column-card-off'"
      [routerLink]="
        generatorSetTab 
          ? systemsService.getSystemRoute(
              systemData.attributes.equipmentId,
              systemData.id,
              false
            )
          : systemsService.getSystemRoute(0, systemData.id)
      "
      (click)="saveTab('generatorSet')"
    >
      <!-- icon card -->
      <div class="icon-card-container">
        <img *ngIf="generatorSetTab" src="./assets/icon/motor.svg" alt="" />
      </div>

      <div *ngIf="!generatorSetTab" class="icon-card-off-container">
        <img src="./assets/icon/motor.svg" alt="" />
        <mat-card-title class="title">
          {{ systemData.attributes.structure.summary.cards.fg1.label }}
          Electrógeno
        </mat-card-title>
      </div>

      <!-- title card -->
      <mat-card-title *ngIf="generatorSetTab" class="title">
        {{ systemData.attributes.structure.summary.cards.fg1.label }}
        Electrógeno
      </mat-card-title>

      <!-- box 1 -->
      <mat-card-header
        tooltipClass="top"
        placement="top"
        *ngIf="generatorSetTab"
      >
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fg4.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fg4.var
            ] | number : '1.0-2'
          }}
          {{ systemData.attributes.structure.summary.cards.fg4.unit }}
        </mat-card-title>
      </mat-card-header>

      <!-- box 2 -->
      <mat-card-header
        tooltipClass="top"
        placement="top"
        *ngIf="generatorSetTab"
      >
        <mat-card-subtitle>
          {{ systemData.attributes.structure.summary.cards.fg5.label }}
        </mat-card-subtitle>
        <mat-card-title>
          {{
            systemData.records[0][
              systemData.attributes.structure.summary.cards.fg5.var
            ] | date : 'dd-LL hh:mm a' : '-0800'
          }}
          {{ systemData.attributes.structure.summary.cards.fg5.unit }}
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </section>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-template #tipContentDiagram>
  {{ 'SYSTEM.tooltips.15.1' | translate }}
  {{ 'SYSTEM.tooltips.15.2' | translate }}
  {{ 'SYSTEM.tooltips.15.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.16.1' | translate }}
  {{ 'SYSTEM.tooltips.16.2' | translate }}
  {{ 'SYSTEM.tooltips.16.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.17.1' | translate }}
  {{ 'SYSTEM.tooltips.17.2' | translate }}
  {{ 'SYSTEM.tooltips.17.3' | translate }}
  <br />
  <br />
  {{ 'SYSTEM.tooltips.18.1' | translate }}
  {{ 'SYSTEM.tooltips.18.2' | translate }}
  {{ 'SYSTEM.tooltips.18.3' | translate }}
</ng-template>

<ng-template #tipContent10>
  {{ 'SYSTEM.tooltips.10' | translate }}
</ng-template>
<ng-template #tipContent11>
  {{ 'SYSTEM.tooltips.11' | translate }}
</ng-template>
<ng-template #tipContent12>
  {{ 'SYSTEM.tooltips.12' | translate }}
</ng-template>
<ng-template #tipContent13>
  {{ 'SYSTEM.tooltips.13' | translate }}
</ng-template>
<ng-template #tipContent14>
  {{ 'SYSTEM.tooltips.14' | translate }}
</ng-template>
<ng-template #tipContent23>
  {{ 'SYSTEM.tooltips.23' | translate }}
</ng-template>
<ng-template #tipContent57>
  {{ 'SYSTEM.tooltips.57' | translate }}
</ng-template>
<ng-template #tipContent34>
  {{ 'SYSTEM.tooltips.34' | translate }}
</ng-template>
<ng-template #tipContent59>
  {{ 'SYSTEM.tooltips.59' | translate }}
</ng-template>
<ng-template #tipContent89>
  {{ 'SYSTEM.tooltips.89' | translate }}
</ng-template>
<ng-template #tipContent92>
  {{ 'SYSTEM.tooltips.92' | translate }}
</ng-template>
<ng-template #tipContent68>
  {{ 'SYSTEM.tooltips.68' | translate }}
</ng-template>
<ng-template #tipContent69>
  {{ 'SYSTEM.tooltips.69' | translate }}
</ng-template>
<ng-template #tipContent67>
  {{ 'SYSTEM.tooltips.67' | translate }}
</ng-template>
<ng-template #connected>
  {{ 'SYSTEM.status.1' | translate }}
</ng-template>
<ng-template #disconnected>
  {{ 'SYSTEM.status.0' | translate }}
</ng-template>

<ng-template #charging>
  {{ 'SYSTEM.tooltips.113' | translate }}
</ng-template>
<ng-template #discharging>
  {{ 'SYSTEM.tooltips.114' | translate }}
</ng-template>
