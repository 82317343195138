import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment-timezone';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { SystemsService } from 'src/app/services/systems/systems.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { MathToolsService } from 'src/app/services/mathTools/math-tools.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';

@Component({
  selector: 'app-system-t1',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {
  //Image to krill spinner from indicator value
  indicatorImageValue = 1;

  imageIndicator: string;
  pageLoading: boolean = true;
  tabLoading: boolean = false;
  batteryCharge: number = 10;

  tabs = ['load', 'electricNet', 'batteries', 'solar', 'generatorSet'];
  // tabs = ['load', 'electricNet', 'solar', 'generatorSet'];

  systemId: string;
  systemData: any;
  systemCreated: string;
  systemCurrentDate: string;
  lastNotification: any;
  systemCurrentYear: string;
  systemCurrentHour: string;
  lastConnection: string;
  status: boolean = false;

  spinnerRemasterizedDiameter: number = 135;

  systemIndicatorsData: any;
  systemImgs: [];

  sidenavOpened: boolean;

  indicators: any[] = [
    {
      name: 'availability',
      off: 0
    },
    {
      name: 'profitability',
      off: 0
    },
    {
      name: 'sustainability',
      off: 0
    }
  ];

  //tabs
  loadTab: any;
  electricalNetTab: any;
  batteriesTab: any;
  solarTab: any;
  generatorSetTab: any;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public systemsService: SystemsService,
    public indicatorsService: IndicatorsService,
    public sidenavService: SidenavService,
    public notificationsService: NotificationsService,
    private config: NgbTooltipConfig,
    public mathTools: MathToolsService,
    public utils: UtilsService
  ) {
    //deshabilitar tooltip
    config.disableTooltip = true;
    config.container = 'body';
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    await this.systemDataSetup();

    // Set spinner diameter
    this.spinnerResizes();

    if (this.systemData == null) {
      this.router.navigate(['/dashboard/home']);
      return;
    }

    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemData.id
    );
    this.pageLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
  }

  async systemDataSetup() {
    this.systemId = this.activatedRoute.snapshot.paramMap.get('id');

    this.systemData = await this.systemsService.getSystemById(this.systemId);
    this.systemData = this.systemData.system;

    //monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);
    this.systemData.monthly = monthlyData;

    //daily data
    let dailyData = await this.systemsService.getSystemDailyData(this.systemId);
    this.systemData.daily = dailyData;
    //weekly data
    let weeklyData = await this.systemsService.getSystemWeekly(this.systemId);
    this.systemData.weekly = weeklyData;

    let tabsLength = this.tabs.length;

    //last notification
    this.lastNotification =
      await this.notificationsService.getLastNotificationBySystem(
        Number(this.systemId)
      );

    if (!this.lastNotification.notification) {
      this.lastNotification.notification = {
        lastType: 'N/A',
        lastDate: 'N/A',
        name: 'N/A'
      };
    } else {
      this.lastNotification.notification.lastDate = moment(
        this.lastNotification.notification.time
      ).format('DD/MM/YYYY hh:mm:ss a');

      this.lastNotification.notification.lastType =
        this.lastNotification.notification.title;
    }

    //tabs
    for (let i = 0; i < tabsLength; i++) {
      if (
        !this.systemData.attributes.structure.common.systemTabs[this.tabs[i]]
      ) {
        this.tabs.splice(i, 1);
        i = i - 1;
        tabsLength -= 1;
      }
    }

    this.loadTab = this.systemData.attributes.structure.common.systemTabs.load
      ? true
      : false;
    this.electricalNetTab = this.systemData.attributes.structure.common
      .systemTabs.electricNet
      ? true
      : false;
    this.batteriesTab = this.systemData.attributes.structure.common.systemTabs
      .batteries
      ? true
      : false;
    this.solarTab = this.systemData.attributes.structure.common.systemTabs.solar
      ? true
      : false;
    this.generatorSetTab = this.systemData.attributes.structure.common
      .systemTabs.generatorSet
      ? true
      : false;

    //add image
    this.imageIndicator = `assets/weather/1x/${this.systemData.records[0].icon}.png`;

    // Para deshabilitar cualquier indicador recibido del backend
    if (this.systemData.attributes.plantDetails.inactiveIndicators) {
      this.systemData.attributes.plantDetails.inactiveIndicators.forEach(
        (element: any, index: any) => {
          //Si el indicador esta deshabilitado, deshabilita el indicador en el htmls
          if (element == 1) {
            this.indicators[index].off = element;
          }
        }
      );
    }

    if (this.systemData == null) {
      return;
    }
    this.systemImgs = JSON.parse(this.systemData.attributes.imgs);

    let date = moment(this.systemData.records[0].time).utcOffset(
      this.systemData.attributes.timeZone
    );

    this.systemCurrentDate = date.format('DD/MM');
    this.systemCurrentYear = date.format('YYYY');
    this.systemCurrentHour = date.format('hh:mm a');
    this.systemCreated = moment().format('DD/MM/YYYY hh:mm:ss a');

    this.lastConnection = moment(this.systemData.records[0].time)
      .utcOffset(this.systemData.attributes.timeZone)
      .format('DD/MM/YYYY hh:mm:ss a');
    if (this.systemData.attributes.status == '00000') {
      this.status = false;
    }
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  /**
   * Return the round value of number.
   * This function is useful for the html tags
   */
  public round(number: number) {
    return Math.round(number);
  }

  /**
   *  Save name indicator in localStorage to use in indicator page
   */
  saveIndicator(indicator: string) {
    if (indicator) localStorage.setItem('indicator', indicator);
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 135;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }

  /**
   *  Go to notification page
   */
  GoNotification(system) {
    if (system.records[0].status == null) return;

    if (system.records[0].status.sNotifications == 'clear') {
      return;
    } else {
      this.router.navigateByUrl('/dashboard/notifications', { state: system });
    }
  }
}
