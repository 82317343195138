import { Component, OnInit, ViewChild } from "@angular/core";
import {
  MatSelectionList,
  MatSelectionListChange,
} from "@angular/material/list";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import * as _moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Router } from "@angular/router";

import { SweetAlertService } from "src/app/services/alert/sweet-alert.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";
import { TranslateConfigService } from "src/app/services/translate-config/translate-config.service";
import { DashboardComponent } from "../dashboard.component";
import { SystemsService } from "src/app/services/systems/systems.service";
import { ConfigsService } from "src/app/services/configs/configs.service";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  systemByRoute: any;

  loading: boolean = true;

  summaryPage: boolean = true;

  notificationList: any[] = [];
  initialPage: number = 1;

  systemNotifications: any;
  systemAlerts: any;
  systemAlarms: any;

  //Paginator
  notificationListLength: number;
  pageSize = 50;
  pageEvent: PageEvent;
  notificationList10: any;
  pageIndex: any;

  clickPanel: boolean = false;
  systems: any[] = [];
  notificationOpenId: string;

  //filters
  filterTypes = [
    {
      id: 1,
      name: "notifications",
    },
    {
      id: 2,
      name: "alerts",
    },
    {
      id: 3,
      name: "alarms",
    },
  ];

  //visualization filters
  visualizationTypes = [
    {
      value: "true",
      name: "notRead",
    },
    {
      value: "false",
      name: "read",
    },
    // {
    //   value: 'all',
    //   name: 'all'
    // }
  ];

  mapLat: number;
  mapLng: number;

  selectedVisualizations = ["true"];
  selectedTypes = [];
  selectedSystems = [];
  selectedVisualization = "true";
  selectedSystem;
  selectedType;

  lastSelect = [true];
  actualSelect;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSelectionList) visualization4: MatSelectionList;

  //system type
  startDate;
  endDate;
  today;

  //error date
  errorDate = false;
  error: string;

  //languague
  languague = "ES";

  constructor(
    public notificationsService: NotificationsService,
    public sweetAlertService: SweetAlertService,
    private translate: TranslateConfigService,
    public dashboardComponent: DashboardComponent,
    public systemsService: SystemsService,
    public configsService: ConfigsService,
    private router: Router
  ) {
    //get system by route (summary or system)
    this.systemByRoute = this.router.getCurrentNavigation()?.extras.state;

    //if system by route (summary or system) exist then put this selectedSystem = systemId
    if (this.systemByRoute?.id !== undefined) {
      this.selectedSystem = [this.systemByRoute.id];
      this.selectedSystems = [this.systemByRoute.id];
    }
  }

  async ngOnInit() {
    await this.getSystems();

    this.reloadNotification(this.initialPage);

    this.loading = false;

    let myDate = new Date();
    this.today = new Date(
      myDate.getFullYear(),
      myDate.getMonth(),
      myDate.getDate()
    );

    this.summaryPage = await this.configsService.getSummaryPage(
      localStorage.getItem("USER_ID")
    );
  }

  ngAfterViewInit() {
    // setTimeout( () => {
    //   this.visualization4.selectionChange.subscribe((select: MatSelectionListChange) => {
    //     this.visualization4.deselectAll();
    //     select.option.selected = true;
    //   });
    // }, 5000)
  }

  async reloadNotification(pageIndex: any) {
    let data = await this.notificationsService.getNotifications(
      pageIndex,
      localStorage.getItem("USER_ID"),
      this.selectedType,
      this.selectedSystem,
      this.selectedVisualization,
      this.startDate == undefined
        ? undefined
        : _moment(this.startDate).format("YYYY-MM-D"),
      this.endDate == undefined
        ? undefined
        : _moment(this.endDate).format("YYYY-MM-D")
    );

    // initial page = 1;
    this.initialPage = pageIndex;

    // data
    this.systemNotifications = 0;
    this.systemAlerts = 0;
    this.systemAlarms = 0;

    // notification list
    this.notificationList = data.notifications;

    // total notifications paginator
    this.notificationListLength = data.total;

    this.notificationList10 = data.notifications.slice(
      this.pageSize * (pageIndex - 1),
      this.pageSize * pageIndex
    );
    console.log("notificationList10: ", this.notificationList10);

    if (this.notificationList10[0].sys != undefined) {
      this.mapLat = this.notificationList10[0].sys.latitude;
      this.mapLng = this.notificationList10[0].sys.longitude;
    } else {
      this.mapLat = 0;
      this.mapLng = 0;
    }

    // contar totales
    data.notifications.forEach((element) => {
      if (element.flagType == 1) {
        this.systemNotifications++;
      }
      if (element.flagType == 2) {
        this.systemAlerts++;
      }
      if (element.flagType == 3) {
        this.systemAlarms++;
      }
    });
  }

  public getNotifications(event: PageEvent): PageEvent {
    this.reloadNotification(event.pageIndex + 1);
    return event;
  }

  public async eyesAlert(notification: any) {
    this.clickPanel = true;
    // let res = await this.sweetAlertService.warning(
    //   '',
    //   `${this.translate.getTranslation('NOTIFICATION.alertMessage')}`,
    //   'question',
    //   `${this.translate.getTranslation('NOTIFICATION.accept')}`,
    //   `${this.translate.getTranslation('NOTIFICATION.cancel')}`
    // );
    if (true) {
      let response = await this.notificationsService.readNotification(
        notification.userNotifications.id
      );

      if (response.result === true) {
        this.reloadNotification(this.initialPage);

        //reload badge in sidebar
        let type: any = [1, 2, 3];
        let ress = await this.notificationsService.getNotifications(
          1,
          localStorage.getItem("USER_ID"),
          type,
          [],
          "true",
          undefined,
          undefined
        );
        this.dashboardComponent.totalAlertsAlarms =
          ress.numberAlarm + ress.numberAlert + ress.numberNotification;
      }
      // } else if (res.isDismissed) {
      //   return;
    }
  }

  async GoSummaryPage(notification) {
    this.clickPanel = true;

    this.router.navigate([`/dashboard/summary/t1/${notification.systemsId}`]);
  }

  public async getSystems() {
    let systems = await this.notificationsService.getSystems(
      localStorage.getItem("COMPANY_ID"),
      localStorage.getItem("PROJECT_ID"),
      localStorage.getItem("TYPE"),
      localStorage.getItem("REGION_ID")
    );

    this.systems = systems.data.systems;

    if (this.selectedSystem == undefined) {
      let s = [];
      for (let i = 0; i < this.systems.length; i++) {
        s.push(this.systems[i].id);
      }
      this.selectedSystem = s;
    }
  }

  onNgModelChange(event, value: string) {
    if (event) {
      if (value == "type") {
        this.selectedType = event;
      } else if (value == "system") {
        this.selectedSystem = event;
      } else {
        this.selectedVisualizations = [event];
        this.selectedVisualization = event;
      }
    }
  }

  async filter() {
    this.notificationOpenId = undefined;
    if (!this.errorDate) {
      this.reloadNotification(1);
      this.paginator.firstPage();
    }
  }
  async allRead() {
    let response = await this.notificationsService.readUserNotification(
      localStorage.getItem("USER_ID")
    );
    this.notificationOpenId = undefined;
    if (response.result === true) {
      this.reloadNotification(this.initialPage);

      //reload badge in sidebar
      let type: any = [1, 2, 3];
      let ress = await this.notificationsService.getNotifications(
        1,
        localStorage.getItem("USER_ID"),
        type,
        [],
        "true",
        undefined,
        undefined
      );
      this.dashboardComponent.totalAlertsAlarms =
        ress.numberAlarm + ress.numberAlert + ress.numberNotification;
    }
  }

  async onClickPanel(notification) {
    this.clickPanel = false;
    if (
      this.notificationOpenId != undefined &&
      this.notificationOpenId == notification.userNotifications.id
    ) {
      let response = await this.notificationsService.readNotification(
        this.notificationOpenId
      );
      this.notificationOpenId = undefined;
      if (response.result === true) {
        this.reloadNotification(this.initialPage);

        //reload badge in sidebar
        let type: any = [1, 2, 3];
        let ress = await this.notificationsService.getNotifications(
          1,
          localStorage.getItem("USER_ID"),
          type,
          [],
          "true",
          undefined,
          undefined
        );
        this.dashboardComponent.totalAlertsAlarms =
          ress.numberAlarm + ress.numberAlert + ress.numberNotification;
      }
    } else {
      this.notificationOpenId = notification.userNotifications.id;
    }
  }

  async clean() {
    this.selectedSystems = [];
    this.selectedSystem = "";
    this.selectedTypes = [];
    this.selectedType = "";
    this.selectedVisualizations = ["true"];
    this.selectedVisualization = "true";
  }

  addEvent(date: string, event: MatDatepickerInputEvent<Date>) {
    if (this.startDate != undefined && this.endDate != undefined) {
      if (this.startDate > this.endDate) {
        this.errorDate = true;
        this.error = `${this.translate.getTranslation(
          "HOME.filters.date.errorDate.2"
        )}`;
      } else {
        this.errorDate = false;
      }
    }

    if (date == "start") {
      if (this.startDate > this.today) {
        this.error = `${this.translate.getTranslation(
          "HOME.filters.date.errorDate.1"
        )}`;
        this.errorDate = true;
      } else {
        this.errorDate = false;
      }
    } else {
    }
  }

  public String(value) {
    if (value == undefined) return 0;
    let String = value.toString();
    return String.length;
  }
}
