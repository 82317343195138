import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ReportsService } from 'src/app/services/reports/reports.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { environment } from '../../../../environments/environment';
import { ListModalComponent } from 'src/app/components/list-modal/list-modal.component';
import { Utils } from 'src/app/utils/utils';
import { UsersService } from 'src/app/services/users/users.service';
import { MatTabGroup } from '@angular/material/tabs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  // reporte mensual de operacion

  // consolidado
  projects: any[] = [];
  projectSelected: any;

  // individual
  startDate: Date;
  startDateString: string;

  systems: any[] = [];

  systemSelected: any;
  monthSelected: any;

  allMonths: any[] = [
    {
      id: 1,
      name: 'Enero'
    },
    {
      id: 2,
      name: 'Febrero'
    },
    {
      id: 3,
      name: 'Marzo'
    },
    {
      id: 4,
      name: 'Abril'
    },
    {
      id: 5,
      name: 'Mayo'
    },
    {
      id: 6,
      name: 'Junio'
    },
    {
      id: 7,
      name: 'Julio'
    },
    {
      id: 8,
      name: 'Agosto'
    },
    {
      id: 9,
      name: 'Septiembre'
    },
    {
      id: 10,
      name: 'Octubre'
    },
    {
      id: 11,
      name: 'Noviembre'
    },
    {
      id: 12,
      name: 'Diciembre'
    }
  ];

  monthsValid = [];

  // datos mensuales
  systemSelectedMonthData;

  monthlyDataForm: FormGroup = this.formBuilder.group({
    dataStartDate: [{ value: '', disabled: true }, [Validators.required]],
    dataEndDate: [{ value: '', disabled: true }, [Validators.required]]
  });

  minDate: Date;
  maxDate: Date;

  formActive: boolean = false;

  @ViewChild('tabgroup', { static: true }) tabGroup: MatTabGroup;

  constructor(
    private translate: TranslateConfigService,
    private notificationsService: NotificationsService,
    private reportsService: ReportsService,
    private alertService: SweetAlertService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private sweetAlertService: SweetAlertService,
    private usersService: UsersService
  ) {}

  async ngOnInit() {
    await this.getSystems();
    await this.getProjects();

    this.minMaxDates();
    this.sortMonths();
    this.monthsValid.shift();
  }

  ngAfterViewChecked(): void {
    // reload ink bar group tab
    this.tabGroup.realignInkBar();
  }

  sortMonths() {
    this.monthsValid.sort((a, b) => {
      const yearA = a.year;
      let idA = a.id;
      const yearB = b.year;
      let idB = b.id;
      if (yearA < yearB) {
        idB += 12;
      }
      if (idA < idB) {
        return 1;
      }
      if (idA > idB) {
        return -1;
      }

      // names must be equal
      return 0;
    });
  }

  sortSystems() {
    this.systems.sort((a, b) => {
      let idA = a.id;
      let idB = b.id;
      if (idA < idB) {
        return -1;
      }
      if (idA > idB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  /*
   * Open individual report
   */
  async openIndividualReport() {
    let dateSelect = moment(
      `${this.monthSelected}/01/${this.allMonths[this.monthSelected - 1].year}`
    ).format('yyyy-MM-DD');

    let check = await this.reportsService.getIndividualReportCheck(
      this.systemSelected.id,
      dateSelect
    );

    if (check.result) {
      let url =
        environment.apiUrl +
        'records/' +
        this.systemSelected.id +
        '/report/' +
        dateSelect;
      window.open(url, '_blank');
    } else {
      this.alertService.message(
        'Sin datos',
        `El sistema ${this.systemSelected.name} no tiene datos para el mes seleccionado`,
        'info',
        'OK'
      );
      this.systemSelected = undefined;
      this.monthSelected = undefined;
    }
  }

  /*
   * Open consolidated report
   */
  async openConsolidatedReport() {
    let dateSelect = moment(
      `${this.monthSelected}/01/${this.allMonths[this.monthSelected - 1].year}`
    ).format('yyyy-MM-DD');

    let check = await this.reportsService.getConsolidatedReportCheck(
      this.projectSelected.id,
      dateSelect
    );

    if (check.result) {
      let url =
        environment.apiUrl +
        'records/project/' +
        this.projectSelected.id +
        '/report/' +
        dateSelect;
      window.open(url, '_blank');
    } else {
      this.alertService.message(
        'Sin datos',
        `El proyecto ${this.projectSelected.name} no tiene datos para el mes seleccionado`,
        'info',
        'OK'
      );
      this.projectSelected = undefined;
      this.monthSelected = undefined;
    }
  }

  public async getSystems() {
    let systems = await this.notificationsService.getSystemsForReport(
      localStorage.getItem('COMPANY_ID'),
      localStorage.getItem('PROJECT_ID')
    );
    this.systems = systems.systems;
  }

  public async getProjects() {
    let projects = await this.usersService.getProjectsForReport(
      localStorage.getItem('COMPANY_ID')
    );
    let userProjects = localStorage.getItem('PROJECT_ID');
    userProjects = JSON.parse(userProjects);
    if (userProjects.length > 0) {
      projects.projects = projects.projects.filter((project) => {
        return userProjects.includes(project.id);
      });
    }
    this.projects = projects.projects;
    return;
  }

  async minMaxDates() {
    this.minDate = moment().subtract(7, 'months').toDate();
    this.maxDate = moment().toDate();

    let minDateMonths = moment().subtract(7, 'months').month() + 1;
    let minDateYear = moment().subtract(7, 'months').year();

    let maxDateMonths = moment().month() + 1;
    let maxDateYear = moment().year();

    if (minDateMonths < maxDateMonths) {
      for (let i = minDateMonths; i < maxDateMonths; i++) {
        this.allMonths[i].name = `${this.allMonths[i].name} - ${minDateYear}`;
        this.allMonths[i].year = minDateYear;
        this.monthsValid.push(this.allMonths[i]);
      }
    }

    if (minDateMonths > maxDateMonths) {
      this.allMonths.forEach((month, index) => {
        if (month.id > minDateMonths) {
          this.allMonths[
            index
          ].name = `${this.allMonths[index].name} - ${minDateYear}`;
          this.allMonths[index].year = minDateYear;
          this.monthsValid.push(this.allMonths[index]);
        }

        if (month.id <= maxDateMonths) {
          this.allMonths[
            index
          ].name = `${this.allMonths[index].name} - ${maxDateYear}`;
          this.allMonths[index].year = maxDateYear;
          this.monthsValid.push(this.allMonths[index]);
        }
      });
    }
  }

  setMonthAndYear(
    normalizedMonthAndYear: moment.Moment,
    datepicker: MatDatepicker<moment.Moment>
  ) {
    // console.log(
    //   'normalizedMonthAndYear: ',
    //   normalizedMonthAndYear,
    //   'datepicker: ',
    //   datepicker
    // );
    if (datepicker.id === 'mat-datepicker-0') {
      this.startDateString = moment(normalizedMonthAndYear).format('YYYY/MM');
      datepicker.close();
    }
  }

  /*
   * cReport = Consolidated report
   * iReport = Individual report
   * mData   = Monthly data
   */
  openSystemList(type: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '75%';
    dialogConfig.height = '70vh';

    if (type === 'cReport') dialogConfig.data = { list: this.projects };
    if (type === 'iReport' || type === 'mData')
      dialogConfig.data = { list: this.systems };

    const dialogRef = this.dialog.open(ListModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (type === 'iReport') this.systemSelected = data;
      if (type === 'mData') this.systemSelectedMonthData = data;
      if (type === 'cReport') this.projectSelected = data;
    });
  }

  async downloadMonthlyData() {
    if (
      !Utils.isStartDateValid(
        this.monthlyDataForm.controls['dataStartDate'].value
      )
    ) {
      this.sweetAlertService.message(
        'Fecha inválida',
        'La fecha inicial no puede ser mayor que la fecha actual.',
        'warning',
        'OK'
      );
    } else if (
      !Utils.isEndDateValid(
        this.monthlyDataForm.controls['dataEndDate'].value,
        this.monthlyDataForm.controls['dataStartDate'].value
      )
    ) {
      this.sweetAlertService.message(
        'Fecha inválida',
        'La fecha final debe ser mayor o igual a la fecha inicial.',
        'warning',
        'OK'
      );
    } else {
      const startDate = moment(
        this.monthlyDataForm.controls['dataStartDate'].value
      ).format('yyyy-MM-DD');
      const endDate = moment(
        this.monthlyDataForm.controls['dataEndDate'].value
      ).format('yyyy-MM-DD');

      const csv = await this.reportsService.getCSV(
        this.systemSelectedMonthData.id,
        startDate,
        endDate
      );

      Utils.downloadFile(
        csv,
        'text/csv',
        'csv',
        `${this.systemSelectedMonthData.name} - Datos Mensuales `,
        `(${startDate}-${endDate})`
      );
    }
  }
}
