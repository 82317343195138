import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  constructor() {}

  /**
   *  Get the companies list
   */
  public async getCompaniesList() {
    try {
      let response = await axios.get(environment.apiUrl + 'companies', {
        headers: {}
      });

      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Add a company
   */
  public async addCompany(name: string) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'companies',
        {
          name
        },
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Update a company
   */
  public async updateCompany(id: any, name: string) {
    try {
      let response = await axios.put(
        environment.apiUrl + 'companies/' + id,
        {
          name
        },
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err.response.status;
    }
  }

  /**
   *  Delete a company
   */
  public async deleteCompany(id) {
    try {
      let response = await axios.delete(
        environment.apiUrl + 'companies/' + id,
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }
}
