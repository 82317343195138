<div>
  <h1 mat-dialog-title *ngIf="action == 'add'">
    {{ 'DIALOG.add' | translate }}
    <span *ngIf="title == 'user'">{{ 'DIALOG.user' | translate }}</span>
    <span *ngIf="title == 'company'">{{ 'DIALOG.company' | translate }}</span>
    <span *ngIf="title == 'project'">{{ 'DIALOG.project' | translate }}</span>
  </h1>
  <h1 mat-dialog-title *ngIf="action == 'edit'">
    {{ 'DIALOG.edit' | translate }}
    <span *ngIf="title == 'user'"
      >{{ 'DIALOG.user' | translate }}: {{ username }}</span
    >
    <span *ngIf="title == 'company'"
      >{{ 'DIALOG.company' | translate }}: {{ name }}</span
    >
    <span *ngIf="title == 'project'"
      >{{ 'DIALOG.project' | translate }}: {{ name }}</span
    >
  </h1>
</div>
<!-- USER FORM -->
<mat-dialog-content [formGroup]="form" *ngIf="title == 'user'">
  <mat-form-field>
    <input
      matInput
      type="text"
      formControlName="username"
      placeholder="{{ 'DIALOG.USER.username' | translate }}"
      required
      name="username"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="form.controls.username.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.USER.username' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      type="email"
      formControlName="email"
      placeholder="{{ 'DIALOG.USER.email' | translate }}"
      name="email"
      required
      [errorStateMatcher]="matcher"
      email
    />
    <mat-error *ngIf="form.controls.email.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.USER.email' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      type="email"
      formControlName="confirmEmail"
      placeholder="{{ 'DIALOG.USER.confirmEmail' | translate }}"
      name="confirmEmail"
      required
      [errorStateMatcher]="matcher"
      email
    />
    <mat-error *ngIf="form.controls.confirmEmail.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.USER.emailConf' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-error
    *ngIf="
      !form.controls.confirmEmail.hasError('required') &&
      form.hasError('errorEmails')
    "
    style="margin-top: -10px; font-size: 12px"
  >
    {{ 'DIALOG.USER.emailsDoNotMatch' | translate }}
  </mat-error>
  <mat-form-field>
    <mat-label>{{ 'DIALOG.USER.role' | translate }}</mat-label>
    <mat-select formControlName="role">
      <mat-option value="admin"> admin </mat-option>
      <mat-option value="client"> client </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls.role.hasError('required')"
      >{{ 'DIALOG.select' | translate }}
      {{ 'DIALOG.USER.role' | translate }}.</mat-error
    >
  </mat-form-field>
  <mat-form-field style="width: 38%; margin: 0 2% 0 0">
    <input
      matInput
      type="text"
      formControlName="longitude"
      placeholder="{{ 'DIALOG.USER.longitude' | translate }}"
      name="longitude"
    />
  </mat-form-field>
  <mat-form-field style="width: 38%; margin: 0 2% 0 0">
    <input
      matInput
      type="text"
      formControlName="latitude"
      placeholder="{{ 'DIALOG.USER.latitude' | translate }}"
      name="latitude"
    />
  </mat-form-field>
  <div
    style="
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <button mat-mini-fab color="primary" (click)="openMap()">
      <mat-icon>gps_fixed</mat-icon>
    </button>
  </div>
  <mat-form-field>
    <mat-label>{{ 'DIALOG.USER.company' | translate }}</mat-label>
    <mat-select formControlName="companyId">
      <mat-option *ngFor="let company of companiesList" [value]="company.id">
        {{ company.attributes.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls.role.hasError('required')"
      >{{ 'DIALOG.select' | translate }}
      {{ 'DIALOG.USER.company' | translate }}.</mat-error
    >
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      type="password"
      formControlName="password"
      placeholder="{{ 'DIALOG.USER.password' | translate }}"
      required
      name="password"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="form.controls.password.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.USER.password' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.password.hasError('minlength')">
      {{ 'DIALOG.USER.passwordMin' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      type="password"
      formControlName="confirmPassword"
      placeholder="{{ 'DIALOG.USER.confirmPassword' | translate }}"
      required
      name="confirmPassword"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="form.controls.confirmPassword.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.USER.passwordConf' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.confirmPassword.hasError('minlength')">
      {{ 'DIALOG.USER.passwordMin' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-error
    *ngIf="
      !form.controls.confirmPassword.hasError('required') &&
      form.hasError('errorPasswords')
    "
    style="margin-top: -4px; font-size: 12px"
  >
    {{ 'DIALOG.USER.passwordsDoNotMatch' | translate }}
  </mat-error>
</mat-dialog-content>

<!-- COMPANY FORM -->
<mat-dialog-content [formGroup]="companyForm" *ngIf="title == 'company'">
  <mat-form-field>
    <input
      matInput
      type="text"
      formControlName="name"
      placeholder="{{ 'DIALOG.COMPANY.name' | translate }}"
      required
      name="name"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="companyForm.controls.name.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.COMPANY.name' | translate }}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<!-- PROJECT FORM -->
<mat-dialog-content [formGroup]="projectForm" *ngIf="title == 'project'">
  <mat-form-field>
    <input
      matInput
      type="text"
      formControlName="name"
      placeholder="{{ 'DIALOG.PROJECT.name' | translate }}"
      required
      name="name"
      [errorStateMatcher]="matcher"
    />
    <mat-error *ngIf="projectForm.controls.name.hasError('required')">
      {{ 'DIALOG.introduce' | translate }}
      {{ 'DIALOG.PROJECT.name' | translate }}
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<!-- ACTIONS -->
<mat-dialog-actions>
  <button class="mat-raised-button" (click)="close()">
    {{ 'DIALOG.close' | translate }}
  </button>
  <button
    class="mat-raised-button mat-primary"
    *ngIf="action == 'add'"
    [disabled]="
      title === 'user'
        ? !form.valid
          ? true
          : false
        : title === 'company'
        ? !companyForm.valid
          ? true
          : false
        : title === 'project'
        ? !projectForm.valid
          ? true
          : false
        : true
    "
    (click)="save('add')"
  >
    {{ 'DIALOG.addOk' | translate }}
    <span *ngIf="title === 'user'">{{ 'DIALOG.user' | translate }}</span>
    <span *ngIf="title === 'company'">{{ 'DIALOG.company' | translate }}</span>
    <span *ngIf="title === 'project'">{{ 'DIALOG.project' | translate }}</span>
  </button>
  <button
    class="mat-raised-button mat-primary"
    *ngIf="action == 'edit'"
    [disabled]="
      title === 'user'
        ? !form.valid
          ? true
          : false
        : title === 'company'
        ? !companyForm.valid
          ? true
          : false
        : title === 'project'
        ? !projectForm.valid
          ? true
          : false
        : true
    "
    (click)="save('edit')"
  >
    {{ 'DIALOG.editOk' | translate }}
    <span *ngIf="title === 'user'">{{ 'DIALOG.user' | translate }}</span>
    <span *ngIf="title === 'company'">{{ 'DIALOG.company' | translate }}</span>
    <span *ngIf="title === 'project'">{{ 'DIALOG.project' | translate }}</span>
  </button>
</mat-dialog-actions>
