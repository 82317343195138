import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { environment } from './../../environments/environment';

axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        if(!config.url.includes('auths/login')){
            config.headers.Authorization =
            'Bearer ' + localStorage.getItem('TOKEN');
            return config;
        }else{
            return config;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        if(error.response.status === 400 && error.response.data.errors[0].title === "badJWT") {
            localStorage.clear();
            window.location.href = environment.loginUrl;
        }

        // Session expired || Token expired
        if (error.response.status == 403 || error.response.status == 401) {
            localStorage.clear();
            window.location.href = environment.loginUrl;
        }
    }
);