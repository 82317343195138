import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor() {}

  /**
   *  Get the users list
   */
  public async getUsersList() {
    try {
      let response = await axios.get(environment.apiUrl + 'users', {
        headers: {}
      });

      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  /**
   *
   */
  public async deleteUser(id) {
    try {
      let response = await axios.delete(environment.apiUrl + 'users/' + id, {
        headers: {}
      });
      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   *
   */
  public async addUser(
    username,
    password,
    companyId,
    projectId,
    email,
    role,
    latitude,
    longitude
  ) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'users',
        {
          username,
          password,
          companyId,
          projectId,
          email,
          role,
          latitude,
          longitude
        },
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   *
   */
  public async updateUser(
    id,
    username,
    password,
    companyId,
    projectId,
    email,
    role,
    latitude,
    longitude
  ) {
    try {
      let response = await axios.put(
        environment.apiUrl + 'users/' + id,
        {
          username,
          password,
          companyId,
          projectId,
          email,
          role,
          latitude,
          longitude
        },
        {
          headers: {}
        }
      );
      return response;
    } catch (err) {
      return err.response.status;
    }
  }

  /**
   * Project list by company id
   * Return project list by user company id
   * @param companyId user company id
   */
  public async getProjectsForReport(companyId: string) {
    try {
      let response = await axios.get(
        `
        ${environment.apiUrl}users/filter/company/${companyId}/projects`,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
}
