<mat-toolbar style="background-color: transparent">
  <span class="list-title">Proyectos de la compañía {{ companyName }}</span>
  <span class="spacer"></span>
  <button mat-mini-fab class="add-button" (click)="addProject()">
    <ion-icon name="add-outline" class="add-icon"></ion-icon>
  </button>
  <button mat-mini-fab class="add-button" (click)="close()">
    <ion-icon name="close-outline" class="add-icon"></ion-icon>
  </button>
</mat-toolbar>
<mat-card>
  <mat-list style="height: 78vh; overflow: auto">
    <div *ngIf="tabLoading" class="bars-spinner">
      <mat-spinner></mat-spinner>
    </div>
    <div class="no-data" *ngIf="projects.length === 0 && !tabLoading">
      <h3>No existen proyectos en esta compañía</h3>
    </div>
    <mat-list-item *ngFor="let project of projects">
      <div mat-list-icon class="avatar-container">
        <ngx-avatar
          name="{{ project.name }}"
          size="50"
          textSizeRatio="3"
        ></ngx-avatar>
      </div>
      <div class="item-content">
        <div>
          <h5 mat-line>ID {{ project.id }}</h5>
          <!-- <h5 mat-line> {{ companyName }} </h5> -->
        </div>
        <div>
          <h5> {{ project.name }} </h5>
        </div>
        <!-- <div>
          <h5> {{ company.createdAt | date: 'medium'}} </h5>
        </div> -->
      </div>
      <!-- <span class="spacer"></span> -->
      <button (click)="updateProject(project)" mat-button>
        <ion-icon name="create-outline"></ion-icon>
      </button>
      <button (click)="deleteProject(project)" mat-button>
        <ion-icon name="trash-outline"></ion-icon>
      </button>
      <mat-divider style="width: 100%"></mat-divider>
    </mat-list-item>
  </mat-list>
</mat-card>
