import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigsService {
  constructor() {}

  /**
   *  Get the summaryPage variable
   * @param userId id of the user
   */
  public async getSummaryPage(userId: string) {
    try {
      let response = await axios.get(environment.apiUrl + 'users/' + userId, {
        headers: {}
      });
      return response.data.data.attributes.summaryPage;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Update the summaryPage variable
   * @param userId id of the user
   */
  public async updateSummaryPage(userId: string) {
    try {
      let response = await axios.put(
        environment.apiUrl + 'users/' + userId + '/summaryPage',
        {
          headers: {}
        }
      );
      return response.data.summaryPage;
    } catch (err) {
      return err;
    }
  }
}
