import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-list-modal',
  templateUrl: './list-modal.component.html',
  styleUrls: ['./list-modal.component.scss']
})
export class ListModalComponent implements OnInit {
  listBackup: any[] = [];
  list: any[] = [];

  // paginator
  listPaginatorBackup: any[] = [];
  listLength: number;
  pageSize = 50;
  pageEvent: PageEvent;
  list100: any;
  list100Backup: any[] = [];
  pageIndex: any;
  initialPage: number = 1;

  // table columns
  displayedColumns: string[] = ['id', 'name', 'region'];

  constructor(
    private dialogRef: MatDialogRef<ListModalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.list = data.list;
  }

  async ngOnInit() {
    this.listBackup = this.list;
    this.listPaginatorBackup = this.list;

    //Agregando el tipo de sistema que sera
    this.listBackup.forEach((system) => (system.type = 'eq12kb'));

    this.reloadPagination(this.initialPage);
  }

  searchList(evt) {
    // console.log(this.systemListBackup.length);
    this.list = this.listBackup;
    let searchTerm = evt.srcElement.value;
    searchTerm = searchTerm.toLowerCase();

    if (!searchTerm) {
      return;
    }

    this.list = this.list.filter((system) => {
      return system.name.toLowerCase().includes(searchTerm);
    });

    this.listPaginatorBackup = this.list;
    this.reloadPagination(1);
  }

  async reloadPagination(pageIndex: any) {
    // initial page = 1;
    this.initialPage = pageIndex;

    // total notifications paginator
    this.listLength = this.listPaginatorBackup.length;

    this.list100 = this.listPaginatorBackup.slice(
      this.pageSize * (pageIndex - 1),
      this.pageSize * pageIndex
    );

    this.list100Backup = this.list100;
  }

  public getSystems(event: PageEvent): PageEvent {
    this.reloadPagination(event.pageIndex + 1);
    return event;
  }

  close() {
    this.dialogRef.close();
  }

  send(row: any) {
    this.dialogRef.close(row);
  }

  checkRegionData(element) {
    if (!element.region) return '-';
    else return element.region.name;
  }
}
