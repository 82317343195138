import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment-timezone';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { SystemsService } from 'src/app/services/systems/systems.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { MathToolsService } from 'src/app/services/mathTools/math-tools.service';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-t1-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  //Image to krill spinner from indicator value
  indicatorImageValue = 1;

  loading: boolean = true;
  summaryPage: boolean = true;

  sidenavOpened: boolean;

  systemId: string;
  systemData: any;
  lastNotification: any;
  systemCreated: string;
  systemCurrentDate: string;
  systemCurrentYear: string;
  systemCurrentHour: string;
  lastConnection: string;
  status = false;

  //indicator image
  imageIndicator: string;

  spinnerRemasterizedDiameter: number = 135;

  indicatorsDeviation: any;

  systemIndicatorsData: any;
  defaultDate: string = '';
  startDate: string = '';
  endDate: string = '';

  interval: any;
  isRefresh: string;

  systemImgs: [];

  indicators: any[] = [
    {
      name: 'autonomy',
      off: 0
    },
    {
      name: 'profitability',
      off: 0
    },
    {
      name: 'sustainability',
      off: 0
    }
  ];

  promEnergiaDiariaCarga = 0;

  message: any;

  //tabs
  loadTab: any;
  electricalNetTab: any;
  batteriesTab: any;
  solarTab: any;
  generatorSetTab: any;

  // google maps - tankLevel
  mapLat: number;
  mapLng: number;
  mapZoom: number;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateConfigService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public systemsService: SystemsService,
    public notificationsService: NotificationsService,
    public indicatorsService: IndicatorsService,
    public sidenavService: SidenavService,
    private config: NgbTooltipConfig,
    public mathTools: MathToolsService,
    public configsService: ConfigsService,
    private sweetAlert: SweetAlertService,
    public utils: UtilsService
  ) {
    config.disableTooltip = true;
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    // Set spinner Diameter
    this.spinnerResizes();

    await this.systemDataSetup();

    //If system doesnt have data, show popup
    // await this.showPopup();

    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemData.id
    );

    // this.indicatorsDeviation =
    //   await this.indicatorsService.getIndicatorsDeviation(
    //     this.systemData.attributes.id
    //   );

    this.loading = false;

    //Energia diaria de carga
    //await this.energiaDiariaCarga();

      // Inicia la actualización cada 10 minutos (600,000 milisegundos)
      this.isRefresh = localStorage.getItem('refresh');
      if(this.isRefresh == "yes"){
        this.interval = setInterval(() => {
          this.refresh();
          console.log('refresh');
        },240000);
      }
    }
   
    // Detiene la actualización al salir del componente
    ngOnDestroy() {
      if(this.isRefresh == "yes"){
        clearInterval(this.interval);
      }
    }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
  }

  async showPopup() {
    if (!this.systemData.records[0].data.pLoad) {
      this.message = (
        await this.sweetAlert.message(
          `${this.systemData.attributes.name}`,
          `Actualmente el sistema se encuentra sin datos que reportar.`,
          'info',
          'Regresar'
        )
      ).value;
      if (this.message) {
        this.router.navigate(['/dashboard/home']);
      }
    }
  }

  async energiaDiariaCarga() {
    let barsData = await this.systemsService.getRecordsBarsEnergy(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      0,
      'lastWeek'
    );
    let sumData = 0;
    for (let x in barsData.lineChartData[2].data) {
      sumData += parseInt(barsData.lineChartData[2].data[x]);
    }
    this.promEnergiaDiariaCarga =
      sumData / barsData.lineChartData[2].data.length;
  }

  /**
   *  Save name tab in localStorage to use in system page
   */
  saveTab(tab: string) {
    // console.log("saveTab, exist ? ", this.systemData.attributes.structure.common.systemTabs[tab], "tab? :", tab)
    if (this.systemData.attributes.structure.common.systemTabs[tab])
      localStorage.setItem('tab', tab);
  }

  /**
   *  Save name indicator in localStorage to use in indicator page
   */
  saveIndicator(indicator: string) {
    if (indicator) localStorage.setItem('indicator', indicator);
  }

  async systemDataSetup() {
    //get system id
    this.systemId = this.activatedRoute.snapshot.paramMap.get('id');

    this.systemData = await this.systemsService.getSystemById(this.systemId);
    this.systemData = this.systemData.system;
    console.log('sysData: ', this.systemData);

    //monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);
    this.systemData.monthly = monthlyData;

    //daily data
    let dailyData = await this.systemsService.getSystemDailyData(this.systemId);
    this.systemData.daily = dailyData;

    //weekly data
    let weeklyData = await this.systemsService.getSystemWeekly(this.systemId);
    this.systemData.weekly = weeklyData;

    //last notification
    this.lastNotification =
      await this.notificationsService.getLastNotificationBySystem(
        Number(this.systemId)
      );

    if (!this.lastNotification.notification) {
      this.lastNotification.notification = {
        lastType: 'N/A',
        lastDate: 'N/A',
        name: 'N/A'
      };
    } else {
      this.lastNotification.notification.lastDate = moment(
        this.lastNotification.notification.time
      ).format('DD/MM/YYYY hh:mm:ss a');

      this.lastNotification.notification.lastType =
        this.lastNotification.notification.title;
    }

    //tabs
    this.loadTab = this.systemData.attributes.structure.common.systemTabs.load
      ? true
      : false;
    this.electricalNetTab = this.systemData.attributes.structure.common
      .systemTabs.electricNet
      ? true
      : false;
    this.batteriesTab = this.systemData.attributes.structure.common.systemTabs
      .batteries
      ? true
      : false;
    this.solarTab = this.systemData.attributes.structure.common.systemTabs.solar
      ? true
      : false;
    this.generatorSetTab = this.systemData.attributes.structure.common
      .systemTabs.generatorSet
      ? true
      : false;

    //add image
    this.imageIndicator = `assets/weather/1x/${this.systemData.records[0].icon}.png`;
    // Para deshabilitar cualquier indicador recibido del backend
    if (this.systemData.attributes.plantDetails.inactiveIndicators) {
      this.systemData.attributes.plantDetails.inactiveIndicators.forEach(
        (element: any, index: any) => {
          //Si el indicador esta deshabilitado, deshabilita el indicador en el htmls
          if (element == 1) {
            this.indicators[index].off = element;
          }
        }
      );
    }

    if (this.systemData == null) return;

    this.systemImgs = JSON.parse(this.systemData.attributes.imgs);

    let date = moment(this.systemData.records[0].time).utcOffset(
      this.systemData.attributes[
        this.systemData.attributes.structure.common.hb3.var
      ]
    );

    this.systemCurrentDate = date.format('DD/MM');
    this.systemCurrentYear = date.format('YYYY');
    this.systemCurrentHour = date.format('hh:mm a');
    this.systemCreated = moment().format('DD/MM/YYYY hh:mm:ss a');

    this.defaultDate = moment(new Date())
      .utcOffset(
        this.systemData.attributes[
          this.systemData.attributes.structure.common.hb3.var
        ]
      )
      .format('yyyy-MM-DD');

    this.lastConnection = moment(this.systemData.records[0].time)
      .utcOffset(this.systemData.attributes.timeZone)
      .format('DD/MM/YYYY hh:mm:ss a');
    if (this.systemData.attributes.status == '10000') {
      this.status = true;
    }

    localStorage.setItem('eq', this.systemData.attributes.equipmentId);

    // google map
    if (this.systemData) {
      this.mapLat = this.systemData.attributes.latitude;
      this.mapLng = this.systemData.attributes.longitude;
      this.mapZoom = 14;
    }
  }

  async refresh() {
    // update system data
    let auxSystemData = await this.systemsService.getSystemById(this.systemId);

    //update monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);

    //update daily data
    let dailyData = await this.systemsService.getSystemDailyData(this.systemId);

    //update weekly data
    let weeklyData = await this.systemsService.getSystemWeekly(this.systemId);

    this.systemData = auxSystemData.system;
    console.log('sysData: ', this.systemData);

    this.systemData.monthly = monthlyData;
    this.systemData.daily = dailyData;
    this.systemData.weekly = weeklyData;

    //update last notification
    this.lastNotification =
      await this.notificationsService.getLastNotificationBySystem(
        Number(this.systemId)
      );

    if (!this.lastNotification.notification) {
      this.lastNotification.notification = {
        lastType: 'N/A',
        lastDate: 'N/A',
        name: 'N/A'
      };
    } else {
      this.lastNotification.notification.lastDate = moment(
        this.lastNotification.notification.time
      ).format('DD/MM/YYYY hh:mm:ss a');

      this.lastNotification.notification.lastType =
        this.lastNotification.notification.title;
    }
    console.log('lastNotification: ', this.lastNotification);

    // add image
    this.imageIndicator = `assets/weather/1x/${this.systemData.records[0].icon}.png`;

    let date = moment(this.systemData.records[0].time).utcOffset(
      this.systemData.attributes[
        this.systemData.attributes.structure.common.hb3.var
      ]
    );

    this.systemCurrentDate = date.format('DD/MM');
    this.systemCurrentYear = date.format('YYYY');
    this.systemCurrentHour = date.format('hh:mm a');
    this.systemCreated = moment().format('DD/MM/YYYY hh:mm:ss a');

    this.defaultDate = moment(new Date())
      .utcOffset(
        this.systemData.attributes[
          this.systemData.attributes.structure.common.hb3.var
        ]
      )
      .format('yyyy-MM-DD');

    this.lastConnection = moment(this.systemData.records[0].time)
      .utcOffset(this.systemData.attributes.timeZone)
      .format('DD/MM/YYYY hh:mm:ss a');
    if (this.systemData.attributes.status == '10000') {
      this.status = true;
    }
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 140;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }

  public getdefaultValueSpinnerV2(value, units) {
    return '' + value + ' ' + units;
  }

  /**
   *  Go to notification page
   */
  GoNotification(system) {
    if (system.records[0].status == null) return;

    if (system.records[0].status.sNotifications == 'clear') {
      return;
    } else {
      this.router.navigateByUrl('/dashboard/notifications', { state: system });
    }
  }
}
