import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { SystemsService } from 'src/app/services/systems/systems.service';
import { IndicatorsService } from 'src/app/services/indicators/indicators.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlertService } from 'src/app/services/alert/sweet-alert.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';
import { MathToolsService } from 'src/app/services/mathTools/math-tools.service';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-indicator-t1',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent implements OnInit {
  //Image to krill spinner from indicator value
  indicatorImageValue = 1;

  imageIndicator: string;

  sidenavOpened: boolean;

  pageLoading: boolean = true;
  barsLoading: boolean = false;
  tabLoading: boolean = false;
  tabLoading2: boolean = false;
  histLoading: boolean = false;

  spinnerRemasterizedDiameter: number = 135;

  systemIndicatorsData: any;
  systemData: any;
  systemId: string;
  systemCurrentDate: string;
  systemCurrentYear: string;
  systemCurrentHour: string;

  lastConnection: string;

  indicatorsDeviation: any;

  availabilityHistoricsTable: any;
  availabilityHistoricalValues: any;

  sustHistTable: any;

  indicatorsProjections: any;

  systemImgs: [];

  indicators: any[] = [
    {
      name: 'availability',
      off: 0
    },
    {
      name: 'profitability',
      off: 0
    },
    {
      name: 'sustainability',
      off: 0
    }
  ];

  //tabs
  loadTab: any;
  electricalNetTab: any;
  batteriesTab: any;
  solarTab: any;
  generatorSetTab: any;

  indicatorToggle: any;
  subIndicatorToggle: any;

  constructor(
    public systemsService: SystemsService,
    private activatedRoute: ActivatedRoute,
    public indicatorsService: IndicatorsService,
    private config: NgbTooltipConfig,
    public sweetAlertService: SweetAlertService,
    public sidenavService: SidenavService,
    public mathTools: MathToolsService,
    private router: Router,
    public utils: UtilsService,
  ) {
    config.disableTooltip = true;
    config.container = 'body';
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
    // this.maxLimitChartsChange();
  }

  async ngOnInit() {
    // await this.systemsService.isEquipmentRight();
    this.systemId = this.activatedRoute.snapshot.paramMap.get('id');

    // Set spinner Diameter and charts Ticks
    this.spinnerResizes();
    // this.maxLimitChartsChange();

    await this.systemDataSetup();
    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemId
    );

    //console.log("systemIndicatorsData: ", this.systemIndicatorsData);

    // this.indicatorsDeviation =
    //   await this.indicatorsService.getIndicatorsDeviation(this.systemId);
    this.indicatorsProjections = await this.systemsService.getSystemProjections(
      this.systemId
    );
    // daily has 8 item, we need to have 7 so we use .pop
    this.indicatorsProjections.data.weatherRequest.daily.pop();

    this.pageLoading = false;

    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  setTogglesValues(indicator) {
    this.indicatorToggle = indicator;
    this.subIndicatorToggle = 'deviations';
    localStorage.setItem('indicator', indicator);
  }

  async systemDataSetup() {
    this.systemData = (
      await this.systemsService.getSystemById(this.systemId)
    )?.system;
    console.log('sysData indicators: ', this.systemData);

    //monthly data
    let monthlyData = await this.systemsService.getSystemMonthly(this.systemId);
    this.systemData.monthly = monthlyData;

    //daily data
    let dailyData = await this.systemsService.getSystemDailyData(this.systemId);
    this.systemData.daily = dailyData;
    //weekly data
    let weeklyData = await this.systemsService.getSystemWeekly(this.systemId);
    this.systemData.weekly = weeklyData;

    //tabs
    this.loadTab = this.systemData.attributes.structure.common.systemTabs.load
      ? true
      : false;
    this.electricalNetTab = this.systemData.attributes.structure.common
      .systemTabs.electricNet
      ? true
      : false;
    this.batteriesTab = this.systemData.attributes.structure.common.systemTabs
      .batteries
      ? true
      : false;
    this.solarTab = this.systemData.attributes.structure.common.systemTabs.solar
      ? true
      : false;
    this.generatorSetTab = this.systemData.attributes.structure.common
      .systemTabs.generatorSet
      ? true
      : false;

    //add image
    this.imageIndicator = `assets/weather/1x/${this.systemData.records[0].icon}.png`;

    // Para deshabilitar cualquier indicador recibido del backend
    if (this.systemData.attributes.plantDetails.inactiveIndicators) {
      this.systemData.attributes.plantDetails.inactiveIndicators.forEach(
        (element: any, index: any) => {
          //Si el indicador esta deshabilitado, deshabilita el indicador en el htmls
          if (element == 1) {
            this.indicators[index].off = element;
          }
        }
      );
    }

    if (this.systemData == null) {
      return;
    }
    this.systemImgs = JSON.parse(this.systemData.attributes.imgs);

    let date = moment(this.systemData.records[0].time).utcOffset(
      this.systemData.attributes[
        this.systemData.attributes.structure.common.hb3.var
      ]
    );
    this.systemCurrentDate = date.format('DD, MMM YYYY');
    this.systemCurrentYear = date.format('YYYY');
    this.systemCurrentHour = date.format('hh:mm a');

    this.lastConnection = moment
      .unix(this.systemData.records[0].time)
      .format('DD/MM/YYYY hh:mm:ss a');
    // if (this.systemData.attributes.status == '00000') {
    //   this.status = false;
    // }

    localStorage.setItem('eq', this.systemData.attributes.equipmentId);
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 135;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }

  /**
   *  Save name indicator in localStorage to use in indicator page
   */
  saveIndicator(indicator: string) {
    if (indicator) localStorage.setItem('indicator', indicator);
  }

  /**
   *  Go to notification page
   */
  GoNotification(system) {
    if (system.records[0].status == null) return;

    if (system.records[0].status.sNotifications == 'clear') {
      return;
    } else {
      this.router.navigateByUrl('/dashboard/notifications', { state: system });
    }
  }
}
