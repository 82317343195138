<ion-app>
  <mat-drawer-container autosize>
    <mat-drawer #drawer [mode]="sidenavMode" [opened]="sidenavOpened">
      <mat-list>
        <mat-list-item class="logo-krill">
          <img src="../assets/logos/LOGOTIPO_KRILL_ENERGY_8.svg" alt="" />
        </mat-list-item>
        <!-- home -->
        <mat-list-item class="menu-item">
          <button
            mat-button
            routerLink="/dashboard/home"
            routerLinkActive="button-active"
          >
            <ion-icon name="home-outline"></ion-icon>
            {{ 'NAVIGATION.home' | translate }}
          </button>
        </mat-list-item>
        <!-- systems -->
        <mat-list-item class="menu-item" style="pointer-events: none">
          <button mat-button>
            <ion-icon name="folder-open-outline"></ion-icon>
            {{ 'NAVIGATION.systems' | translate }}
          </button>
        </mat-list-item>
        <mat-divider *ngIf="loadingRoutes" class="menu-divider"></mat-divider>
        <!-- system's name item if it is summary page -->
        <mat-list-item
          *ngIf="
            router.url.substring(0, 19) == '/dashboard/summary/' &&
            !loadingRoutes
          "
          class="menu-item system-name-item"
        >
          <button mat-button disabled>
            <ion-icon name="folder-open-outline"></ion-icon>
            <span class="system-name-ellipsis-overflow">{{ systemName }}</span>
          </button>
        </mat-list-item>
        <mat-divider
          *ngIf="
            router.url.substring(0, 19) == '/dashboard/summary/' &&
            !loadingRoutes
          "
          class="menu-divider"
        ></mat-divider>
        <!-- system's name item if it is system page -->
        <mat-list-item
          *ngIf="
            router.url.substring(0, 18) == '/dashboard/system/' &&
            !loadingRoutes
          "
          class="menu-item system-name-item"
        >
          <button mat-button disabled>
            <ion-icon name="folder-open-outline"></ion-icon>
            <span class="system-name-ellipsis-overflow">{{ systemName }}</span>
          </button>
        </mat-list-item>
        <mat-divider
          *ngIf="
            router.url.substring(0, 18) == '/dashboard/system/' &&
            !loadingRoutes
          "
          class="menu-divider"
        ></mat-divider>
        <!-- system's name item if it is indicator's page -->
        <mat-list-item
          *ngIf="
            router.url.substring(0, 22) == '/dashboard/indicators/' &&
            !loadingRoutes
          "
          class="menu-item system-name-item"
        >
          <button mat-button disabled>
            <ion-icon name="folder-open-outline"></ion-icon>
            <span class="system-name-ellipsis-overflow">{{ systemName }}</span>
          </button>
        </mat-list-item>
        <mat-divider
          *ngIf="
            router.url.substring(0, 22) == '/dashboard/indicators/' &&
            !loadingRoutes
          "
          class="menu-divider"
        ></mat-divider>
        <!-- reports -->
        <mat-list-item class="menu-item">
          <button
            mat-button
            routerLink="/dashboard/reports"
            routerLinkActive="button-active"
          >
            <ion-icon name="google-docs"></ion-icon>
            {{ 'NAVIGATION.reports' | translate }}
          </button>
        </mat-list-item>
        <mat-divider class="menu-divider"></mat-divider>
        <!-- analysis -->
        <mat-list-item class="menu-item" style="pointer-events: none">
          <button mat-button>
            <ion-icon name="analytics-outline"></ion-icon>
            {{ 'NAVIGATION.analysis' | translate }}
          </button>
        </mat-list-item>
        <mat-divider class="menu-divider"></mat-divider>
        <!-- notifications -->
        <mat-list-item class="menu-item">
          <button
            mat-button
            routerLink="/dashboard/notifications"
            routerLinkActive="button-active"
          >
            <ion-icon name="bell"></ion-icon>
            <ion-label>{{ 'NAVIGATION.notifications' | translate }}</ion-label>
          </button>
        </mat-list-item>
        <mat-divider class="menu-divider"></mat-divider>
        <!-- contacts -->
        <mat-list-item class="menu-item">
          <button
            mat-button
            routerLink="/dashboard/contact"
            routerLinkActive="button-active"
          >
            <ion-icon name="person-outline"></ion-icon>
            {{ 'NAVIGATION.contact' | translate }}
          </button>
        </mat-list-item>
        <mat-divider class="menu-divider"></mat-divider>

        <!-- <mat-list-item class="menu-item" *ngIf="userProfile.role == 'admin'">
          <button
            mat-button
            routerLink="/dashboard/users"
            routerLinkActive="button-active"
          >
            <ion-icon name="people-outline"></ion-icon>
            {{ 'NAVIGATION.users' | translate }}
          </button>
        </mat-list-item> -->
        <!-- admin -->
        <mat-list-item class="menu-item" *ngIf="userProfile.role == 'admin'">
          <button
            mat-button
            routerLink="/dashboard/adm-panel"
            routerLinkActive="button-active"
          >
            <ion-icon name="admin"></ion-icon>
            <span id="spanText">{{ 'NAVIGATION.adminpanel' | translate }}</span>
          </button>
        </mat-list-item>
        <mat-divider class="menu-divider"></mat-divider>
        <!-- configurations -->
        <mat-list-item class="menu-item" style="pointer-events: none">
          <button
            mat-button
            routerLink="/dashboard/configurations"
            routerLinkActive="button-active"
          >
            <ion-icon name="settings-outline"></ion-icon>
            {{ 'NAVIGATION.configs' | translate }}
          </button>
        </mat-list-item>
        <mat-divider class="menu-divider"></mat-divider>
      </mat-list>
      <div *ngIf="false" class="sidenav-footer">
        <img src="../assets/logos/InterVE-Logo.png" alt="" />
        <h3>
          Sistemas de energía esencial para maximizar la disponibilidad de las
          nuevas redes de distribución de servicios INTER a nivel nacional.
        </h3>
      </div>
    </mat-drawer>

    <ion-header [translucent]="true">
      <ion-toolbar>
        <button
          mat-icon-button
          slot="start"
          (click)="drawer.toggle(); sidenavChanged(drawer.opened)"
          matTooltip="{{ 'NAVIGATION.menu' | translate }}"
        >
          <ion-icon class="toolbar-icon" name="menu-outline"></ion-icon>
        </button>

        <button
          mat-icon-button
          slot="start"
          *ngIf="
            router.url.substring(0, 18) == '/dashboard/system/' &&
            !loadingRoutes
          "
          [routerLink]="
            indicatorsService.getIndicatorRoute(equipmentId, systemId)
          "
        >
          <ion-icon
            class="material-icons toolbar-icon"
            name="speedometer-outline"
          ></ion-icon>
        </button>
        <!-- arrow back button if it is indicator page to go to system page  -->
        <button
          mat-icon-button
          slot="start"
          matTooltip="{{ 'NAVIGATION.goBack' | translate }}"
          [routerLink]="
            systemsService.getSystemRoute(equipmentId, systemId, false, true)
          "
          *ngIf="
            router.url.substring(0, 22) == '/dashboard/indicators/' &&
            !loadingRoutes
          "
        >
          <ion-icon
            class="toolbar-icon"
            name="return-up-back-outline"
          ></ion-icon>
        </button>
        <button
          slot="end"
          mat-icon-button
          [routerLink]="
            systemsService.getSystemRoute(equipmentId, systemId, false, true)
          "
          *ngIf="
            router.url.substring(0, 22) == '/dashboard/indicators/' &&
            !loadingRoutes
          "
        >
          <ion-icon class="toolbar-icon" name="desktop-outline"></ion-icon>
        </button>
        <div
          slot="end"
          *ngIf="
            router.url.substring(0, 18) == '/dashboard/system/' &&
            !loadingRoutes
          "
        >
          <button
            *ngIf="equipmentId != '4'"
            mat-icon-button
            [routerLink]="
              indicatorsService.getIndicatorRoute(equipmentId, systemId)
            "
          >
            <ion-icon
              class="material-icons toolbar-icon"
              name="speedometer-outline"
            ></ion-icon>
          </button>
        </div>
        <!-- <button
          mat-icon-button
          slot="end"
          [matMenuTriggerFor]="languages"
          matTooltip="{{ 'NAVIGATION.languages' | translate }}"
        >
          <ion-icon class="toolbar-icon" name="globe-outline"></ion-icon>
        </button> -->
        <button slot="end" mat-icon-button (click)="logOut()">
          <ion-icon class="toolbar-icon" name="group"></ion-icon>
        </button>

        <mat-menu #languages="matMenu">
          <button (click)="languageChanged('en')" mat-menu-item>
            {{ 'NAVIGATION.languagesNames.en' | translate }}
          </button>
          <button (click)="languageChanged('es')" mat-menu-item>
            {{ 'NAVIGATION.languagesNames.es' | translate }}
          </button>
        </mat-menu>
      </ion-toolbar>
    </ion-header>
    <div class="page-content">
      <router-outlet></router-outlet>
    </div>
    <ion-content> </ion-content>
  </mat-drawer-container>
</ion-app>
