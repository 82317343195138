<div class="dialog-div">
  <h3>
    {{ 'DIALOG.delete' | translate }}
    <span *ngIf="title == 'user'">{{ 'DIALOG.user' | translate }}</span>
    <span *ngIf="title == 'company'">{{ 'DIALOG.company' | translate }}</span>
    <span *ngIf="title == 'project'">{{ 'DIALOG.project' | translate }}</span>
  </h3>
  <mat-dialog-content [formGroup]="form">
    <h5>{{ name }}</h5>
    <mat-form-field>
      <input
        matInput
        type="text"
        formControlName="krill"
        placeholder="{{ 'DIALOG.deleteMessage' | translate }}"
        required
        name="krill"
        [errorStateMatcher]="errorMatcher"
      />
      <mat-error
        *ngIf="
          form.controls.krill.hasError('required') &&
          !form.hasError('krillDoNotMatch')
        "
      >
        {{ 'DIALOG.writeKrill' | translate }}
      </mat-error>
      <mat-error *ngIf="form.hasError('krillDoNotMatch')">
        {{ 'DIALOG.errorWritingKrill' | translate }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="closeDialog()">
      {{ 'DIALOG.close' | translate }}
    </button>
    <button
      class="mat-raised-button mat-primary"
      [disabled]="!form.valid"
      (click)="delete()"
    >
      {{ 'DIALOG.deleteOk' | translate }}
    </button>
  </mat-dialog-actions>
</div>
