<mat-card>
  <mat-card-content>
    <img
      class="logo"
      src="../../../assets/logos/LOGOTIPO_KRILL ENERGY_3.png"
      alt=""
    />
    <form [formGroup]="loginForm" action="" (ngSubmit)="onSubmit()" *ngIf="login">
      <mat-form-field>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="{{ 'LOGIN.email' | translate }}"
          required
          name="email"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          *ngIf="
            loginForm.controls.email.hasError('email') &&
            !loginForm.controls.email.hasError('required')
          "
        >
          {{ 'LOGIN.introduceValidEmail' | translate }}
        </mat-error>
        <mat-error *ngIf="loginForm.controls.email.hasError('required')">
          {{ 'LOGIN.introduceEmail' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="password"
          formControlName="password"
          placeholder="{{ 'LOGIN.password' | translate }}"
          required
          name="password"
          [errorStateMatcher]="matcher"
        />
        <mat-error *ngIf="loginForm.controls.password.hasError('required')">
          {{ 'LOGIN.introducePassword' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-label class="forgot-password"
        (click)="resetLoginForm()"
        type="button"
        mat-raised-button
      >
        ¿Olvidaste tu contraseña?
      </mat-label>
      <mat-error *ngIf="submitError" class="credentials-error">
        {{ 'LOGIN.errorCredentials' | translate }}
      </mat-error>
      <button
        type="submit"
        mat-raised-button
        [disabled]="!loginForm.valid"
        color="primary"
      >
        {{ 'LOGIN.login' | translate }}
      </button>
    </form>

    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPassword()" *ngIf="!login">
      <mat-label class="forgot-password"
        style="display: flex; text-align: center; margin: 20px 20px 0 20px;"
      >
        Ingrese su dirección de correo electrónico para iniciar el proceso de cambio de contraseña
      </mat-label>
      <mat-form-field>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="{{ 'LOGIN.email' | translate }}"
          required
          name="email"
          [errorStateMatcher]="matcher"
        />
        <mat-error
          *ngIf="
            forgotPasswordForm.controls.email.hasError('email') &&
            !forgotPasswordForm.controls.email.hasError('required')
          "
        >
          {{ 'LOGIN.introduceValidEmail' | translate }}
        </mat-error>
        <mat-error *ngIf="forgotPasswordForm.controls.email.hasError('required')">
          {{ 'LOGIN.introduceEmail' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-label class="forgot-password"
        (click)="resetForgotPasswordForm()"
      >
        ¿Ya tiene una cuenta? <span><b>Iniciar sesión</b></span>
      </mat-label>
      <button
        type="submit"
        mat-raised-button
        [disabled]="!forgotPasswordForm.valid"
        color="primary"
      >
        Recuperar contraseña
      </button>
    </form>
  </mat-card-content>
</mat-card>
