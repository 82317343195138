import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemsService {
  constructor(private router: Router) {}

  async getSystemListPaginated(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    currentPage: string,
    status: string,
    name: string
    // isEnergy = true
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'systems/list/Pagination' +
          '?companyId=' +
          companyId +
          '&projectId=' +
          projectId +
          '&type=' +
          type +
          '&regionId=' +
          regionId +
          '&currentPage=' +
          currentPage +
          `${status !== '' ? '&status=' + status : []}` +
          `${name !== '' ? '&name=' + name : []}`,
        // '&isEnergy=' +
        // isEnergy,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async getMapSites(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string,
    status: string,
    name: string
    // isEnergy = true
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'systems/list/resume' +
          '?companyId=' +
          companyId +
          '&projectId=' +
          projectId +
          '&type=' +
          type +
          '&regionId=' +
          regionId +
          `${status !== '' ? '&status=' + status : []}` +
          `${name !== '' ? '&name=' + name : []}`,
        // '&isEnergy=' +
        // isEnergy,
        {
          headers: {}
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  async getCSVSites(
    companyId: string,
    projectId: string,
    type: string,
    regionId: string
  ) {
    try {
      let response = await axios.get(
        environment.apiUrl +
          'systems/list/csv' +
          '?companyId=' +
          companyId +
          '&projectId=' +
          projectId +
          '&type=' +
          type +
          '&regionId=' +
          regionId,
        // '&isEnergy=' +
        // isEnergy,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * @param companyId
   * DEPRECATED
   */
  public async getSystemList(companyId: string) {
    try {
      console.log(localStorage.getItem('PROJECT_ID'));
      let response = await axios.get(
        environment.apiUrl +
          'systems/summary/list/' +
          companyId +
          '?projectId=' +
          localStorage.getItem('PROJECT_ID'),
        {
          headers: {}
        }
      );
      return response.data.data;
    } catch (err) {
      return err;
    }
  }

  public async getSystemById(id: string) {
    try {
      let response = await axios.get(environment.apiUrl + 'systems/' + id, {
        headers: {}
      });
      let system = response.data.data;
      let weather = await axios.get(
        environment.apiUrl + 'systems/' + id + '/weather',
        {
          headers: {}
        }
      );
      system.system.records[0].temp = weather.data.data.temp;
      system.system.records[0].icon = weather.data.data.icon;
      return system;
    } catch (err) {
      return err;
    }
  }

  /**
   * Returns system's name by the given id
   * @param id
   */
  public async getSystemNameById(id: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'systems/details/sidebar/' + id,
        {
          headers: {}
        }
      );
      return response.data.data.attributes.name;
    } catch (err) {
      return err;
    }
  }

  public async getSystemIndicators(id: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'systems/indicator/' + id,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getSystemMonthly(id: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'records/' + id + '/monthly',
        {
          headers: {}
        }
      );
      if (response.data.monthly.length == 0) {
        return {};
      }
      return response.data.monthly[0];
    } catch (err) {
      return err;
    }
  }
  public async getSystemDailyData(id: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'records/' + id + '/daily',
        {
          headers: {}
        }
      );
      if (response.data.daily.length == 0) {
        return {};
      }
      return response.data.daily[0];
    } catch (err) {
      return err;
    }
  }
  public async getSystemWeekly(id: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'records/' + id + '/weekly',
        {
          headers: {}
        }
      );
      if (response.data.weekly.length == 0) {
        return {};
      }
      return response.data.weekly[0];
    } catch (err) {
      return err;
    }
  }

  public async getSystemProjections(id: string) {
    try {
      let response = await axios.get(
        environment.apiUrl + 'systems/' + id + '/weather/week',
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsChart(
    systemId: number,
    equipmentId: number,
    tabIndex: number,
    startDate: string,
    endDate: string,
    timeZone: string
  ) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'records/charts',
        {
          systemsId: systemId,
          equipmentId: equipmentId,
          tabIndex: tabIndex,
          startDate: startDate,
          endDate: endDate,
          timeZone: timeZone
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      return response.data.chartsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsChartIndicator(
    systemId: number,
    equipmentId: number,
    startDate: string,
    endDate: string,
    timeZone: string
  ) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'records/charts/indicator',
        {
          systemsId: systemId,
          equipmentId: equipmentId,
          startDate: startDate,
          endDate: endDate,
          timeZone: timeZone
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );

      return response.data.chartsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsBars(
    systemId: number,
    equipmentId: number,
    tabIndex: number,
    intervals: string
  ) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'records/bars',
        {
          systemsId: systemId,
          equipmentId: equipmentId,
          tabIndex: tabIndex,
          intervals: intervals
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data.barsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsBarsEnergy(
    systemId: number,
    equipmentId: number,
    tabIndex: number,
    intervals: string
  ) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'records/bars/energy',
        {
          systemsId: systemId,
          equipmentId: equipmentId,
          tabIndex: tabIndex,
          intervals: intervals
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('response en serv. ', response);
      return response.data.barsData;
    } catch (err) {
      return err;
    }
  }

  public async getRecordsHistoryEvents(
    systemId: number,
    equipmentId: number,
    startDate: string,
    endDate: string,
    timeZone: string,
    fullData: boolean
  ) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'records/history/events',
        {
          systemsId: systemId,
          equipmentId: equipmentId,
          startDate: startDate,
          endDate: endDate,
          timeZone: timeZone,
          fullData: fullData
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  /**
   * Returns the system's route depending on the equipment id and the summaryPage
   * If summaryPage
   * @param equipmentId The Equipment id of the system
   * @param sysmteId The system id
   * @param summaryPage It could be hardcoded, it defines if the route should be the system's summaryPage
   * @param fromIndicators If we are in indicator's Page, the route is the system's page
   */
  public getSystemRoute(
    equipmentId: any,
    systemId: any,
    summaryPage: boolean = false,
    // fromHome: boolean = true,
    fromIndicators: boolean = false
    // systemType: any
  ) {
    equipmentId = equipmentId.toString();
    systemId = systemId.toString();

    //if (fromIndicators || !false)
    if (fromIndicators || !false) {
      // switch (equipmentId) {
      //   case '1':
      //     if(systemId == 9) return '/dashboard/system/sacolar-ns/' + systemId;
      //     else return '/dashboard/system/sacolar/' + systemId;

      //   case '2':
      //     return '/dashboard/system/studer/' + systemId;

      //   case '3':
      //     return '/dashboard/system/studer-inv/' + systemId;

      //   case '4':
      //     return '/dashboard/system/krill-box/' + systemId;

      //   case '5':
      //     return '/dashboard/system/ups/' + systemId;

      //   case '6':
      //     return '/dashboard/system/krill-box-ups/' +systemId;

      //   case '7':
      //     if(summaryPage) return '/dashboard/summary/jas/' + systemId;
      //     else return '/dashboard/system/jas/' + systemId;

      //   case '8':
      //     if(summaryPage) return '/dashboard/summary/porvenir/' + systemId;
      //     else return '/dashboard/system/porvenir/' + systemId;
      //     // if(summaryPage) return '/dashboard/summary/eq14kb/' + systemId;
      //     // else return '/dashboard/system/eq14kb/' + systemId;

      //   case '9':
      //     if(summaryPage) return '/dashboard/summary/tigo/' + systemId;
      //     else return '/dashboard/system/tigo/' + systemId;

      //   case '10':
      //     if(summaryPage) return '/dashboard/summary/eq10kb/' + systemId;
      //     else return '/dashboard/system/eq10kb/' + systemId;

      //   case '11':
      //     if(summaryPage) return '/dashboard/summary/eq11kb/' + systemId;
      //     else return '/dashboard/system/eq11kb/' + systemId;

      //   case '12':
      //     if(summaryPage) return '/dashboard/summary/eq12kb/' + systemId;
      //     else return '/dashboard/system/eq12kb/' + systemId;

      //   case '13':
      //       if(summaryPage) return '/dashboard/summary/eq13kb/' + systemId;
      //       else return '/dashboard/system/eq13kb/' + systemId;

      //   case '14':
      //       if(summaryPage) return '/dashboard/summary/eq14kb/' + systemId;
      //       else return '/dashboard/system/eq14kb/' + systemId;

      //     //Te lleva a la pagina Lite, summary
      //     //return '/dashboard/summary/jas/' + systemId;
      //   default:
      //     return;
      //     break;

      // }
      if (equipmentId == 0) return;
      if (summaryPage) return '/dashboard/summary/t1/' + systemId;
      else return '/dashboard/system/t1/' + systemId;
    }
  }

  /**
   * Returns the system's status code as a word
   *
   * @param code Code of the system status
   */
  public getSystemStatus(code: string, ignore: boolean = false) {
    switch (code) {
      case '00000':
        return 'disconnected';

      case '10000':
        return 'connected';

      case '11000':
        if (ignore) return 'connected';
        return 'notification';

      case '10100':
        if (ignore) return 'connected';
        return 'alert';

      case '10010':
        if (ignore) return 'connected';
        return 'alarm';

      case '10001':
        return 'error';

      default:
        return 'error';
    }
  }

  /**
   * Returns the first img url of the system. If there is none returns null
   * @param system System Objt
   */
  public getSystemFirstImg(system: any) {
    if (system.imgs == null) {
      return null;
    }
    return JSON.parse(system.imgs)[0];
  }

  /**
   * Returns the system's status code as a color
   *
   * @param code Code of the system status
   */
  public getSystemStatusColor(code: string) {
    switch (code) {
      case '00000':
        return 'black';

      case '10000':
        return 'green';

      case '11000':
        return 'blue';

      case '10100':
        return 'yellow';

      case '10010':
        return 'orange';

      case '10001':
        return 'red';

      default:
        return 'red';
    }
  }

  public getSystemSummaryData(systemData: any, stucture: any) {
    // console.log("sysD: ", systemData, "stucture: ", stucture)
    let result = null;
    if (stucture.dir == 'records') {
      result = systemData.records[0][stucture.var];
    } else if (stucture.dir == 'monthly') {
      result = systemData.monthly[stucture.var];
    } else if (stucture.dir == 'daily') {
      result = systemData.daily[stucture.var];
    } else if (stucture.dir == 'weekly') {
      result = systemData.weekly[stucture.var];
    } else if (stucture.dir == 'plantDetails') {
      result = systemData.attributes.plantDetails.system.Premisas[stucture.var];
    }
    if (typeof result == 'number') {
      return result.toFixed(2);
    } else {
      return result;
    }
  }

  public getSystemNotification(records, alarmName = null) {
    if ('status' in records[0]) {
      const status = records[0].status.sNotifications;
      switch (status) {
        case 'alarm':
          if (alarmName == null) return 'Alarma';
          return alarmName;

        case 'warning':
          if (alarmName == null) return 'Alerta';
          return alarmName;

        case 'info':
          if (alarmName == null) return 'Notificación';
          return alarmName;

        case 'clear':
          return 'Estado Ok';

        default:
          return 'Ok';
      }
    } else {
      return 'Ok';
    }
  }

  public async isEquipmentRight() {
    let routerItems = this.router.url.split('/');
    let systemId = routerItems.pop();
    let systemType = routerItems.pop();
    let system = await this.getSystemById(systemId);

    if (this.checkEquipmentId(systemType, system.equipmentId)) {
      return;
    } else {
      this.router.navigate(['/dashboard/home']);
    }
  }

  public checkEquipmentId(systemType, equipmentId) {
    switch (systemType) {
      case 'sacolar':
      case 'sacolar-ns':
        let listSacolar = [1];
        return listSacolar.includes(equipmentId);

      case 'studer':
        let listStuder = [2];
        return listStuder.includes(equipmentId);

      case 'studer-inv':
        let listStuderInv = [3];
        return listStuderInv.includes(equipmentId);

      case 'krill-box':
        let listKrillBox = [4];
        return listKrillBox.includes(equipmentId);

      case 'ups':
        let listUps = [5];
        return listUps.includes(equipmentId);

      case 'krill-box-ups':
        let listKrillBoxUps = [6];
        return listKrillBoxUps.includes(equipmentId);

      case 'jas':
        let listJas = [7];
        return listJas.includes(equipmentId);

      case 'porvenir':
        let listPorvenir = [8];
        return listPorvenir.includes(equipmentId);

      case 'tigo':
        let listTigo = [9];
        return listTigo.includes(equipmentId);

      case 'eq10kb':
        let listEq10kb = [10];
        return listEq10kb.includes(equipmentId);

      case 'eq11kb':
        let listEq11kb = [11];
        return listEq11kb.includes(equipmentId);

      case 'eq12kb':
        let listEq12kb = [12];
        return listEq12kb.includes(equipmentId);

      case 'eq13kb':
        let listEq13kb = [13];
        return listEq13kb.includes(equipmentId);

      case 'eq14kb':
        let listEq14kb = [14];
        // let listEq14kb = [8];
        return listEq14kb.includes(equipmentId);

      default:
        break;
    }
  }

  /**
   * returns all filter data by company id ( projects | companies | regions | type )
   * @param companyId user company id
   */
  public async getFilterData(companyId: string, projectId: string) {
    try {
      let response = await axios.get(
        `${environment.apiUrl}users/filter/company/${companyId}?projectId=${projectId}`,
        {
          headers: {}
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }
}
