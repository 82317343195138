import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './pages/dashboard/home/home.component';

import { NotificationsComponent } from './pages/dashboard/notifications/notifications.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ReportsComponent } from './pages/dashboard/reports/reports.component';

import { AuthGuard } from './guards/auth/auth.guard';
import { ContactComponent } from './pages/dashboard/contact/contact.component';
import { LoadingComponent } from './pages/loading/loading.component';

import { UsersComponent } from './pages/dashboard/users/users.component';
import { ConfigsComponent } from './pages/dashboard/configs/configs.component';

import { SystemComponent } from './pages/dashboard/systemPage/t1/system.component';
import { IndicatorComponent } from './pages/dashboard/indicatorsPage/t1/indicator.component';
import { SummaryComponent } from './pages/dashboard/summaryPage/t1/summary.component';

import { AdmPanelComponent } from './pages/dashboard/adm-panel/adm-panel.component';
import { CompaniesComponent } from './pages/dashboard/companies/companies.component';
import { ProjectsComponent } from './pages/dashboard/projects/projects.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', component: LoginComponent },
  { path: 'loading/:token', component: LoadingComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'configurations',
        component: ConfigsComponent
      },
      {
        path: 'system/t1/:id',
        component: SystemComponent
      },
      {
        path: 'indicators/t1/:id',
        component: IndicatorComponent
      },
      {
        path: 'summary/t1/:id',
        component: SummaryComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'adm-panel',
        component: AdmPanelComponent
      },
      {
        path: 'companies',
        component: CompaniesComponent
      },
      {
        path: 'projects',
        component: ProjectsComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
