<ion-slides
  (click)="openDialog()"
  id="slider"
  scrollbar="true"
  pager="true"
  style="height: 100%"
>
  <ion-slide *ngFor="let img of systemImgs">
    <img [src]="img" alt="" />
  </ion-slide>
</ion-slides>
