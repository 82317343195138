import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor() {}
  private dataObserved = new BehaviorSubject<any>('');
  sidenavOpened = this.dataObserved.asObservable();

  changeSidenav(state: boolean) {
    this.dataObserved.next(state);
  }
}
