<div class="dialog-div">
  <button mat-icon-button mat-dialog-close class="close-button">
    <ion-icon name="close-outline"></ion-icon>
  </button>

  <ion-slides
    class="dialog-slides"
    cdkFocusInitial
    id="slider"
    scrollbar="true"
    pager="true"
    #slider
  >
    <ion-slide *ngFor="let img of systemImgs">
      <img [src]="img" alt="" />
    </ion-slide>
  </ion-slides>
  <!-- previous page -->
  <button (click)="this.slider.slidePrev()" class="buttonSlide buttonSlide-dialogPrev"> 
    <ion-icon name="chevron-back-outline"></ion-icon>
  </button>
  <!-- next page -->
  <button (click)="this.slider.slideNext()" class="buttonSlide buttonSlide-dialogNext"> 
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </button>
</div>
