import { Injectable } from '@angular/core';
import { TranslateConfigService } from '../translate-config/translate-config.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private translate: TranslateConfigService) {}

  public getWeather(data:string):string {
    let weather = this.translate.getTranslation(`SYSTEM.weather.${data}`);
    weather = weather.split(" ")[0];
    return weather;
  }
}
