import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { AddEditDialogComponent } from 'src/app/components/add-edit-dialog/add-edit-dialog.component';
import { DeleteDialogComponent } from 'src/app/components/delete-dialog/delete-dialog.component';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { CompaniesService } from 'src/app/services/companies/companies.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-modal-projects',
  templateUrl: './modal-projects.component.html',
  styleUrls: ['./modal-projects.component.scss'],
})
export class ModalProjectsComponent implements OnInit {

  matcher = new MyErrorStateMatcher();

  //company
  companyForm: FormGroup;
  name:string;
  companyId:any;
  companyName:any;

  okName: string;
  action: string;
  title: string;

  projects:any[] = [];

  tabLoading: boolean = false;

  constructor(
    private dialog: MatDialog,
    private companiesService: CompaniesService,
    private projectsService: ProjectsService,
    private _snackBar: MatSnackBar,
    private translate: TranslateConfigService,
    private fb: FormBuilder,
    private dialogRefModalProject: MatDialogRef<ModalProjectsComponent>,
    private dialogRef: MatDialogRef<AddEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.defineData(data);
  }

  async ngOnInit() {
    this.tabLoading = true;
    this.projects = await this.projectsService.getProjectList(this.companyId);
    this.tabLoading = false;
  }

  defineData(data:any){
    if(data.title === 'projects'){
      this.companyId = data.companyId;
      this.companyName = data.companyName;
    }

    this.title = data.title;
    this.action = data.action;
  }

  addProject() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'add',
      title: 'project',
      companyId: this.companyId,
    };

    const dialogRef = this.dialog.open(AddEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        let response = await this.projectsService.addProject(
          data.name,
          this.companyId
        );
        if (!response) {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
          return;
        }
        if (response.status >= 200 && response.status < 400) {
          this.projects = await this.projectsService.getProjectList(this.companyId);
          this._snackBar.open(
            this.translate.getTranslation('DIALOG.projectCreated')
          );
        } else {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  updateProject(project:any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'edit',
      title: 'project',
      name: project.name,
      ...project
    };

    const dialogRef = this.dialog.open(AddEditDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        let response = await this.projectsService.updateProject(
          data.id,
          data.name
        );
        if (response.status >= 200 && response.status < 400) {
          this.projects = await this.projectsService.getProjectList(this.companyId);
          this._snackBar.open(
            this.translate.getTranslation('DIALOG.projectEdited')
          );
        } else {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  deleteProject(project:any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      action: 'delete',
      title: 'project',
      name: project.name,
      ...project
    };

    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        var response = await this.projectsService.deleteProject(data.id);
        if (response.status >= 200 && response.status < 400) {
          this.projects = await this.projectsService.getProjectList(this.companyId);
          this._snackBar.open(
            this.translate.getTranslation('DIALOG.projectDeleted')
          );
        } else {
          this._snackBar.open(this.translate.getTranslation('DIALOG.fail'));
        }
      }
    });
  }

  close() {
    this.dialogRefModalProject.close();
  }

}
