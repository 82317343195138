<mat-tab-group
  (selectedTabChange)="tabChanged($event)"
  [selectedIndex]="openTabIndex"
  dynamicHeight
  mat-align-tabs="center"
  class="mat-tab-group-system"
>
  <!-- resume -->
  <mat-tab label="Resumen" disabled>
    <ng-template mat-tab-label>
      <a
        [routerLink]="
          systemsService.getSystemRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            true
          )
        "
        style="text-decoration: none !important"
      >
        <span class="mat-tab-label">
          <img
            style="margin-right: 8px"
            src="../../../assets/logos/favicon.png"
            alt=""
            width="25px"
          />
          {{ 'SYSTEM.resume' | translate }}
        </span>
      </a>
    </ng-template>
  </mat-tab>

  <!-- tabs -->
  <mat-tab *ngFor="let tab of tabs" [label]="tab">
    <ng-template mat-tab-label>
      <span *ngIf="tab == 'load'">
        <ion-icon class="tab-icon" name="newbulb"></ion-icon>
      </span>

      <span *ngIf="tab == 'batteries'">
        <ion-icon class="tab-icon" name="batt"></ion-icon>
      </span>

      <span *ngIf="tab == 'electricNet' && actualTabIndex != 1">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/electrical-tower-gray.svg"
        ></ion-icon>
      </span>

      <span *ngIf="tab == 'solar' && actualTabIndex != 3">
        <ion-icon class="tab-icon" src="./assets/icon/solar.svg"></ion-icon>
      </span>

      <span *ngIf="tab == 'electricNet' && actualTabIndex == 1">
        <ion-icon
          class="tab-icon"
          src="./assets/icon/electrical-tower-white.svg"
        ></ion-icon>
      </span>

      <span *ngIf="tab == 'solar' && actualTabIndex == 3">
        <ion-icon class="tab-icon" src="./assets/icon/solar-off.svg"></ion-icon>
      </span>

      <span *ngIf="tab == 'generatorSet' && actualTabIndex == 4">
        <ion-icon class="tab-icon" name="motor-off"></ion-icon>
      </span>

      <span *ngIf="tab == 'generatorSet' && actualTabIndex != 4">
        <ion-icon class="tab-icon" name="motor"></ion-icon>
      </span>

      {{ 'SYSTEM.' + tab | translate }}
    </ng-template>

    <!-- LOAD TAB -->
    <div class="tab load-tab" *ngIf="tab == 'load' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon class="info-icon" name="newbulb"></ion-icon>

          <div>
            <h1> Carga </h1>
            <h2
              [ngClass]="
                systemData.records[0][
                  systemData.attributes.structure.system.la.var
                ] > 0
                  ? 'green-color'
                  : 'red-color'
              "
            >
              {{
                'SYSTEM.status.' +
                  (systemData.records[0][
                    systemData.attributes.structure.system.la.var
                  ] >
                    0) | translate
              }}
            </h2>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.lb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.lb.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.lb
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.lb.label"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.lc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.lc.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.lc
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.lc.label"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <!-- <krill-spinner

            [spinnerValue]="
              (abs(
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.system.lc
                )
              ) *
                90) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.lc.limits.varMax
              ]
            "
          >
          </krill-spinner> -->

          <div class="percentage-container">
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.ld.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.ld
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.ld.unit }}
                {{ systemData.attributes.structure.system.ld.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.le.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.le
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.le.unit }}
                {{ systemData.attributes.structure.system.le.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.lf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.lf
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.lf.unit }}
                {{ systemData.attributes.structure.system.lf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>

      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>

        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            #linearChart
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartData"
            [options]="lineChartOptionsLoad"
            [colors]="lineChartColorsLoad"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="tipContentLoadChart"
            tooltipClass="left chart-tooltip"
            placement="left"
          >
            
          </div>
        </div>
      </div>
    </div>

    <!-- ELECTRICAL NET TAB -->
    <div class="tab electrical-net" *ngIf="tab == 'electricNet' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon
            class="info-icon"
            src="./assets/icon/electrical-tower-gray.svg"
          ></ion-icon>

          <div>
            <h1>Red Eléctrica</h1>
            <h2
              [ngClass]="
                systemData.records[0][
                  systemData.attributes.structure.system.ra.var
                ] != 0
                  ? 'green-color'
                  : 'red-color'
              "
            >
              <span
                *ngIf="
                  systemData.records[0][
                    systemData.attributes.structure.system.ra.var
                  ] != 0
                "
              >
                {{ 'SYSTEM.status.1' | translate }}
              </span>
              <span
                *ngIf="
                  systemData.records[0][
                    systemData.attributes.structure.system.ra.var
                  ] != 1
                "
              >
                {{ 'SYSTEM.status.0' | translate }}
              </span>
            </h2>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.rb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.rb.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.rb
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.rb.label"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.rc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.rc.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.rc
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.rc.label"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <!-- 1 -->
            <mat-card-header
              [ngbTooltip]="tipContent25"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.rd.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.rd.var[0]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.rd.var[0]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.rd.var[1]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.rd.var[1]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.rd.var[2]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.rd.var[2]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{ systemData.attributes.structure.system.rd.unit }}
                {{ systemData.attributes.structure.system.rd.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <!-- 2 -->
            <mat-card-header
              [ngbTooltip]="tipContent25"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.re.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.re.var[0]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.re.var[0]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.re.var[1]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.re.var[1]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{
                  systemData.records[0][
                    systemData.attributes.structure.system.re.var[2]
                  ]
                    ? (systemData.records[0][
                        systemData.attributes.structure.system.re.var[2]
                      ] | number : '1.0-2')
                    : ''
                }}
                {{ systemData.attributes.structure.system.re.unit }}
                {{ systemData.attributes.structure.system.re.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <!-- 3 -->
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.rf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.rf
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.rf.unit }}
                {{ systemData.attributes.structure.system.rf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>

      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>

        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartDataElect"
            [options]="lineChartOptionsElect"
            [colors]="lineChartColorsElect"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="electricalLeftChart"
            tooltipClass="left half-chart-tooltip"
            placement="left"
          >
        
          </div>
        </div>
      </div>
    </div>

    <!-- BATTERIES TAB -->
    <div class="tab batteries" *ngIf="tab == 'batteries' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon class="info-icon" name="batt"></ion-icon>
          <div>
            <!-- <h1>{{ 'SYSTEM.batteries' | translate }}</h1> -->
            <h1>Batería</h1>

            <h2
              style="color: green"
              *ngIf="
                systemData.records[0][
                  systemData.attributes.structure.system.ba.var
                ] == 1
              "
            >
              {{ 'SYSTEM.charging' | translate }}
            </h2>
            <h2
              style="color: rgb(207, 0, 0)"
              *ngIf="
                systemData.records[0][
                  systemData.attributes.structure.system.ba.var
                ] != 1
              "
            >
              {{ 'SYSTEM.discharging' | translate }}
            </h2>
          </div>
        </div>

        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.bb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.bb.limits.varMax
              ]
            "
            [textValue]="
              abs(
                systemsService.getSystemSummaryData(
                  systemData,
                  systemData.attributes.structure.system.bb
                )
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.bb.label"
            [ngbTooltip]="tipContent33"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.bc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.bc.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.bc
              ) | number : '1.0-2'
            "
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [imgValue]=""
            [descriptionTitle]="systemData.attributes.structure.system.bc.label"
            [ngbTooltip]="tipContent34"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <!-- 1 -->
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.bd.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.bd
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.bd.unit }}
                {{ systemData.attributes.structure.system.bd.subUnit }}
              </mat-card-title>
            </mat-card-header>
            <!-- 2 -->
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.be.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.be
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.be.unit }}
                {{ systemData.attributes.structure.system.be.subUnit }}
              </mat-card-title>
            </mat-card-header>
            <!-- 3 -->
            <mat-card-header
              [ngbTooltip]="tipContent20"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.bf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.bf
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.bf.unit }}
                {{ systemData.attributes.structure.system.bf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>
      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>

        <div class="linear-chart">
          <canvas
            class="linear-chart"
            baseChart
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartDataBattery"
            [options]="lineChartOptionsBattery"
            [colors]="lineChartColorsBattery"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="battLeftChart"
            tooltipClass="left half-chart-tooltip"
            placement="left"
          >
            
          </div>
        </div>
      </div>
    </div>

    <!-- SOLAR TAB -->
    <div class="tab solar" *ngIf="tab == 'solar' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon class="info-icon" src="./assets/icon/solar.svg"></ion-icon>

          <div>
            <h1> Solar </h1>
            <h2
              [ngClass]="
                systemData.records[0][
                  systemData.attributes.structure.system.sa.var
                ] > 0
                  ? 'green-color'
                  : 'red-color'
              "
            >
              {{
                'SYSTEM.status.' +
                  (systemData.records[0][
                    systemData.attributes.structure.system.sa.var
                  ] >
                    0) | translate
              }}
            </h2>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.sb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.sb.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.sb
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.sb.label"
            [ngbTooltip]="tipContent44"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.sc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.sc.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.sc
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.sc.label"
            [ngbTooltip]="tipContent45"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>
          <div class="percentage-container">
            <mat-card-header
              [ngbTooltip]="tipContent46"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.sd.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.sd
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.sd.unit }}
                {{ systemData.attributes.structure.system.sd.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent47"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.se.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.se
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.se.unit }}
                {{ systemData.attributes.structure.system.se.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent47"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.sf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.sf
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.sf.unit }}
                {{ systemData.attributes.structure.system.sf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>
      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>

        <div class="linear-chart">
          <canvas
            baseChart
            id="linearChart"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartDataSolar"
            [options]="lineChartOptionsSolar"
            [colors]="lineChartColorsSolar"
            [labels]="lineChartLabelsSolar"
          >
          </canvas>

          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="solarChart"
            tooltipClass="left half-chart-tooltip"
            placement="left"
          >
          
          </div>
        </div>
      </div>
    </div>

    <!-- GENERATOR SET TAB -->
    <div class="tab generatorSet" *ngIf="tab == 'generatorSet' && !tabLoading">
      <div class="half-tab">
        <div class="info-container">
          <ion-icon class="info-icon" src="./assets/icon/motor.svg"></ion-icon>
          <div>
            <h1> Eléctrogeno </h1>
            <h2
              [ngClass]="
                systemData.records[0][
                  systemData.attributes.structure.system.ga.var
                ] > 0
                  ? 'green-color'
                  : 'red-color'
              "
            >
              {{
                'SYSTEM.status.' +
                  (systemData.records[0][
                    systemData.attributes.structure.system.ga.var
                  ] >
                    0) | translate
              }}
            </h2>
          </div>
        </div>
        <div class="summary">
          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.gb.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.gb.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.gb
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.gb.label"
            [ngbTooltip]="tipContent44"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <krill-spinner-v2
            format="vertical"
            [magnitude]="systemData.attributes.structure.system.gc.unit"
            [maxSpinnerValue]="
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.system.gc.limits.varMax
              ]
            "
            [textValue]="
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.system.gc
              ) | number : '1.0-2'
            "
            [imgValue]=""
            [spinnerDiameter]="spinnerRemasterizedDiameter"
            [descriptionTitle]="systemData.attributes.structure.system.gc.label"
            [ngbTooltip]="tipContent44"
            tooltipClass="top"
            placement="top"
          >
          </krill-spinner-v2>

          <div class="percentage-container">
            <mat-card-header
              [ngbTooltip]="tipContent46"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.gd.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.gd
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.gd.unit }}
                {{ systemData.attributes.structure.system.gd.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent47"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.ge.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.ge
                  ) | date : 'dd-LL hh:mm a' : '-0800'
                }}
                {{ systemData.attributes.structure.system.ge.unit }}
                {{ systemData.attributes.structure.system.ge.subUnit }}
              </mat-card-title>
            </mat-card-header>

            <mat-card-header
              [ngbTooltip]="tipContent46"
              tooltipClass="top"
              placement="top"
            >
              <mat-card-subtitle>
                {{ systemData.attributes.structure.system.gf.label }}
              </mat-card-subtitle>
              <mat-card-title>
                {{
                  systemsService.getSystemSummaryData(
                    systemData,
                    systemData.attributes.structure.system.gf
                  ) | number : '1.0-2'
                }}
                {{ systemData.attributes.structure.system.gf.unit }}
                {{ systemData.attributes.structure.system.gf.subUnit }}
              </mat-card-title>
            </mat-card-header>
          </div>
        </div>
      </div>
      <div class="half-tab">
        <div class="dates-container-charts">
          <ion-badge color="primary">
            <h1>Rango de tiempo:</h1>
          </ion-badge>
          <div class="dates-fields">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.from' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="from"
                [(ngModel)]="startDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="from"
              ></mat-datepicker-toggle>
              <mat-datepicker #from disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'TIME.to' | translate }}</mat-label>
              <input
                matInput
                [matDatepicker]="to"
                [(ngModel)]="endDate"
                disabled
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
          <button
            (click)="changeChartsInterval()"
            class="date-button"
            mat-raised-button
            color="primary"
          >
            Buscar
          </button>
        </div>

        <div class="linear-chart">
          <canvas
            class="linear-chart"
            baseChart
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [datasets]="lineChartDataMotor"
            [options]="lineChartOptionsMotor"
            [colors]="lineChartColorsElect"
          >
          </canvas>

          <mat-spinner
            class="border-spinner"
            *ngIf="barChartLoading"
            class="bar-spinner"
          ></mat-spinner>
          <div
            class="tooltip-icon-blue chart-tooltip"
            [ngbTooltip]="solarBarChart"
            tooltipClass="left chart-tooltip"
            placement="left"
            *ngIf="!barChartLoading"
          >
           
          </div>
        </div>
      </div>
    </div>

    <div class="tab" *ngIf="tabLoading">
      <mat-spinner></mat-spinner>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #tipContent10>
  {{ 'SYSTEM.tooltips.10' | translate }}
</ng-template>
<ng-template #tipContent19>
  {{ 'SYSTEM.tooltips.19' | translate }}
</ng-template>
<ng-template #tipContent20>
  {{ 'SYSTEM.tooltips.20' | translate }}
</ng-template>
<ng-template #tipContent22>
  {{ 'SYSTEM.tooltips.22' | translate }}
</ng-template>

<!-- Load tab tips -->

<ng-template #tipContentLoadChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.4' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.pBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.3' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.2' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.1' | translate }}
</ng-template>

<!-- Electrical tab tips -->
<ng-template #tipContent23>
  {{ 'SYSTEM.tooltips.23' | translate }}
</ng-template>
<ng-template #tipContent24>
  {{ 'SYSTEM.tooltips.24' | translate }}
</ng-template>
<ng-template #tipContent25>
  {{ 'SYSTEM.tooltips.25' | translate }}
</ng-template>
<ng-template #tipContent26>
  {{ 'SYSTEM.tooltips.26' | translate }}
</ng-template>
<ng-template #electricalLeftChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.29' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.30' | translate }}
</ng-template>

<!-- Battery tab tips -->
<ng-template #tipContent33>
  {{ 'SYSTEM.tooltips.33' | translate }}
</ng-template>
<ng-template #tipContent34>
  {{ 'SYSTEM.tooltips.34' | translate }}
</ng-template>
<ng-template #tipContent35>
  {{ 'SYSTEM.tooltips.35' | translate }}
</ng-template>
<ng-template #tipContent36>
  {{ 'SYSTEM.tooltips.36' | translate }}
</ng-template>
<ng-template #battLeftChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.pBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.40' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.41' | translate }}
</ng-template>

<!-- Solar tab tips -->
<ng-template #tipContent44>
  {{ 'SYSTEM.tooltips.44.1' | translate }}
</ng-template>
<ng-template #tipContent45>
  {{ 'SYSTEM.tooltips.45' | translate }}
</ng-template>
<ng-template #tipContent46>
  {{ 'SYSTEM.tooltips.46' | translate }}
</ng-template>
<ng-template #tipContent47>
  {{ 'SYSTEM.tooltips.47' | translate }}
</ng-template>
<ng-template #solarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.ppv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.50' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.51' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vpv' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.52' | translate }}
</ng-template>
<ng-template #solarBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.49' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.epvToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.48' | translate }}
</ng-template>
