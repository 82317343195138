<div class="page-content">
  <mat-card class="map-card">
    <!-- ESTE ES EL MAPA -->
    <div class="header-map">
      <h5>Seleccione un lugar</h5>
      <button class="mat-raised-button" (click)="send()" [disabled]="markerLat == undefined && markerLng == undefined">
        {{ 'DIALOG.send' | translate }}
      </button>
      <button class="mat-raised-button" (click)="close()">
        {{ 'DIALOG.close' | translate }}
      </button>
    </div>

    <div class="map">
      <agm-map
        [latitude]="mapLat"
        [longitude]="mapLng"
        [disableDefaultUI]="false"
        [zoomControl]="false"
        (mapClick)="addMarker($event)">

        <agm-marker
          [latitude]="markerLat"
          [longitude]="markerLng"
          [label]="markerLabel"
          [markerDraggable]="markerDrag"
          (dragEnd)="markerDragEnd($event)">
        </agm-marker>
      </agm-map>
    </div>
  </mat-card>
</div>
