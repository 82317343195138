import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  name = localStorage.getItem('USER_NAME');
  email = localStorage.getItem('EMAIL');
  description = '';
  subject = '';
  localidad = '';

  constructor(
    private router: Router,
    private alertCtrl: AlertController,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  async onSubmit() {
    let message =
    'Nombre: ' +
    this.name +
    '\n' +
    'Email: ' +
    this.email +
    '\n' +
    'Mensaje: ' +
    this.description+
    '\n' +
    'Localidad: ' +
    this.localidad;

  this.subject == '' ? (this.subject = 'Contacto sin asunto') : this.subject;
  this.authService.contact(this.subject, message, this.email);
  let alert = await this.alertCtrl.create({
    header: 'Solicitud enviada',
    message:
      'Muchas gracias por la información, trabajaremos en su comentario y nos pondremos en contacto de ser necesario',
    buttons: [
      {
        text: 'Ok',
        handler: () => {
          this.router.navigate(['/dashboard/systems']);
        }
      }
    ]
  });
  alert.present();
  }
}
