import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject } from 'rxjs';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  private language: Subject<string> = new BehaviorSubject<string>('');

  constructor(private translate: TranslateService) {
    translate.setDefaultLang('es');
    translate.use('es');
  }

  get language$() {
    return this.language.asObservable();
  }

  getDefaultLanguage() {
    let language = this.translate.getBrowserLang();
    this.translate.setDefaultLang(language);
    return language;
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
    this.language.next(setLang);
    moment.locale(setLang);
  }

  getTranslation(translate: string) {
    let response;
    this.translate.get(translate).subscribe((res) => {
      response = res;
    });
    return response;
  }
}
