<div class="page-content" *ngIf="!pageLoading; else matSpinner">
  <mat-card [ngClass]="sidenavOpened ? 'fixed' : 'fixed-full-width'">
    <!-- indicator section -->
    <mat-card class="indicators-section">
      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('availability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[0].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/disponibilidad2.svg"
          ></ion-icon>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.common.ha1.label }}
          </mat-card-subtitle>
          <mat-card-title class="connected-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha1
              ) | number : '1.0-2'
            }}

            {{ systemData.attributes.structure.common.ha1.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('profitability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[1].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/rentabilidad2.svg"
          ></ion-icon>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.common.ha2.label }}
          </mat-card-subtitle>
          <mat-card-title class="alarm-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha2
              ) | number : '1.0-2'
            }}

            {{ systemData.attributes.structure.common.ha2.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>

      <mat-card
        [ngClass]="
          systemData.attributes.structure.common.indicatorRoute == true
            ? 'indicator-card'
            : 'indicator-card p-events'
        "
        (click)="saveIndicator('sustainability')"
        [routerLink]="
          indicatorsService.getIndicatorRoute(
            systemData.attributes.equipmentId,
            systemData.id,
            systemData.attributes.structure.common.indicatorRoute
          )
        "
        [ngStyle]="{
          'pointer-events': indicators[2].off == 1 ? 'none' : 'initial'
        }"
      >
        <mat-card-header>
          <ion-icon
            mat-card-avatar
            src="assets/icon/sustentabilidad2.svg"
          ></ion-icon>
          <mat-card-subtitle>
            {{ systemData.attributes.structure.common.ha3.label }}
          </mat-card-subtitle>
          <mat-card-title class="alert-sites-color">
            {{
              systemsService.getSystemSummaryData(
                systemData,
                systemData.attributes.structure.common.ha3
              ) /1000 | number : '1.0-2'
            }}

            {{ systemData.attributes.structure.common.ha3.unit }}
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </mat-card>
    <!-- data section -->
    <mat-card class="data-section">
      <mat-card-header class="bg-gradient-blue">
        <mat-card-subtitle class="site-name">
          {{ systemData.attributes.name }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="system-description-container">
        <div class="system-description-weather">
          <p>
            {{ utils.getWeather(systemData.records[0].icon) }}
          </p>
          <p>
            {{
              systemData.records[0][
                systemData.attributes.structure.common.hb2.var
              ] | number
            }}
            {{ systemData.attributes.structure.common.hb2.unit }}
          </p>
        </div>
        <div class="system-description-dates">
          <p>{{ systemCurrentDate }}</p>
          <p>{{ systemCurrentYear }}</p>
          <p>{{ systemCurrentHour }}</p>
        </div>
        <div class="system-description-logo">
          <!-- <ion-icon
            class="weather-icon"
            [src]="imageIndicator"
          ></ion-icon> -->
          <img class="weather-icon" [src]="imageIndicator" alt="" />
        </div>
        <div class="system-description-icon">
          <p style="font-size:smaller;">
            <ion-icon
              class="icon"
              name="notifications"
              [ngClass]="
                systemData.records[0].status == null
                  ? 'icon green-icon'
                  : systemData.records[0].status.sNotifications == 'alarm'
                  ? 'icon red-icon'
                  : systemData.records[0].status.sNotifications == 'warning'
                  ? 'icon yellow-icon'
                  : systemData.records[0].status.sNotifications == 'info'
                  ? 'icon blue-icon'
                  : 'icon green-icon'
              "
            >
            </ion-icon>
            {{
              systemsService.getSystemNotification(
                systemData.records,
                lastNotification.notification.title
              )
            }}
          </p>
        </div>
        <div
          [ngClass]="
            systemData.attributes.status != 00000
              ? 'system-description-status bg-green'
              : 'system-description-status bg-black'
          "
        >
          <p>
            {{
              systemData.attributes.status != 00000
                ? 'Conectado'
                : 'Desconectado'
            }}
          </p>
          <p>{{ lastConnection }}</p>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- spinner section -->
    <mat-card class="spinner-section">
      <mat-card-header>
        <mat-card-subtitle> Autonomía </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="spinner-v2">
        <krill-spinner-v2
          format="vertical"
          [magnitude]="systemData.attributes.structure.common.hc1.unit"
          [maxSpinnerValue]="
            systemData.attributes.plantDetails.system.FrontLimits[
              systemData.attributes.structure.common.hc1.limits.varMax
            ]
          "
          [textValue]="
            systemData.records[0][
              systemData.attributes.structure.common.hc1.var
            ] | number : '1.0-2'
          "
          [imgValue]=""
          [spinnerDiameter]="spinnerRemasterizedDiameter"
          descriptionTitle=""
          tooltipClass="top"
          placement="top"
          [spinnerFull]="true"
        >
        </krill-spinner-v2>
      </mat-card-content>
    </mat-card>
    <!-- system indicator section -->
    <mat-card class="system-indicator-section">
      <mat-card-header>
        <mat-card-subtitle> Consumo | Suministro </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <!-- BATTERY -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent13"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg1.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] < 0 &&
              systemData.attributes.structure.common.systemTabs.batteries
            "
          ></ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) | number : '1.0-2'
            }}
            {{ systemData.attributes.structure.common.lg1.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="batt"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg1.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) | number : '1.0-2'
            }}
            {{ systemData.attributes.structure.common.lg1.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg1.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg1.limits.varMax
              ]
            "
            *ngIf="systemData.records[0].pBat >= 0"
          ></ion-progress-bar>
        </div>
        <!-- LOAD -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent14"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg2.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] >= 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] >= 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg2.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] < 0
            "
          ></span>
          <ion-icon class="icon-load" name="newbulb"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] >= 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] < 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg2.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg2.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg2.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg2.var
              ] < 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
        </div>
        <!--ELECTRICAL NET-->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent11"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg3.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] < 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg3.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="electrical-tower-gray"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg3.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg3.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg3.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg3.var
              ] >= 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
        </div>
        <!-- SOLAR -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent12"
          tooltipClass="top"
          placement="top"
          animation="true"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg4.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] < 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg4.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="solar"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg4.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right green-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg4.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg4.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0 && systemData.attributes.structure.common.systemTabs.load
            "
          >
          </ion-progress-bar>
        </div>
        <!-- MOTOR -->
        <div
          class="balance-element"
          [ngbTooltip]="tipContent12"
          tooltipClass="top"
          placement="top"
        >
          <ion-progress-bar
            class="rounded-left green-progress-bar round-left-progress"
            reversed="true"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg5.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] < 0 &&
              systemData.attributes.structure.common.systemTabs.generatorSetTab
            "
          >
          </ion-progress-bar>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] < 0
            "
            class="value-progress-bar-left"
          >
            {{
              mathTools.roundToNumber2(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              )
            }}
            {{ systemData.attributes.structure.common.lg5.unit }}
          </h4>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] >= 0
            "
          ></span>
          <ion-icon class="icon-load" name="motor"></ion-icon>
          <span
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] < 0
            "
          ></span>
          <h4
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg4.var
              ] >= 0
            "
            class="value-progress-bar-right"
          >
            {{
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              ) | number : '1.0-2'
            }}
            {{ systemData.attributes.structure.common.lg5.unit }}
          </h4>
          <ion-progress-bar
            class="rounded-right blue-progress-bar"
            [value]="
              mathTools.abs(
                systemData.records[0][
                  systemData.attributes.structure.common.lg5.var
                ]
              ) /
              systemData.attributes.plantDetails.system.FrontLimits[
                systemData.attributes.structure.common.lg5.limits.varMax
              ]
            "
            *ngIf="
              systemData.records[0][
                systemData.attributes.structure.common.lg5.var
              ] >= 0 &&
              systemData.attributes.structure.common.systemTabs.generatorSet
            "
          ></ion-progress-bar>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- images section -->
    <mat-card class="images-section">
      <mat-card-header> </mat-card-header>
      <mat-card-content>
        <system-images [systemImgs]="systemImgs"></system-images>
      </mat-card-content>
    </mat-card>
  </mat-card>

  <mat-card class="tab-container no-padding">
    <system-tabs
      dynamicHeight
      mat-align-tabs="center"
      [tabs]="tabs"
      [systemData]="systemData"
      [systemIndicatorsData]="systemIndicatorsData"
    >
    </system-tabs>
  </mat-card>
</div>
<ng-template #matSpinner>
  <mat-spinner></mat-spinner>
</ng-template>

<!-- Load tab tips -->

<ng-template #tipContentLoadBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.9' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eacChargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.5' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.bars.eBatDischargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.7' | translate }}
  <br />
  <span style="color: rgba(62, 36, 130)">
    {{ 'SYSTEM.charts.bars.eBatChargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.8' | translate }}
  <br />
  <span style="color: rgba(153, 0, 76)">
    {{ 'SYSTEM.charts.bars.epvTodayzone' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.6' | translate }}
</ng-template>

<ng-template #tipContent11>
  {{ 'SYSTEM.tooltips.11' | translate }}
</ng-template>
<ng-template #tipContent12>
  {{ 'SYSTEM.tooltips.12' | translate }}
</ng-template>
<ng-template #tipContent13>
  {{ 'SYSTEM.tooltips.13' | translate }}
</ng-template>
<ng-template #tipContent14>
  {{ 'SYSTEM.tooltips.14' | translate }}
</ng-template>

<ng-template #tipContentDiagram>
  {{ systemData.plantDetails.inverter.inverterInfo }}
  <br />
  {{ systemData.plantDetails.bess.batInfo }}
  <br />
  {{ systemData.plantDetails.pv.pvInfo }}
  <br />
  {{ systemData.plantDetails.grid.gridInfo }}
</ng-template>

<ng-template #tipContent21>
  {{ 'SYSTEM.tooltips.21' | translate }}
</ng-template>

<!-- Electrical tab tips -->
<ng-template #electricalBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.27' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eacChargeTotal' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.28' | translate }}
</ng-template>
<ng-template #electricalLeftChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.pacInPut' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.29' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.outPutPower' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.30' | translate }}
</ng-template>
<ng-template #electricalRightChart>
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.linear.vGrid' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.31' | translate }}
</ng-template>

<ng-template #battBarChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.bars.eopDischrToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.38' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.bars.eBatDischargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.37' | translate }}
  <br />
  <span style="color: rgba(26, 46, 127)">
    {{ 'SYSTEM.charts.bars.eBatChargeToday' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.39' | translate }}
</ng-template>

<ng-template #battRightChart>
  <span style="color: rgba(0, 205, 255)">
    {{ 'SYSTEM.charts.linear.vBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.42' | translate }}
  <br />
  <span style="color: rgba(0, 89, 255)">
    {{ 'SYSTEM.charts.linear.iBat' | translate }}</span
  >:
  {{ 'SYSTEM.tooltips.43' | translate }}
</ng-template>
