import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
@Component({
  selector: 'krill-spinner-v2',
  templateUrl: './krill-spinner-v2.component.html',
  styleUrls: ['./krill-spinner-v2.component.scss']
})
export class KrillSpinnerV2Component implements OnInit {
  // Represent 90% of spinner
  @Input() maxSpinnerValue: number;
  @Input() imgValue: number;

  // value to show in the spinner
  @Input() textValue: number;
  // magnitude to show in the spinner
  @Input() magnitude: string;
  // title to show in the spinner
  @Input() descriptionTitle: string;
  // description to show in the spinner
  // spinner diameter
  @Input() spinnerDiameter: number;

  @Input() spinnerFull: boolean = false;
  // type ( horizontal - vertical )
  @Input() format: string = 'horizontal';

  value: any;
  

  constructor(private translate: TranslateConfigService) {
    
  }

  ngOnInit() {
    if (this.spinnerFull){
      this.value = 100;
    }else{
      this.value=(this.textValue * 90) / (this.maxSpinnerValue * 1.05)
    }
  }
}
