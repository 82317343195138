import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';

import { SystemsService } from 'src/app/services/systems/systems.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { SidenavService } from 'src/app/services/sidenav/sidenav.service';

@Component({
  selector: 'system-tabs',
  templateUrl: './system-tabs.component.html',
  styleUrls: ['./system-tabs.component.scss']
})
export class SystemTabsComponent implements OnInit {
  @ViewChild('linearChart') canvas: ElementRef;


  @Input() tabs: string[];
  @Input() systemData: any;
  @Input() systemIndicatorsData: any;

  //system
  systemId: string;
  systemCurrentDate: string;
  systemCurrentHour: string;
  lastConnection: string;
  status: boolean = false;
  spinnerRemasterizedDiameter: number = 135;
  sidenavOpened: boolean;
  pageLoading: boolean = true;
  systemImgs: [];
  imageIndicator: string;

  clickPanel = false;

  indicators: any[] = [
    {
      name: 'autonomy',
      off: 0
    },
    {
      name: 'profitability',
      off: 0
    },
    {
      name: 'sustainability',
      off: 0
    }
  ];

  // dates
  defaultDate: string = '';
  startDate: string = '';
  endDate: string = '';

  //tabs
  tabLoading: boolean = false;
  actualTabIndex: number = 1;
  openTabIndex: number = 1;

  // === Charts ===
  barChartLoading: boolean = false;

  //LOAD
  lineChartData: ChartDataSets[] = [];
  lineChartOptionsLoad: ChartOptions = {};
  lineChartColorsLoad: Color[] = [
    {
      //load
      borderColor: '#f4b434 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#f4b434 '
    },
    {
      //battery
      borderColor: ' #fc8c64 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #fc8c64 '
    },
    {
      //solar
      borderColor: '#7caccc ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#7caccc '
    },
    {
      //electrical net
      borderColor: ' #8484a4',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #8484a4'
    },
    {
      //motor
      borderColor: '#b788bf ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#b788bf '
    }
  ];

  //ELECT
  lineChartDataElect: ChartDataSets[] = [];
  lineChartOptionsElect: ChartOptions = {};
  lineChartColorsElect: Color[] = [
    {
      borderColor: '#8484a4',
      backgroundColor: 'transparent'
    },
    {
      borderColor: 'rgba(255,0,0,0.7)',
      backgroundColor: 'transparent'
    },
    {
      borderColor: 'rgba(255,0,0,0.7)',
      backgroundColor: 'transparent'
    }
  ];

  //BATTERY
  lineChartDataBattery: ChartDataSets[] = [];
  lineChartOptionsBattery: ChartOptions = {};
  lineChartColorsBattery: Color[] = [
    {
      borderColor: '#f4b434 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#f4b434 '
    },
    {
      borderColor: ' #fc8c64 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: ' #fc8c64 '
    }
  ];

  //SOLAR
  lineChartDataSolar: ChartDataSets[] = [];
  lineChartOptionsSolar: ChartOptions = {};
  lineChartColorsSolar: Color[] = [
    {
      borderColor: '#f4b434 ',
      backgroundColor: 'transparent',
      pointBackgroundColor: '#f4b434 '
    },
    {
      borderColor: '#7caccc ',
      backgroundColor: '#7caccc',
      pointBackgroundColor: '#7caccc '
    },
    {
      borderColor: 'rgba(26,46,127,0.7)',
      backgroundColor: 'transparent',
      pointBackgroundColor: 'rgba(26,46,127,0.7)'
    }
  ];
  lineChartLabelsSolar: Label[] = [];

  //MOTOR
  lineChartDataMotor: ChartDataSets[] = [];
  lineChartOptionsMotor: ChartOptions = {};

  //COMMON
  lineChartLabels: Label[] = [];
  lineChartLegend = true;
  lineChartType = 'line';

  summaryPercentageCardElectricalNet1 = 1;
  summaryPercentageCardElectricalNet2 = 1;

  // gradients
  blueGradient: CanvasGradient;
  orangeGradient: CanvasGradient;

  constructor(
    public systemsService: SystemsService,
    private translate: TranslateConfigService,
    public sidenavService: SidenavService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngAfterViewInit() {
    //LOAD OPTIONS
    this.lineChartOptionsLoad = {
      elements: {
        point: {
          radius: 0
        }
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: { usePointStyle: true }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              minRotation: 0,
              maxRotation: 0,
              maxTicksLimit: 5
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              
            },
            scaleLabel: {
              display: true,
              labelString: this.systemData.attributes.structure.system.lg.label
            }
          }
        ]
      }
    };
    //ELECT OPTIONS
    this.lineChartOptionsElect = {
      elements: {
        point: {
          radius: 0
        }
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          usePointStyle: true,
          filter: function (legendItem, chartData) {
            if (legendItem.datasetIndex != 0) {
              return false;
            } else {
              return true;
            }
          }
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              minRotation: 0,
              maxRotation: 0,
              maxTicksLimit: 5
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              
            },
            scaleLabel: {
              display: true,
              labelString: this.systemData.attributes.structure.system.rg.label
            }
          }
        ]
      }
    };
    //BATTERY OPTIONS
    this.lineChartOptionsBattery = {
      elements: {
        point: {
          radius: 0
        }
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: { usePointStyle: true }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              minRotation: 0,
              maxRotation: 0,
              maxTicksLimit: 5
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            position: 'left',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              
            },
            scaleLabel: {
              display: true,
              labelString:
                this.systemData.attributes.structure.system.bg.label[0]
            }
          },
          {
            id: 'second-y-axis',
            position: 'right',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 8,
              
            },
            scaleLabel: {
              display: true,
              labelString:
                this.systemData.attributes.structure.system.bg.label[1]
            }
          }
        ]
      }
    };
    //SOLAR OPTIONS
    this.lineChartOptionsSolar = {
      elements: {
        point: {
          radius: 0
        }
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: { usePointStyle: true }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              maxRotation: 0,
              minRotation: 0,
              maxTicksLimit: 5
            },
            scaleLabel: {
              display: true,
              labelString: 'Potencia día vs Proyectada'
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              
            },
            scaleLabel: {
              display: true,
              labelString: this.systemData.attributes.structure.system.sg.label
            }
          }
        ]
      }
    };
    //MOTOR OPTIONS
    this.lineChartOptionsMotor = {
      elements: {
        point: {
          radius: 0
        }
      },
      responsive: true,
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          usePointStyle: true,
          filter: function (legendItem, chartData) {
            if (legendItem.datasetIndex != 0) {
              return false;
            } else {
              return true;
            }
          }
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            ticks: {
              autoSkip: true,
              minRotation: 0,
              maxRotation: 0,
              maxTicksLimit: 5
            },
            gridLines: {
              drawOnChartArea: false
            }
          }
        ],
        yAxes: [
          {
            id: 'first-y-axis',
            type: 'linear',
            gridLines: {
              drawOnChartArea: false
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              
            },
            scaleLabel: {
              display: true,
              labelString: this.systemData.attributes.structure.system.gg.label
            }
          }
        ]
      }
    };
  }

  async ngOnInit() {
    this.sidenavService.sidenavOpened.subscribe((opened) => {
      this.sidenavOpened = opened;
    });

    this.systemDataSetup();

    // Set spinner Diameter
    this.spinnerResizes();

    await this.actualTab();

    // create orange gradient
    this.orangeGradient = this.canvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 400);
    this.orangeGradient.addColorStop(0, '#F6D16F');
    this.orangeGradient.addColorStop(1, '#F2987B');
    // create blue gradient
    this.blueGradient = this.canvas.nativeElement
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 400);
    this.blueGradient.addColorStop(0, '#6F9DF6');
    this.blueGradient.addColorStop(1, '#4D6BA6');

    // load chart gradientss
    this.loadColors();

    this.systemIndicatorsData = await this.systemsService.getSystemIndicators(
      this.systemData.id
    );
    this.pageLoading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.spinnerResizes();
    this.maxLimitChartsChange();
  }

  async tabChanged(tabChangeEvent: MatTabChangeEvent): Promise<void> {
    console.log('cambio');
    this.tabLoading = true;
    this.startDate = '';
    this.endDate = '';
    this.actualTabIndex = tabChangeEvent.index;
    const yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');
    
    switch (tabChangeEvent.tab.textLabel) {
      case 'load':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getLoadData(1, yesterdayDate, this.defaultDate);
        break;
      case 'electricNet':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getElectricalData(2, yesterdayDate, this.defaultDate);
        break;
      case 'batteries':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getBatteriesData(3, yesterdayDate, this.defaultDate);
        break;
      case 'solar':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getSolarData(4, yesterdayDate, this.defaultDate);
        break;
      case 'generatorSet':
        localStorage.setItem('tab', tabChangeEvent.tab.textLabel);
        await this.getGeneratorSetData(5, yesterdayDate, this.defaultDate);
        break;
    }
    this.tabLoading = false;
  }

  async getLoadData(loadTab: number, startDate: string, endDate: string) {
    //RecordsChart
    let loadChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      loadTab - 1,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    this.lineChartData = loadChartsData.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.linear.' + data.label
      );
      return data;
    });

    console.log("lineChartData: ", this.lineChartData)

    //Min and max limit
    this.lineChartOptionsLoad.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.lg.limits.varMin
      ];
    this.lineChartOptionsLoad.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.lg.limits.varMax
      ];

    this.lineChartLabels = loadChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });
  }

  async getElectricalData(
    electricalTab: number,
    startDate: string,
    endDate: string
  ) {
    let electricalChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      electricalTab - 1,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    let upperLimit = new Array(
      electricalChartsData.lineChartData[0].data.length
    ).fill(
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.rg.limits.upper
      ]
    );
    let lowerLimit = new Array(
      electricalChartsData.lineChartData[0].data.length
    ).fill(
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.rg.limits.lower
      ]
    );

    this.lineChartDataElect = [
      {
        data: electricalChartsData.lineChartData[0].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' + electricalChartsData.lineChartData[0].label
        ),
        pointBackgroundColor: 'rgba(26,46,127,0.7)'
      },
      {
        data: upperLimit,
        borderDash: [10, 10],
        pointBackgroundColor: 'rgba(255,0,0,0.7)'
      },
      {
        data: lowerLimit,
        borderDash: [10, 10],
        pointBackgroundColor: 'rgba(255,0,0,0.7)'
      }
    ];

    //Min and max limit
    this.lineChartOptionsElect.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.rg.limits.varMin
      ];
    this.lineChartOptionsElect.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.rg.limits.varMax
      ];

    this.lineChartLabels = electricalChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });
  }

  async getBatteriesData(
    batteriesTab: number,
    startDate: string,
    endDate: string
  ) {
    let BatChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      batteriesTab - 1,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    this.lineChartDataBattery = [
      {
        data: BatChartsData.lineChartData[1].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' + BatChartsData.lineChartData[1].label
        ),
        pointBackgroundColor: 'rgba(0,205,255,0.5)',
        yAxisID: 'first-y-axis'
      },
      {
        data: BatChartsData.lineChartData[0].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' + BatChartsData.lineChartData[0].label
        ),
        pointBackgroundColor: 'rgba(0,89,255,0.7)',
        yAxisID: 'second-y-axis'
      }
    ];

    //Min and max limit [left-right]
    this.lineChartOptionsBattery.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.bg.limits.varMin[0]
      ];
    this.lineChartOptionsBattery.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.bg.limits.varMax[0]
      ];
    this.lineChartOptionsBattery.scales.yAxes[1].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.bg.limits.varMin[1]
      ];
    this.lineChartOptionsBattery.scales.yAxes[1].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.bg.limits.varMax[1]
      ];

    this.lineChartLabels = BatChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });
  }

  async getSolarData(solarTab: number, startDate: string, endDate: string) {
    let solarChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      solarTab - 1,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    // console.log("solarChartsData: ", solarChartsData)

    this.lineChartDataSolar = solarChartsData.lineChartData.map((data) => {
      data.label = this.translate.getTranslation(
        'SYSTEM.charts.linear.' + data.label
      );
      return data;
    });

    //Min and max limit
    this.lineChartOptionsSolar.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.sg.limits.varMin
      ];
    this.lineChartOptionsSolar.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.sg.limits.varMax
      ];

    this.lineChartLabelsSolar = solarChartsData.lineChartLables.map((date) => {
      return moment(date)
        .utcOffset(this.systemData.attributes.timeZone)
        .format('DD MMM hh:mm a');
    });
  }

  async getGeneratorSetData(
    generatorSetTab: number,
    startDate: string,
    endDate: string
  ) {
    let generatorSetChartsData = await this.systemsService.getRecordsChart(
      this.systemData.id,
      this.systemData.attributes.equipmentId,
      generatorSetTab - 1,
      startDate,
      endDate,
      this.systemData.attributes.timeZone
    );

    // console.log("generatorSetChartsData: ", generatorSetChartsData)

    let upperLimit = new Array(
      generatorSetChartsData.lineChartData[0].data.length
    ).fill(
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.gg.limits.upper
      ]
    );
    let lowerLimit = new Array(
      generatorSetChartsData.lineChartData[0].data.length
    ).fill(
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.gg.limits.lower
      ]
    );

    this.lineChartDataMotor = [
      {
        data: generatorSetChartsData.lineChartData[0].data,
        label: this.translate.getTranslation(
          'SYSTEM.charts.linear.' +
            generatorSetChartsData.lineChartData[0].label
        ),
        pointBackgroundColor: 'rgba(26,46,127,0.7)'
      },
      {
        data: upperLimit,
        borderDash: [10, 10],
        pointBackgroundColor: 'rgba(255,0,0,0.7)'
      },
      {
        data: lowerLimit,
        borderDash: [10, 10],
        pointBackgroundColor: 'rgba(255,0,0,0.7)'
      }
    ];

    //Min and max limit
    this.lineChartOptionsMotor.scales.yAxes[0].ticks.min =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.gg.limits.varMin
      ];
    this.lineChartOptionsMotor.scales.yAxes[0].ticks.max =
      this.systemData.attributes.plantDetails.system.FrontLimits[
        this.systemData.attributes.structure.system.gg.limits.varMax
      ];

    this.lineChartLabels = generatorSetChartsData.lineChartLables.map(
      (date) => {
        return moment(date)
          .utcOffset(this.systemData.attributes.timeZone)
          .format('DD MMM hh:mm a');
      }
    );
  }

  /**
   *  Change the spinner's diameter on resize
   */
  public spinnerResizes() {
    if (window.innerWidth > 1440) {
      this.spinnerRemasterizedDiameter = 140;
    } else if (window.innerWidth > 1200) this.spinnerRemasterizedDiameter = 120;
    if (window.innerWidth <= 1200) this.spinnerRemasterizedDiameter = 110;
  }

  public maxLimitChartsChange() {
    if (window.innerWidth > 800) {
      this.lineChartOptionsLoad.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.lineChartOptionsLoad.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.lineChartOptionsElect.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.lineChartOptionsElect.scales.yAxes[0].ticks.maxTicksLimit = 7;

      this.lineChartOptionsBattery.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.lineChartOptionsBattery.scales.yAxes[0].ticks.maxTicksLimit = 6;
      this.lineChartOptionsBattery.scales.yAxes[1].ticks.maxTicksLimit = 6;

      this.lineChartOptionsSolar.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.lineChartOptionsSolar.scales.yAxes[0].ticks.maxTicksLimit = 7;

      this.lineChartOptionsMotor.scales.xAxes[0].ticks.maxTicksLimit = 5;
      this.lineChartOptionsMotor.scales.yAxes[0].ticks.maxTicksLimit = 7;
    } else if (window.innerWidth <= 800) {
      this.lineChartOptionsLoad.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsLoad.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.lineChartOptionsElect.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsElect.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.lineChartOptionsBattery.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsBattery.scales.yAxes[0].ticks.maxTicksLimit = 5;
      this.lineChartOptionsBattery.scales.yAxes[1].ticks.maxTicksLimit = 5;

      this.lineChartOptionsSolar.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsSolar.scales.yAxes[0].ticks.maxTicksLimit = 5;

      this.lineChartOptionsMotor.scales.xAxes[0].ticks.maxTicksLimit = 4;
      this.lineChartOptionsMotor.scales.yAxes[0].ticks.maxTicksLimit = 5;
    }
  }

  async changeChartsInterval() {
    let startDate = moment(new Date(this.startDate)).format('yyyy-MM-DD');
    let endDate = moment(new Date(this.endDate)).format('yyyy-MM-DD');

    moment(startDate).isAfter(endDate);
    if (moment(startDate).isAfter(endDate)) {
      this.startDate = '';
      this.endDate = '';
      return;
    }

    switch (this.actualTabIndex) {
      case 1:
        await this.getLoadData(this.actualTabIndex, startDate, endDate);
        break;
      case 2:
        await this.getElectricalData(this.actualTabIndex, startDate, endDate);
        break;
      case 3:
        await this.getBatteriesData(this.actualTabIndex, startDate, endDate);
        break;
      case 4:
        await this.getSolarData(this.actualTabIndex, startDate, endDate);
        break;
      case 5:
        await this.getGeneratorSetData(this.actualTabIndex, startDate, endDate);
        break;
    }
  }
  async systemDataSetup() {
    this.defaultDate = moment(new Date())
      .utcOffset(this.systemData.attributes.timeZone)
      .format('yyyy-MM-DD');

    this.summaryPercentageCardElectricalNet1 =
      this.systemData.records[0][
        this.systemData.attributes.structure.system.rd.var[0]
      ] &&
      this.systemData.records[0][
        this.systemData.attributes.structure.system.rd.var[1]
      ] &&
      this.systemData.records[0][
        this.systemData.attributes.structure.system.rd.var[2]
      ]
        ? 3
        : (this.systemData.records[0][
            this.systemData.attributes.structure.system.rd.var[0]
          ] &&
            this.systemData.records[0][
              this.systemData.attributes.structure.system.rd.var[1]
            ]) ||
          (this.systemData.records[0][
            this.systemData.attributes.structure.system.rd.var[0]
          ] &&
            this.systemData.records[0][
              this.systemData.attributes.structure.system.rd.var[2]
            ]) ||
          (this.systemData.records[0][
            this.systemData.attributes.structure.system.rd.var[1]
          ] &&
            this.systemData.records[0][
              this.systemData.attributes.structure.system.rd.var[2]
            ])
        ? 2
        : 1;

    this.summaryPercentageCardElectricalNet2 =
      this.systemData.records[0][
        this.systemData.attributes.structure.system.re.var[0]
      ] &&
      this.systemData.records[0][
        this.systemData.attributes.structure.system.re.var[1]
      ] &&
      this.systemData.records[0][
        this.systemData.attributes.structure.system.re.var[2]
      ]
        ? 3
        : (this.systemData.records[0][
            this.systemData.attributes.structure.system.re.var[0]
          ] &&
            this.systemData.records[0][
              this.systemData.attributes.structure.system.re.var[1]
            ]) ||
          (this.systemData.records[0][
            this.systemData.attributes.structure.system.re.var[0]
          ] &&
            this.systemData.records[0][
              this.systemData.attributes.structure.system.re.var[2]
            ]) ||
          (this.systemData.records[0][
            this.systemData.attributes.structure.system.re.var[1]
          ] &&
            this.systemData.records[0][
              this.systemData.attributes.structure.system.re.var[2]
            ])
        ? 2
        : 1;
  }

  async actualTab() {
    let yesterdayDate = moment().subtract(1, 'days').format('yyyy-MM-DD');

    if (localStorage.getItem('tab') != null) {
      let tab = localStorage.getItem('tab');
      if (localStorage.getItem('tab') == 'load') {
        this.openTabIndex = this.tabs.indexOf(tab) + 1;
        this.actualTabIndex = 1;
        await this.getLoadData(1, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'electricNet') {
        this.openTabIndex = this.tabs.indexOf(tab) + 1;
        this.actualTabIndex = 2;
        await this.getElectricalData(2, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'batteries') {
        this.openTabIndex = this.tabs.indexOf(tab) + 1;
        this.actualTabIndex = 3;
        await this.getBatteriesData(3, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'solar') {
        this.openTabIndex = this.tabs.indexOf(tab) + 1;
        this.actualTabIndex = 4;
        await this.getSolarData(4, yesterdayDate, this.defaultDate);
      } else if (localStorage.getItem('tab') == 'generatorSet') {
        this.openTabIndex = this.tabs.indexOf(tab) + 1;
        this.actualTabIndex = 5;
        await this.getGeneratorSetData(5, yesterdayDate, this.defaultDate);
      }
    } else {
      this.actualTabIndex = 1;
      await this.getLoadData(
        this.actualTabIndex,
        yesterdayDate,
        this.defaultDate
      );
    }
  }

  /**
   * Returns the absolute value of number.
   * This function is for the html tags
   * @param number
   */
  public abs(number: number) {
    return Math.abs(number);
  }

  /**
   * Return the round value of number.
   * This function is useful for the html tags
   */
  public round(number: number) {
    return Math.round(number);
  }

  public String(value) {
    if (value == undefined) {
      return 0;
    }
    const String = value.toString();
    return String.length;
  }

  loadColors() {
    let tab = localStorage.getItem('tab');
    if (tab === 'load') {
      // set gradient color
      this.lineChartData[0].backgroundColor = this.orangeGradient;
      this.lineChartData[0].borderColor = '#D9D9D9';
      this.lineChartData[0].pointBackgroundColor = '#D9D9D9';
      this.lineChartData[0].hoverBackgroundColor = this.orangeGradient;

      this.lineChartData[1].backgroundColor = this.blueGradient;
      this.lineChartData[1].borderColor = '#001F9D';
      this.lineChartData[1].pointBackgroundColor = '#001F9D';
      this.lineChartData[1].hoverBackgroundColor = this.blueGradient;
    } else if (tab === 'historical') {
      // set gradient color
      // this.tab2ChartData[0].backgroundColor = 'transparent';
      // this.tab2ChartData[0].borderColor = this.blueGradient;
      // this.tab2ChartData[0].pointBackgroundColor = this.blueGradient;
      // this.tab2ChartData[0].hoverBackgroundColor = this.blueGradient;
      // this.tab2ChartData[0].pointHoverBorderColor = this.blueGradient;

      // this.tab2ChartData[1].backgroundColor = this.orangeGradient;
      // this.tab2ChartData[1].borderColor = this.orangeGradient;
      // this.tab2ChartData[1].pointBackgroundColor = this.orangeGradient;
      // this.tab2ChartData[1].hoverBackgroundColor = this.orangeGradient;
      // this.tab2ChartData[1].pointHoverBorderColor = this.blueGradient;
    } else if (tab === 'projection') {
    } else if (tab === 'alarm') {
    } else if (tab === 'energy') {
    } else {
      return;
    }
  }
}
