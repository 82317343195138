import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor() {}

  /**
   *  Get the companies list
   */
  public async getProjectList(companyId:any) {
    try {
      let response = await axios.get(environment.apiUrl + 'projects/' +companyId, {
        headers: {},
      });

      return response.data.projectsList;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Add a project
   */
  public async addProject(name:string, companyId:any) {
    try {
      let response = await axios.post(
        environment.apiUrl + 'projects',
        {
          name,
          companyId
        },
        {
          headers: {},
        }
      );
      return response;
    } catch (err) {
      return err;
    }
  }

  /**
   *  Update a project
   */
  public async updateProject(
    id:any,
    name:string
  ) {
    try {
      let response = await axios.put(
        environment.apiUrl + 'projects/' + id,
        {
          name
        },
        {
          headers: {},
        }
      );
      return response;
    } catch (err) {
      return err.response.status;
    }
  }

  /**
   *  Delete a project
   */
  public async deleteProject(id) {
    try {
      let response = await axios.delete(environment.apiUrl + 'projects/' + id, {
        headers: {},
      });
      return response;
    } catch (err) {
      return err;
    }
  }
}
