<div
  [ngClass]="
    format === 'horizontal' ? 'krill-spinner-v2' : 'krill-spinner-v2-vertical'
  "
>
  <div class="information-container">
    <p [ngStyle]="{ 'text-align': format === 'horizontal' ? '' : 'center' }">
      {{ descriptionTitle }}
    </p>
    <!-- <p [ngStyle]="{ 'text-align': format === 'horizontal' ? '' : 'center' }">
      Medida ideal: <span> {{ maxSpinnerValue }} </span> {{ magnitude }}
    </p> -->
  </div>
  <div class="spinner-container">
    <span class="max-line"></span>
    <span class="empty-line"></span>
    <div class="img-value">
      <ion-icon
        mat-card-avatar
        *ngIf="imgValue === 1"
        src="assets/icon/home/spinner-gasoline-can.svg"
      ></ion-icon>
    </div>
    <div class="summary-value">
      <h3 *ngIf="textValue < 9997">
        {{ textValue }}
      </h3>
      <span>{{ magnitude }}</span>
    </div>
    <mat-spinner
      [ngClass]="
        textValue < 9998
          ? 'border-spinner'
          : textValue == 9999
          ? 'border-spinner circleGreen'
          : 'border-spinner circleRed'
      "
      reversed="true"
      mode="determinate"
      [value]="value | number : '1.0-1'"
      [diameter]="spinnerDiameter"
    >
    </mat-spinner>
  </div>
</div>
