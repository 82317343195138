import { Component, OnInit } from '@angular/core';
import { ConfigsService } from 'src/app/services/configs/configs.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';

@Component({
  selector: 'app-configs',
  templateUrl: './configs.component.html',
  styleUrls: ['./configs.component.scss'],
})
export class ConfigsComponent implements OnInit {
  summaryPage: boolean;

  constructor(
    private translate: TranslateConfigService,
    private configsService: ConfigsService
  ) {}

  async ngOnInit() {
    this.summaryPage = await this.configsService.getSummaryPage(
      localStorage.getItem('USER_ID')
    );
  }

  public async changeSummaryPage() {
    this.summaryPage = await this.configsService.updateSummaryPage(
      localStorage.getItem('USER_ID')
    );
  }
}
