import { Component } from "@angular/core";
import { TranslateConfigService } from "../services/translate-config/translate-config.service";

@Component({
    template: ''
})
export class Utils {

  constructor(private translate: TranslateConfigService) {}

  // Date validator
  static isDateValid(date: Date): boolean {
    return !isNaN(date.getTime());
  }

  static isStartDateValid(startDate: Date): boolean {
    return Utils.isDateValid(startDate) && startDate <= new Date();
  }

  static isEndDateValid(endDate: Date, startDate: Date): boolean {
    return (Utils.isDateValid(endDate) && endDate >= startDate);
  }

  /**
   * Download file
   * returns the blob file type to download
   * @param file file to transform and download
   * @param type file type ( application/pdf | text/csv )
   * @param extension file extension ( pdf | csv )
   * @param name file name
   * @param date file date
   */
  static async downloadFile(file: any, type: string = 'application/pdf', extension: string = 'pdf', name: string, date = '') {
    //create blob with data csv
    const blob = new Blob([file], { type: type });
    //generate url from blob
    const url = window.URL.createObjectURL(blob);
    //create an a element with href = url
    const a = document.createElement('a');
    a.href = url;
    //docuement name
    a.download = `${name}${date !== '' ? '-' + date : ('').trim()}.${extension}`;
    a.click();
    //delete url
    URL.revokeObjectURL(url);
  }
}